
// .vidBody video,.vidBody img { 
//     position: fixed; 
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     top: 0;
//     left: 0; 
//     opacity: .4;
//     z-index: -100;
// }
.active-link {
    // position: relative;
    color: $navactive !important;
    // border-bottom: 1px solid $navactive;
    // font-weight: 700 !important;
    font-size: 22px;
    padding-bottom: 4px; 
    
    &:hover {
        color: $navactivehover !important;
        // border-bottom: 1px solid $navactivehover;
        
    }
}

.nav-home.active, .nav-invest.active,.nav-education.active
,.nav-store.active,.nav-booking.active,.nav-blog.active
,.nav-login.active
,.nav-owner.active
{
    // position: relative;
    color: $navactive !important;
    // border-bottom: 1px solid $navactive;
    // font-weight: 700 !important;
    font-size: 22px;
    padding-bottom: 4px; 
    
    &:hover {
        color: $navactivehover !important;
        // border-bottom: 1px solid $navactivehover;
        
    }
}

 