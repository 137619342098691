.rand-progressbar-students-courses {
    margin-top: 5px; 
    width: 100%;
    height: 10px;
    border-radius: 20px;
    border: 2px solid $trim;
}

.rand-progressbar-students-courses-perc { 
    border-radius: 20px;  
    background-color: $mblue;
    height: 10px;
    // width: 25%;
}

.rand-progressbar-vidupload-calls{
    margin-top: 15px; 
    margin-left: -10px; 
    width: 100%;
    height: 10px;
    border-radius: 20px;
    border: 2px solid $trim;
}

.rand-progressbar-vidupload-calls-perc { 
    border-radius: 20px;  
    background-color: $mblue;
    height: 10px;
    // width: 25%;
}
.rand-dropdown {
    max-width: 50%; 
    padding: 4px 7px 4px 7px;  
    font-size: 15px;
    border: none;
    text-decoration: none;
    outline: none;
    border-radius: 5px;
    background-color: $headerback;
}
.rand-drop-store {
    margin-bottom: 22px;
    margin-top: 5px;
}
