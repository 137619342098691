 


.market-con {

    list-style: none; 

    & li {
        display: inline-block;
        margin: 50px 50px;
        margin-bottom: 0px;
        vertical-align: top;
        
    }
}
.main-body-alerts  {  
    z-index: 20;
    list-style: none;  
    margin:0;
    margin-top:0;
    padding: 0;  
    background: none;   
    box-shadow:none;

    
    & li {  
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        text-align: center; 

    }
    & price {
        // position: relative; 
        // left: -50px;
        padding-right: 5%; 
        color: $mgreen;
        width: 20%;

    }
    & name {
        padding-left: 5%; 
        // position: relative; 
        // left: 50px;
        color: $mblue;
        width: 20%;

    }

} 
.detailmin { 
        width: 50%;
        color: $mgray;
        // width: 50000px; 
        margin-left: 5%;
        margin-right: 5%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
}
 
.detailmax {
    width: 50%;
    color: $mgray;
    // width: 50000px; 
    margin-left: 5%;
    margin-right: 5%;
}


.alerts-con {
    border-radius: 25px;
    background: $backcolor;  
    box-shadow: 0px 0px 10px $mpink;
    margin: 100px;
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 15px;  
    color: $mpink;  
}
.detail-dropdown { 
    color: $mgray; 
    font-size: 18px;  
    display: grid;
    justify-content: center;
    margin: 20px 75px; 

    
    & key {
        color: rgba(255, 255, 0, 0.8);

    }    
    & discription {
        margin-left: 10px;
    }
}  