.loginBody {  
    padding: 80px 100px; 
    border-radius: 25px;
    background: $backgroundcoursepreview; 
    color: $colorbody;  
     

    display: flex;
    justify-content: center;

    position:relative;  

    // top: 45%;
    // left: 50%; 
    // transform: translate(-50%, -50%);  

    width: 333px; 
    margin: auto;
    margin-top: 100px;
    border: 2px solid $trim;
     
}
   
.login-Body, .reset-body{  
    padding: 20px 100px; 
    border-radius: 25px;
    background: $backgroundcoursepreview; 
    color: $colorbody;  
    // box-shadow: 5px 5px 22px $glow,
    //     -5px -5px 22px $glow;  
    display: flex;
    justify-content: center; 
    position:relative;    
    width: 333px; 
    margin: auto;
    margin-top: 60px;
    margin-bottom: 100px;
    border: 2px solid $trim;
     
    
& { 
    padding: 60px 100px;
     
}
}
.login-Body-profile {
    padding: 20px 100px; 
    border-radius: 25px;
    background: $backgroundcoursepreview; 
    color: $colhighcolor;   
    justify-content: center; 
    position:relative;    
    width: 333px; 
    margin: auto;
    margin-top: 60px;
    margin-bottom: 100px;
    border: 2px solid $trim;
     
    
& { 
    padding: 60px 100px;
     
}
}

// loginUserCon
.loginUser{
    font-size: 16px;
    color: black;
    & ruby { 
        font-weight: 500;
        position: relative;
        left: -22px;
        color: black;  
        text-align: center; 
        font-size: 18px;
        
        display: block;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    
}
.loginUser svg{
    color: $mblue;
   
    position: relative;
    height: 23px;
    width: 20px;

    left: -30px ;
    top: 11px;
   &.imgLock{
    top: 9.5px;
       height: 20px;
   }
 
}

// img.imgLock{
//     height: 200px; 4
// }
.loginUser input[type='password'],.loginUser input[type='text'] { 
    border-radius: 5px;
    background: $headerback ;
    position: relative;
    left: -20px ;
    width: 270px;
    // width: 100%;
    // height: 10px;
    margin-top: 5px;
    margin-bottom: 20px;

     
    font-size: 16px;
    border: 2px solid $trim;
    outline: none;
    
    color: black;
    padding: 5px 15px;
    // border: 2px solid $mblue; 
}
.login-store {
    font-size: 16px;
    color: black;
}

.login-store input[type='text'] {
    border-radius: 5px;
    background: $headerback ;
    position: relative;
    // left: -20px ;
    width: 270px;
    // width: 100%;
    // height: 10px;
    margin-top: 5px;
    margin-bottom: 20px;

     
    font-size: 16px;
    border: 2px solid $trim;
    outline: none;
    
    color: $colhighcolor;
    padding: 5px 15px;
}
.login-store input[type='text']:focus {
    border: 2px solid $mblue; 
}
.loginUser input[type='password']:focus,.loginUser input[type='text']:focus{
    border: 2px solid $mblue; 
    // box-shadow: 0px 0px 7px $mpink,
}

.code-input input[type='text']{ 
    width: 170px;
    left: -30px;
}

.PasswordA,.loginUserCon,.code-input{
    display: flex;
    justify-content: space-between; 
    // width: 100%;
}

.ForgotP{
    position: relative;
    left: -20px;
    font-size: 14px;
    top: 2px;
    color: $mred;  
    text-decoration: none;
}

.buttonC {
    display: flex;
    justify-content: center; 
    height: 33px;    
}
.buttonC-f {
    display: flex;
    justify-content: center; 
    height: 33px;    
    margin-right: 50px;
    margin-top: 20px;
}
.buttonC-v {
    color: black;
    display: flex;
    justify-content: center; 
    height: 33px;    
    // margin-right: 50px;
    margin-bottom: 50px;
}

.btn-con {
    display: flex;
    // justify-content: space-between;
    justify-content: center; 
    height: 33px;   
    & input[value='Sign Up'] {
        top: 0px;
    }
}
 
.buttonLogin {
    color: $backgray ;
    background: rgb(158, 182, 23);
    position: relative;
    left: 2px;
    width: 195px;  
    height: 33px;
    // width: 60%;  
    border-radius: 5px;
    border: 1.5px solid $mlgreen;
    font-weight: 900;
    transition: .6s;
    // box-shadow: none;
    &:hover ,&:focus{
        // color: $backcolor ;
        color: yellow;
        // background:  rgb(26, 170, 26);
        border: 1.5px solid yellow;
        box-shadow: 0px 0px 1px yellow; 
        outline: none;
        transition: .6s;
    }
    &:active, active {
        background: yellow;
        color: $mlgreen ;
        border: 1.5px solid $mlgreen;
        transition: .0s;
    }
}    
    
.buttonSignup,.btn-send-code{

    color: $backgray ;
    background: rgb(105, 170, 255);
    position: relative;
    top: 3px;
    left: -22px;
    width: 80px;
    height: 33px;
    // width: 25%;
    border-radius: 5px;
    border: 1.5px solid rgb(101,167,255);
    font-weight: 900;
    transition: .3s;
 &:hover  {//, &:focus
    // color: $backcolor ;
    // background:  rgb(35, 104, 194);
    // border: 1.5px solid yellow;
    // box-shadow: 0px 0px 1px yellow;
    // color: yellow;
    outline: none;
    transform: scale(1.2);
    box-shadow: 0px 0px 5px black;
 
 }
&:active, active {
    background: yellow;
    color: $backgray ;
    border: 1.5px solid rgb(101,167,255);
    transition: .0s;
}
}

.buttonSignup-active { 
    position: relative;
    top: 3px;
    left: -22px;
    width: 80px;
    height: 33px;
    // width: 25%;
    border-radius: 5px; 
    font-weight: 900;  

    background: yellow;
    color: $backgray ;
    border: 1.5px solid rgb(101,167,255);
    transition: .0s;
}

.buttonC input[type='text'],.hidden-btn,.hidden-btn input[type='text'],
.hidden-div input[type='text'] {
    position: absolute;
    background-color: transparent;
    color: transparent;
    border: transparent;
    top: 200px;
    box-shadow: none;
    z-index: -100;  
    &:focus {
        background-color: transparent;
        color: transparent;
        border: transparent;
    }
}

.input-title { 
    display: flex;
    justify-content: space-between; 
} 
.input-title-profile {
    display: flex;
    justify-content: space-between;  
    margin-left: 0px;
    margin-bottom: 15px;
}
.input-error {
    position: relative;
    left: -22px;
    color: $mred;
}
.alert-error {
    position: relative;
    left: -22px;
    color: $mred;  
    text-align: center;
    padding-bottom: 20px;
    font-size: 18px;

}

.btn-captcha-input {
    color: $backgray ;
    background: rgb(35, 104, 194);
    position: relative;
    margin: 10px;
    top: -9px;
    left: 40px;
    width: 200px;
    height: 33px;
    // width: 25%;
    border-radius: 5px;
    border: 1.5px solid rgb(101,167,255);
    font-weight: 900;
    transition: .6s;
 &:hover , &:focus {
    // color: $backcolor ;
    // background:  rgb(35, 104, 194);
    border: 1.5px solid yellow;
    box-shadow: 0px 0px 1px yellow;
    color: yellow;
    outline: none; 
 }
}

.forgot-btn-con {
    position: relative;
    display: flex;
    justify-content: center;
    left: -22px;
}
.msg-alert {
    font-size: 22px;
    line-height: 1.4;
}

.login-img-name, .login-img-email {
    transform: scale(1.3);
}