.cnt-create-img label, .cnt-student-img label {
    margin: 15px;
}

.cnt-student-img txt {
    font-size: 15px;
    font-weight: 500;
    
}

.cnt-student-img txt2 {
    font-size: 15px;
    font-weight: 700;
    margin: 10px;
}
.txt-answer{    
    margin-top: 10px;
}
.cnt-delete-file h2 {
    margin-left: 10px;
}

.cnt-call-details h1 {
    color: $mblue; 
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.cnt-call-details p{
    color: black; 
    margin-left: 15px;
}
.txt-call-date p {
    color: $headcolor;
    margin-left: 15px;
    position: relative;
    top: 22px; 
}

.txt-soon {
    color: black;
    text-align: center; 
    margin-top: 200px;
    border: 2px solid $trim;
    border-radius: 15px;
    width: 300px;
    padding: 25px;  
}
.txt-kid-courses-title {
    width: 420px;
    height: calc( ( 400px * 0.5625 ) - 20px ); 
    display: flex; 
    flex-direction: column;
    // justify-content: space-between;
    // background-color: gray;
}
.txt-kid-courses-title h2{
    color: $mblue;
    margin-left: 15px;
    // margin-top: 5px;
}
.txt-kid-courses-title txt{
    color: $headcolor;
    margin-left: 15px; 
    margin-top: 15px; 
    display: inline-block; 
}

.txt-courses-previews, .txt-course-previews {
    margin-left: 10px;
    color: $headcolor;  
}
.txt-course-previews {
    margin: 22px;
}
.txt-courses-previews txt2 , .txt-course-previews txt2{ 
    position: relative;
    top: -2px;
}
.label-nothing {
    color: black;
    background-color: gray;
}
.wrap-center-page {
    color: #33487d; 
}
.wrap-center-page h3{
    font-size: 30px;
    color: #1e4170; 
    font-weight: 700;
    font-style: normal; 
    
}
.wrap-center-page h4{
    margin-top: 40px;
    margin-bottom:25px;
    font-size: 24px;
    color: #1e4170; 
    font-weight: 0;  
    font-style: normal; 
}
.wrap-center-page p{ 
    margin-top: 25px;
}