:root {
    --background: rgb(255, 255, 255);
    --font-hover: #FCA2E0;
    --font: #F7F7F7;
    --active-button: #E81EBD; 
    --mpink: rgb(203,1,134);  //hot pink
    --mblue:rgb(23,144,244); //light blue
    --mlblue: rgb(203,236,255);
    --mred: rgb(214, 48, 36); //readable red
    --backcolor: #0d1117 ; //dark blue
    --trimcolor: #FCA2E0; // border trim
    --glow: rgb(45, 45, 241);// glow trim rgb(21, 5, 75)
    --backgray: rgb(21, 66, 110);//background dark gray #171b22 rgb(2,25,47) rgb(24, 42, 66)
    --mgreen: green; //green
    --mlgreen: rgb(26, 170, 26);//light green
    --myellow: yellow; //yellow
    --mdyellow: rgb(223, 223, 31); //dark yellow rgb(160, 160, 20)
    --mgray: rgb(105, 108, 112);//gray
    --morange: rgb(149, 97, 0); 
    --navcolor: white;
    --navhover: rgb(23,144,244);
    --navactive: rgb(23,144,244);
    --navactivehover: rgb(55, 108, 133); 
    --glowhead: 0px 0px 15px green ;
    --glowbody: 0px 0px 15px red ; 
    --colorbody: --mpink;
    --colorhead: green; 
    --headerback: rgb(248,248,250);
    --headcolor: rgb(44,56,71); 
    --colhighcolor: rgb(10,24,32); //rgb(10,24,32); 23,144,244
    --trim: rgb(231, 231, 231); 
    --backgroundcoursepreview: rgb(199,199,199); 
    --borderbottom: rgb(5,157,45);
    --titlepreview: rgb(3,27,48); 
    --txttitlepreview: rgb(119,123,124); 
    --placeholder: rgb(201, 197, 197);
    --quiztxt: rgb(0, 0, 0);
 
}

$quiztxt: var(--quiztxt);
$font-hover: var(--font-hover);
$font: var(--font);
$active-button:  var(--active-button);
$mpink:  var(--mpink);
$mblue:var(--mblue);
$mlblue:var(--mlblue);
$mred: var(--mred);
$backcolor: var(--backcolor);
$trimcolor: var(--trimcolor);
$glow: var(--glow);
$backgray: var(--backgray); // 
$mgreen:  var(--mgreen);
$mlgreen: var(--mlgreen);
$myellow: var(--myellow);
$mdyellow: var(--mdyellow);
$mgray:  var(--mgray);
$morange: var(--morange);
$navcolor:  var(--navcolor);
$navhover:  var(--navhover);
$navactive: var(--navactive);
$navactivehover:  var(--navactivehover);
$glowhead:  var(--glowhead);
$glowbody:  var(--glowbody);
$colorbody: var(--colorbody);
$colorhead:  var(--colorhead);
$headerback:  var(--headerback);
$headcolor:  var(--headcolor);
$background: var(--background);
$colhighcolor:  var(--colhighcolor);
$trim:  var(--trim);
$backgroundcoursepreview:  var(--backgroundcoursepreview); 
$borderbottom:  var(--borderbottom);
$titlepreview:  var(--titlepreview);
$txttitlepreview:  var(--txttitlepreview);
$placeholder:  var(--placeholder);

