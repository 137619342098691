 
  
  table {
    border-collapse: collapse;
    width: 100%; 
    overflow-x: auto;
  }
  
  th, td {
    border-top: 1px solid $trim;
    text-align: center;
    
  }
  
.tr-head {
    // background-color: white  ;
    color: $mgray;
    // font-size: 22px;
    font-size: 1.4vw;
    
   & th {
        // margin: 10px;
        padding: 10px 5px;
   }

  }
  
.tr-table td {
   
    color: $colhighcolor;
    padding: 5px 5px; 
    font-size: 1.2vw;

  } 
.main-body-table {
    margin: 22px;
}
// font curr 2 , 1.5, pl 1.5, 1.2, static 
.tr-table {
  &:hover{
    background-color: $trim;
  }
}
// .td-name {
//   &:hover {
//     background-color: $trim;
//   }
// }
.table-dropdown { 
  max-width: 50%;
  height: 100%;
  padding: 1px;  
}
  