
 

#link {
    text-decoration: none;
}

//#$@#$%(#!*#!#!@*$@!(#!*$@#(!@#!@#!@#*)#!~@$)
.main-body,.main-body-home {  
    text-align: center;
    margin: 100px;
    // margin-top: 100px;
    margin-top: 0px;
    font-size: 20px;
    padding: 25px; 
    border-radius: 25px;
    background: $backcolor;  
    box-shadow: $glowbody;
    color: $colorbody;  
}
.main-body-home {
    margin-top: 0px;
    font-size: 20px;
}
.p-1 {
    color: red;
    margin-bottom: 20px;
    margin-top: 20px;
}
 

 

//#$@#$%(#!*#!#!@*$@!(#!*$@#(!@#!@#!@#*)#!~@$)
.btn { 
    // width: 80px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    color: $backgray ;
    background: rgb(35, 104, 194);
    border: 1.5px solid rgb(101,167,255);
    position: relative;
    top: 3px;  
    height: 33px; 
    border-radius: 5px; 
    font-weight: 900;
    transition: .6s;
 &:hover , &:focus {
    // color: $backcolor ;
    // background:  rgb(35, 104, 194);
    border: 1.5px solid yellow;
    box-shadow: 0px 0px 1px yellow;
    color: yellow;
    outline: none;
 
 }
&:active, active {
    background: yellow;
    color: $backgray ;
    border: 1.5px solid rgb(101,167,255);
    transition: .0s;
}
}

