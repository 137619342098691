input,textarea {
    outline: none;
}

.txta-sys {
    height: 50px;
}
.txta-descrip {
    height: 200px;
}

.ctn-create-input input,.ctn-create-input textarea, .cnt-unit input, 
.cnt-quiz input, .cnt-quiz textarea ,.cnt-worksheet input, .input input {
    margin: 8px 0px 30px 0px;
    font-size: 16px;
    padding: 6px;
    border: 2px solid $trim;  
    border-radius: 7px;
    &:hover {
        border: 2px solid black;  
    } 
    &:focus {
        border: 2px solid $mblue;  
    }
} 

.ctn-check  input  {
// height: 50px;
transform: scale(1.5);
margin-right: 8px;
margin-top: 8px;
}

.cnt-create-img input {
    // transform: scale(1.2);
    margin: 10px;
    // left: 50px;
    position: relative;
    left: 40px;
}
.cnt-unit input,.cnt-quiz input,.cnt-quiz textarea {
    width: 600px;
    margin: 0px 20px 20px 20px;
}

.ctn-unit-footer input {
    width: 190px;
}
.ctn-lesson-video input {
    margin-bottom: 20px;
}
.cnt-answer input[type='checkbox']{ 
    position: relative;
    top: 11px;
    width: 18px;
    transform: scale(1.5);
    margin: 0px; 
}
.cnt-answer input[type='text'] { 
    width: calc(100%);


}
.cnt-worksheet input {
    margin: 10px 0px 0px 0px;
    width: calc(100% - 14px);
}

#input-take-check input[type='checkbox']{ 
    position: relative;
    top: 7px;
    // width: 18px;
    transform: scale(1.2);
    margin-right: 10px; 
}
#input-take-check {
    :hover {
        transform: scale(1.1);
      
    }
    
}

.input-indent {
    margin-left: 20px;
    margin-top: 10px;
}

::placeholder {
    color: $placeholder ;
}