.pay_all {
    display: flex;
    position: relative;
    -moz-box-sizing: border-box; //for paddding not resizeing
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;    
    
}

.pay_stripe_card {
    width: 320px;
    height: 30px;
    display: flex;
    align-items: center;
    color: black;
    & .StripeElement {
        width: 100%;
        padding: 15px;
    }
}

.pay_preview_logo {
    // text-align: left;
    // align-items: flex-start;
    // justify-content: left;
    width: 380px;
    display: flex; 
    align-items: center;
    justify-content: left;
    
    // background: black;
    position: relative;
    right: 0;
    margin-bottom: 50px;
    h1 {
        color: hsla(0,0%,100%,.9);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 500;
        font-size: 20px;
        margin-right: 10px;
        margin-left: 10px;
        
    }
}
.pay_preview_permonth {

    width: 380px; 
    h2 {
        font-weight: 500;
        font-size: 18px;
        color: hsla(0,0%,100%,.6);
        margin-bottom: 10px;
    }
    h3 {
        font-weight: 600;
        font-size: 33px;
        margin-right: 10px;
    }
    p {
        color: hsla(0,0%,100%,.6);
    }
    h5 {
        color: hsla(0,0%,100%,.6);
    }

}
.pay_preview_permonth_span {
    display: flex;
    margin-bottom: 10px;
    // p {
    //     text-align: ;
    // }

    
}

.pay_preview_cnt {
    min-height: 100vh;
    width: 50vw;//xox
    background: rgb(84,95,128); 
    padding: 122px 80px 122px 80px;
 
    display: flex;
    flex-direction: column; 
    align-items: end; 
}
.pay_card_cnt {
 
    min-height: 100vh;
    width: 50vw;//xox
    // background: rgb(18, 28, 58);
    background: white;
    padding: 122px 80px 122px 80px; 
    display: flex;
    flex-direction: column; 
    align-items: start; 
    h1 {
        color: black;
        font-weight: 700;
        font-size: 22px;
        
    }
    h2 {
        margin-top: 22px;
        color: rgb(94,94,94);
        font-weight: 500;
        font-size: 18px;
    }
    p {
        text-align: center;
        // margin-left: 10px;
        // margin-right: 10px;
        width: 406px;
        color: rgb(200,200,200);
        font-size: 13px;
    }
    button {
        margin: 22px 0 22px 0;
        width: 406px;
        height: 38px;
        border: none;
        color: rgb(155,197,239);
        background: rgb(40,117,216);
        border-radius: 6px;

    }
    // border-top-right-radius: 6px;
    // box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
}
.pay_input_card_month {
    // background: black;
    padding: 8px 12px;  
    // width: calc(50% + 12px + 12px); 
    width: 50%;
    color: black;  
    border: 1px  solid rgb(200, 200, 200); 
    border-top: 0;
    border-bottom-left-radius: 6px;
}
.pay_input_city, .pay_input_zip {
    
    //  
    padding: 8px 12px;
    width: 50%; 
    // width: calc(50% + 12px + 12px);
    // width: 166px;
    color: black;  
    border: 1px  solid rgb(200, 200, 200);   
}
.pay_input_zip {
    border-left: none;
}
.pay_card {
    width: 406px;
    
}
.pay_card_l {
    width: 406px;
}
.pay_stripe_ex {
    // pay_input_card_month
    // background: gray;
    // width: 171px;
    height: 30px;
    display: flex;
    align-items: center;
    color: black;

    // padding: 8px 12px;  
    // width: calc(50% + 12px + 12px); 
    width: 50%;
    // color: black;  
    border: 1px  solid rgb(200, 200, 200); 
    border-top: 0;
    border-bottom-left-radius: 6px;



    & .StripeElement {
        width: 100%;
        padding: 15px;
    }
}
.pay_stripe_cvc {
    // pay_input_card_cvc
    // background: red;
    // width: 171px;
    width: 50%;
    height: 30px;
    display: flex;
    align-items: center;
    color: black;

    border: 1px  solid rgb(200, 200, 200); 
    border-top: 0;
    border-bottom-right-radius: 6px;
    border-left: none;

    & .StripeElement {
        width: 100%;
        padding: 15px;
    }
}
.pay_input_card_cvc {
    // width: 100%; 
    width: 50%;
    padding: 8px 12px;
    color: black;  
    border: 1px  solid rgb(200, 200, 200); 
    border-top: none;
    border-left: none;
    border-bottom-right-radius: 6px;
}
.pay_input_name {
    width: 380px;
    padding: 8px 12px;
    color: black;
    border-radius: 6px; 
    border: 1px  solid rgb(200, 200, 200); 
}
.pay_input_bill_address {
    width: 380px;
    padding: 8px 12px;
    color: black; 
    border: 1px  solid rgb(200, 200, 200); 
    border-bottom: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.pay_input_state {
    width: 380px;
    padding: 8px 12px;
    color: black; 
    border: 1px  solid rgb(200, 200, 200); 
    border-top: none;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.pay_input_email_l, .pay_input_phone {
    width: 380px;
    padding: 8px 12px;
    color: black;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border: 1px  solid rgb(200, 200, 200); 
}
.pay_input_email {
    // width: 345px;
    width: 404px;
    // padding: 8px 12px;
    // color: black;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border: 1px  solid rgb(200, 200, 200); 
}
.pay_input_phone {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.pay_preview_plan_top {
    padding: 15px;
    margin-top: 22px;
    width: 380px; 
    border: 1px  solid hsla(0,0%,100%,.1);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: none;
    h4 {
        font-weight: 500;
    }
    p {
        margin-top: 5px;
        font-size: 12px;
        color: hsla(0,0%,100%,.6);
    }

    // background: red;
    // height: 222px;
}
.pay_preview_plan_top_span {
    display: flex;
    justify-content: space-between;
}
.pay_preview_plan_bottom {
    display: flex;
    text-align: center;
    width: 380px; 
    border: 1px  solid hsla(0,0%,100%,.1);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    // height: 222px;
    background: rgb(93,103,135);
    padding: 15px;
    margin-bottom: 22px;
    


}
.pay_preview_spam_bottom { 
    // background: red; 
    position: relative;
    top: 2px;
    margin-left: 8px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    width: 100%;
    h5 {
        color: white;
        position: relative;
        top: 2px; 
    }
}
.pay_preview_span_bottom {
    position: relative;
    top: 2px;
    margin-left: 8px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    width: 100%; 
    data {
        position: relative;
        top: -2.5px; 
        color: rgb(32,98,68);
        padding: 3px;
        background: rgb(207,244,199);
        border-radius: 6px;
        margin-left: 5px; 
        height: 16px; 
    }
    h5 {
        text-align: right;
        position: relative;
        top: -4px; 
        color: white; 
        font-size: 12px;
        font-weight: 500;
    }
    h4 {
        text-align: right;
        position: relative;
        top: -4px; 
        font-size: 12px;
        font-weight: 500;
    }
}
.pay_preview_total {
    display: flex;
    justify-content: space-between;
    width: 380px; 
    border-top: 1px  solid hsla(0,0%,100%,.1);
    padding-top: 15px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 22px;
    // background: black;
}
.pay_err {
    margin-bottom: 10px; 
    color: red !important;
    
}
@media (max-width: 920px) {
    .pay_all {
        flex-direction: column;
    }
    .pay_preview_cnt, .pay_card_cnt {
        min-height: 50vh;
        width: 100vw; //xox
        padding: 33px 0 33px 0;
        align-items: center; 
    }  
    .pay_preview_permonth {
        display: flex; 
        align-items: center; 
        flex-direction: column; 
    }
    .pay_preview_logo {
        justify-content: center;

    }
    .pay_preview_plan_top, .pay_preview_plan_bottom, .pay_preview_logo
    .pay_preview_permonth, .pay_preview_total,  
    .pay_input_name, .pay_input_bill_address, .pay_input_state, .pay_input_email_l,
    .pay_input_phone {
        width: 320px;
    }
    .pay_card, .pay_card_cnt p, .pay_card_cnt button {
        width: 346px;
    }
    .pay_input_email {
        width: 344px;
    }
    .pay_card_l {
        width: 346px;
    }
    .pay_preview_span_bottom data{
        height: 30px;
    }
}
