.nav-container {
    z-index: 99;
    font-size: 20px;
    display: flex; 
    gap: 25px;
    justify-content: flex-end;//right
    padding-top: 10px; 
    padding-bottom: 10px;
    padding-right: 30px; 
    top: 0px;
    left: 0px;    
    right: 0px;
    background: $backgray; 
    // background: transparent; 
    // position: fixed; 
    border-bottom:1px solid black;
    // box-shadow: 0px 0px 10px black;

    & img {
        position: absolute;
        // height: 100px; 
        height: 50px;
        
        // left: 15px;
        left: 20px;
        transition: .5s;

        top: 3px;
    
} 
    & svg {
        position: absolute;
        // height: 100px; 
        height: 40px;
        // width: 100px;
        // top: 10px;
        // left: 15px;
        left: 90px;  
        top: 5px;
        color: red;
        // font-family: Comic Sans MS, Comic Sans, cursive ; 
        
    }
    & titlelogo {
        position: absolute; 
        height: 200px; 
        left: 110px;  
        top: 11px;
        color: #343b48;
        font-size: 30px;
        // font-family: MS Gothic;
        font-weight: 700;
        letter-spacing: 0px;
    }

    a {
        text-decoration: none;
         
        color: $navcolor;
        // font-weight: bold !important;
        // font-weight: inherit;
        // font-size: 18px;
        

        &:hover {
            color: $navhover;
            
        }
    }


}
.nav-logo {
    font-family: Comic Sans MS, Comic Sans, cursive ; 
    color: red;
}
.nav-redirect {
    
    position: absolute;
    z-index: -1;
    top: 50px;
    right: 0px;
    left: 547px;
    background: rgb(31,30,66);  

    // temporary
    color: transparent;
    background-color: transparent;

} 
 