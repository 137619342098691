@media (max-width: 777px) {

    .freeweb_vidp {
        display: block;
    }
    .freeweb_wrapper {
        margin-top: 30px;
        width: 100%;
    }
    .freeweb_vid_cnt {
        width: 100%;
        height: calc(100% * .5625 );
    
        margin-right: 0px;
        
    }
    .freeweb_info_wrap {
        padding: 20px 20px 20px 50px; 
        width: 250px; 
    }
    .loginUser input[type='password'],.loginUser input[type='text'] {  
        width: 200px; 
    }
} 