.img-course, .img-student  {
    height: 200px;
    width: 250px ; 
    // object-fit: cover;
}

.img-student {
    margin-top: 40px;
    color: $trim;
}

.img-edit,.img-delete2 {
    position: relative;
    top: 2px;
    left: -5px; 
    
}
.img-delete2 {
    left: -2px; 
}

.img-delete {
    position: relative;
    border-left: none;
    left: -3px;
    top: 1.5px;
    padding-left: 0;
    color: $mblue;
    z-index: 5;
}
.img-delete-followup {
    position: relative;
    border-left: none;
    left: -3px;
    top: 1.5px;
    padding-left: 0;
    color: $mblue;
    z-index: 5;
    cursor: pointer;
}

.img-x {
    color: red;
    position: relative;
    top: 7px;
    transform: scale(1.6);
}
.img-detail, .img-play, .img-worksheet, .img-download, .img-question {
    color: $mblue;
    margin-top: 1px;
    margin-right: 5px;
    margin-left: 5px;
}
.img-download {
    transform: scale(1.3);
}
.img-detail {
    transform: scale(1.1);
}

.img-x-calls {
    color: red;
    position: relative;
    top: 22.5px;
    transform: scale(1.6);
    margin-left: 10px;
}

.img-kid-courses img{
    width: 400px;
    height: calc( 400px * 0.5625 ); 
    border-radius: 10px;
    transition: .2s;
    &:hover {
        transform: scale(1.03);
        box-shadow: 0px 0px 10px black;
    }
}
.img-courses-detail, .img-courses-play, .img-courses-worksheet, .img-courses-download, .img-courses-question {
    color: $mblue;
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px; 
}
.img-courses-download {
    transform: scale(1.3);
}
.img-courses-detail {
    transform: scale(1.1);
}
 

.img-kid-course img{
    width: 400px;
    height: calc( 400px * 0.5625 ); 
    border-radius: 10px;
    transition: .2s;
    margin: 25px;
    &:hover {
        transform: scale(1.03);
        box-shadow: 0px 0px 10px black;
    }
}

.img-check {
    position: relative;
    margin-right: 5px;
    top: 3px;
    color: green;
}

#img-close {
    color: red;
    transform: scale(1.1); 
    margin-top: 4px;
    margin-left: 10px;


}

#img-check {
    color: $mlgreen;
    transform: scale(1.3);
    margin-top: 2px;
    margin-left: 10px;
}

#img-download-take  {
    color: $mblue;
    transform: scale(2.5);
    margin-top: 15px;
    margin-left: 25px;
    &:hover {
        transform: scale(3);
    }
}

.img-check-homework {
    position: absolute;
    color: $mlgreen;
    transform: scale(1.3);
    margin-top: 10px;
    margin-left: 10px;
    left: 850px;
}
.img-edit-calls {
    color: $mblue;
    position: relative;
    top: 22px;
    transform: scale(1.2);
    margin-left: 15px;
}
.img-edit-role {
    position: relative;
    top: 1px;
}