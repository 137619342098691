#flex-center {
    display: flex;
    justify-content: center;
}

#flex-start {
    display: flex;
    justify-content: start;
}

#flex-between {
    display: flex;
    justify-content: space-between;
}
.id-top20 button{
   margin-top: 20px;
}