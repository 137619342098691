// main-body-ctn
//     display: flex;
//     flex-direction: column;
//     min-height: 100%;
// }
// .body-wrapper {
//     margin-top: auto;
// .page-con {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     min-height: 100%;
//     left: 0;
//     padding: 0;
//     margin: 0;
//     flex: 1;
// }
// .footer-all-con {//
//     position: relative;
//     width: 100%;
//     left: 0;
//     padding: 0;
//     margin: 0;
//     flex: 1;
// }
// .main-body-ctn {
//     margin: 0px;
//     padding: 0px;
//     box-sizing: 0; 
//     position: relative;
//     min-height: 100vh;
//     padding-bottom: 60px;


    
// }
// .main-body-wrap {
//     min-height: 100vh;
//     position: relative;
// }
// .footer-all-con{
//     position: relative;
//     bottom: 0;
//     width: 100%;
// }
.div-footer-main { //in the component
    // position: relative;
    // width: 100%;
    // left: 0;
    // padding: 0;
    // margin: 0;
    // flex: 1;
    // padding: 200px;
    // margin: 200px;
    // position: relative;
    // bottom: 0;
    // padding-bottom: 0;

    // margin-bottom: 0;
    // width: 100%;
    display: flex;
    justify-content: space-between; 
    background-color: $backgray;
    color: $mblue;
    border-top: 1.5px solid $backcolor;
    // box-shadow: 0px -1px 3px black; 
}

// .adsad {
//     position: relative;
//     margin-top: auto;
//     // bottom: 0;
//     width: 100%;
//     // // height: 2.5rem;  
//     left: 0;
//     // // bottom: 0;
//     // // width: 100%;
//     // height: 111px; 
// }


.f1, .f2, .f3, .f4, .f5  {//main 
    color: $mlblue;
    width: 15%;
    text-align: center;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
 
} 
.f1 {
    padding-left: 12.5%; 
    padding-top: 10px;
}
.f2 {
    padding-top: 10px;
     
}
.f3  { 
    
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.f4  { 
    padding-top: 10px;
}
.f5  { 
    // padding-right: 150px;
    padding-right: 12.5%; 
    padding-top: 10px;
} 

.footer-title {
    color: green;
    font-size: 20px;
    
}

.foot-links {
    color: $mlblue;
    text-decoration: none;
}

.footer-logo {  
    margin: auto;
    position: relative;
    height: 50px;
    top: 0px;
    height: 88px; 
    // top: 10px;
    // left: 15px;
    transition: transform .4s;
    transition: .4s;
    &:hover {
        transform: scale(1.4); 
        top: -25px;
    }
} 
