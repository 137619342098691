.column-ctn{
    display: flex;
    // justify-content: start;
    // position: fixed;
    
    
}
.column {   
    // text-align : center;
    padding-left: 35px;
    padding-top: 40px;
    
    
    
    min-width: 180px;  
    max-width: 180px; 
    // margin-top: 100px;
    background-color: $background;
    // color: $colhighcolor;
    border-right: 2px solid $trim; 
    min-height: 100vh; 
    justify-content: center;
    
}


// column-ctn
// column
// column-body