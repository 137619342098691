/*===================== Variable =====================*/
/*=== Grid & Layout ===*/
/*=== Color === */
/*=== Background Color ===*/
.perpagecss {
    // width: 100vw !important; 

// #site-header, #headerbar, .wrapper, .nav_wrapper, #site-footer {
//   width: 100vw !important;
// }
.wrapper {
  width: 100% !important;
  // padding-left: 22px;
  // padding-right: 22px;
  // overflow-wrap: break-word;  
}
.custom-info {
  position: relative;
  left: 30px;
}
.top-navigator {
  position: relative;
  right: 30px;
}
.logo-ft {
  position: relative;
  left: -50px;
  height: 50px;
  width: 190px;
}
.row {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}
.custom-infox {
  margin-left: 30px ;
}
.columnsx {
  // position: relative;
  // left: 50px;
}
@media (max-width: 999px) { 
.logo-ft { 
  left: 30px; 
}
  .row {
      flex-direction: column;
      justify-content: center; 
    align-items: center;
  }
}
#headerbar {
  height: 70px;
}

.clear,
.clear-after:after,
.clear-before:before,
.row:after {
  content: '';
  display: table;
  clear: both;
}
.row,
.sidebar-right #page-body .content-wrap,
.sidebar-left #page-body .content-wrap {
  margin-left: -15px;
  margin-right: -15px;
}
.columns,
.sidebar-right #page-body .content-wrap .content,
.sidebar-right #page-body .content-wrap .sidebars,
.sidebar-left #page-body .content-wrap .content,
.sidebar-left #page-body .content-wrap .sidebars {
  float: left;
  padding: 0 15px;
}
.columns-1 {
  width: 8.333333333333334%;
}
.columns-2 {
  width: 16.666666666666668%;
}
.columns-3,
.sidebar-right #page-body .content-wrap .sidebars,
.sidebar-left #page-body .content-wrap .sidebars {
  width: 25%;
}
.columns-4 {
  width: 33.333333333333336%;
}
.columns-5 {
  width: 41.666666666666664%;
}
.columns-6 {
  width: 50%;
}
.columns-7 {
  width: 58.333333333333336%;
}
.columns-8 {
  width: 66.66666666666667%;
}
.columns-9,
.sidebar-right #page-body .content-wrap .content,
.sidebar-left #page-body .content-wrap .content {
  width: 75%;
}
.columns-10 {
  width: 83.33333333333333%;
}
.columns-11 {
  width: 91.66666666666667%;
}
.columns-12 {
  width: 100%;
}
.pull-1 {
  left: -8.33333333%;
}
.pull-2 {
  left: -16.66666667%;
}
.pull-3 {
  left: -25%;
}
.pull-4 {
  left: -33.33333333%;
}
.pull-5 {
  left: -41.66666667%;
}
.pull-6 {
  left: -50%;
}
.pull-7 {
  left: -58.33333333%;
}
.pull-8 {
  left: -66.66666667%;
}
.pull-9,
.sidebar-left #page-body .content-wrap .sidebars {
  left: -75%;
}
.pull-10 {
  left: -83.33333333%;
}
.pull-11 {
  left: -91.66666667%;
}
.pull-12 {
  left: -100%;
}
.push-1 {
  left: 8.333333333333334%;
}
.push-2 {
  left: 16.666666666666668%;
}
.push-3,
.sidebar-left #page-body .content-wrap .content {
  left: 25%;
}
.push-4 {
  left: 33.333333333333336%;
}
.push-5 {
  left: 41.666666666666664%;
}
.push-6 {
  left: 50%;
}
.push-7 {
  left: 58.333333333333336%;
}
.push-8 {
  left: 66.66666666666667%;
}
.push-9 {
  left: 75%;
}
.push-10 {
  left: 83.33333333333333%;
}
.push-11 {
  left: 91.66666666666667%;
}
.push-12 {
  left: 100%;
}
/*============================================================ 
                        CSS OVERVIEWS
                1. GENERAL
                2. LAYOUT
                3. HEADER
                	3.1. Headerbar
                	3.2. Masthead
                		3.1.1. Site Brand
                		3.1.2. Site Navigator
                		3.1.3. Site Navigator Mobile
                4. CONTENT
                	4.1. Page Header
                	4.2. Page Breadcrumbs
                	4.3. Page Body
                5. WIDGET
                6. FOOTER
                7. BLOG	
                8. NAVIGATION
                9. OTHER PAGES
                	9.1. Author
                	9.2. Search
                	9.3. Error404
                	9.4. Blank Page
                10. MEMBER
                11. PROJECTS	
                12. OTHER
                	12.1. Comments
                	12.2. Form
                	12.3. Gallery
                	12.4. Go To Top
                           	
============================================================*/
/* ============================================================ 
    1. GENERAL
=============================================================*/
// *,
// *:after,
// *:before {
// //   -webkit-box-sizing: border-box;
// //   -moz-box-sizing: border-box;
// //   box-sizing: border-box;
//   text-rendering: optimizeLegibility;
//   outline: 0;
// }
// *:focus,
// *:after:focus,
// *:before:focus {
//   outline: 0;
// }
// *:after,
// *:before {
//   -moz-osx-font-smoothing: grayscale;
//   -webkit-font-smoothing: antialiased;
// }



.page-loading .loading-overlay {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  display: block;
  visibility: visible;
}
.loading-overlay {
  position: fixed;
  background: #ffffff;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  visibility: hidden;
  display: none;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
.loading-overlay:after {
  position: fixed;
  content: "";
  width: 64px;
  height: 64px;
  left: 50%;
  margin-left: -32px;
  top: 50%;
  margin-top: -32px;
//   background: url(../images/preloader.gif);
}
.clear:before,
.clear:after {
  content: ' ';
  display: table;
}
.clear:after {
  clear: both;
}
.clear,
.clear-after:after,
.clear-before:before,
.row:after {
  *zoom: 1;
}
.transition,
.loading-overlay,
.loading-overlay:after,
a,
a[rel="wp-video-lightbox"]:before,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input.input-text,
input[type="submit"],
button[type="submit"],
.button,
#site-header #headerbar .top-navigator .menu li .sub-menu,
#site-header #masthead #site-navigator .menu li .sub-menu,
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart .submenu,
.widget.widget_search .search-form .search-submit,
.navigation.post-navigation .nav-links li a:after,
.navigation.loadmore a:after,
.search #main-content .content-inner .search-form .search-submit,
.search #main-content .content-inner .search-results article .counter,
.error404 #main-content .content-inner .content-404 .search-form .search-submit,
.gallery-caption,
.goto-top,
.goto-top a:after,
.history li h3,
.social-links a i,
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail a img,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail a img,
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a:after,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a:after,
.projects.projects-grid .projects-items .hentry .project-wrap .project-info,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-info,
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info,
.projects.projects-justified .projects-items .hentry > a:after,
.projects .projects-items,
.blog .hentry .entry-cover a:after,
.single-post .hentry .entry-cover a:after,
.blog .hentry .entry-cover a:before,
.single-post .hentry .entry-cover a:before,
.blog .hentry .entry-cover a img,
.single-post .hentry .entry-cover a img,
.woocommerce .products li,
.woocommerce-page .products li,
.woocommerce .products li .product-info h3,
.woocommerce-page .products li .product-info h3,
.woocommerce .products li .product-info .woocommerce-loop-product__title,
.woocommerce-page .products li .product-info .woocommerce-loop-product__title,
.woocommerce .products li .product-info .secondary-image,
.woocommerce-page .products li .product-info .secondary-image,
.woocommerce .products li.product-category a img,
.woocommerce-page .products li.product-category a img,
#site-wrapper .wpb_single_image .vc_figure .vc_figure-caption,
#site-wrapper .wpb_single_image .vc_figure a img,
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item a img,
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item a:after,
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item a:before,
#site-wrapper .iconbox,
#site-wrapper .iconbox .box-header .box-icon i,
#site-wrapper .iconbox .box-readmore a:before,
#site-wrapper .imagebox .box-header,
#site-wrapper .imagebox .box-content,
#site-wrapper .imagebox .box-content .box-button a:after,
#site-wrapper .iconlist li.circle i,
#site-wrapper .owl-controls .owl-buttons > div,
#site-wrapper .owl-controls .owl-buttons > div:after,
#site-wrapper .owl-controls .owl-buttons > div.owl-next:after,
#site-wrapper .vc_toggle .vc_toggle_title h4:after,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-wrapper,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover .entry-time,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover a:after,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover a:before,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover a img,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-content-wrap,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-content-wrap:after,
#site-wrapper .posts-carousel .hentry .entry-cover a img,
.header-v3 #site-header #masthead #site-navigator .menu.menu-extra .search-box .submenu .search-field,
.header-v2 #site-header #masthead #site-navigator .menu.menu-extra .search-box .submenu {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.wp-caption {
  max-width: 100%;
}
.wp-caption-text {
  font-size: 12px;
  font-style: italic;
  color: #b3b3b3;
}
.alignleft {
  float: left;
  margin: 10px 20px 10px 0;
  clear: left;
}
.alignright {
  float: right;
  margin: 10px 0 10px 20px;
  clear: right;
}
.aligncenter {
  text-align: center;
}
.bypostauthor {
  font-weight: normal;
}
.wrapper {
  margin: 0 auto;
  position: relative;
}
.wrapper:after {
  clear: both;
  content: "";
  display: table;
}
.group-btn .button:first-child {
  margin-right: 8px;
}
.group-btn .button:last-child {
  margin-left: 8px;
}
.group-btn .link {
  margin-left: 30px;
  color: #ffffff;
  padding-bottom: 2px;
}
.group-btn .link:hover {
  border-color: #ffffff;
}
html {
  overflow-x: hidden;
}
body {
  font-size: 14px;
  line-height: 1.8;
  // background: #ffffff;
  overflow-x: hidden;
}
body i.fa {
  font-size: 14px;
}
body i.fa.fa-2x {
  font-size: 2em;
}
body i.fa.fa-3x {
  font-size: 3em;
}
body i.fa.fa-4x {
  font-size: 4em;
}
body i.fa.fa-5x {
  font-size: 5em;
}
body i.fa.icons {
  font-family: "simple-line-icons";
}
a {
  text-decoration: none;
}
a:hover {
  color: #333333;
}
a.link {
  border-bottom: 2px solid;
  font-weight: bold;
}
a.link2 {
  font-weight: bold;
  text-transform: uppercase;
}
a.link2 i {
  font-size: 14px;
  font-weight: normal;
  margin: 0 10px;
}
a[rel="wp-video-lightbox"] {
  position: relative;
  display: inline-block;
}
a[rel="wp-video-lightbox"]:before {
  width: 60px;
  height: 60px;
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -30px;
  right: 50%;
  margin-right: -30px;
}
a[rel="wp-video-lightbox"]:after {
  font-family: "FontAwesome";
  content: "\f04b";
  font-size: 20px;
  font-weight: normal;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -18px;
  margin-right: -9px;
}
p {
  margin-top: 0;
  margin-bottom: 20px;
}
p:last-child {
  margin-bottom: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 30px 0 20px 0;
  color: #15416e;
  line-height: 1.1;
  text-transform: uppercase;
}
h1.no-margin-top,
h2.no-margin-top,
h3.no-margin-top,
h4.no-margin-top,
h5.no-margin-top,
h6.no-margin-top {
  margin-top: 0;
}
h1.style1,
h2.style1,
h3.style1,
h4.style1,
h5.style1,
h6.style1 {
  border-bottom: 1px solid rgba(54, 70, 115, 0.08);
  padding-bottom: 20px;
}
h1.style2,
h2.style2,
h3.style2,
h4.style2,
h5.style2,
h6.style2 {
  position: relative;
  padding-bottom: 22px;
  overflow: hidden;
}
h1.style2:after,
h2.style2:after,
h3.style2:after,
h4.style2:after,
h5.style2:after,
h6.style2:after {
  width: 50px;
  height: 3px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}
h1.style2:before,
h2.style2:before,
h3.style2:before,
h4.style2:before,
h5.style2:before,
h6.style2:before {
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  left: 65px;
  bottom: 1px;
  background: rgba(54, 70, 115, 0.15);
}
h1.style2[style="text-align: center;"]:after,
h2.style2[style="text-align: center;"]:after,
h3.style2[style="text-align: center;"]:after,
h4.style2[style="text-align: center;"]:after,
h5.style2[style="text-align: center;"]:after,
h6.style2[style="text-align: center;"]:after {
  left: 50%;
  margin-left: -25px;
}
h1.style2[style="text-align: center;"]:before,
h2.style2[style="text-align: center;"]:before,
h3.style2[style="text-align: center;"]:before,
h4.style2[style="text-align: center;"]:before,
h5.style2[style="text-align: center;"]:before,
h6.style2[style="text-align: center;"]:before {
  left: 0;
}
h1.style2.bg-dark:before,
h2.style2.bg-dark:before,
h3.style2.bg-dark:before,
h4.style2.bg-dark:before,
h5.style2.bg-dark:before,
h6.style2.bg-dark:before {
  background: rgba(241, 242, 248, 0.1);
}
h1 i.fa,
h2 i.fa,
h3 i.fa,
h4 i.fa,
h5 i.fa,
h6 i.fa {
  font-size: 14px;
  font-weight: normal;
  margin-right: 20px;
}
h1 i.fa.fa-2x,
h2 i.fa.fa-2x,
h3 i.fa.fa-2x,
h4 i.fa.fa-2x,
h5 i.fa.fa-2x,
h6 i.fa.fa-2x {
  font-size: 28px;
}
h1 i.fa.border,
h2 i.fa.border,
h3 i.fa.border,
h4 i.fa.border,
h5 i.fa.border,
h6 i.fa.border {
  border: 1px solid;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 26px;
  margin-right: 15px;
}
.title-button {
  position: relative;
}
.title-button .button {
  position: absolute;
  right: 0;
  top: -4px;
}
ul.style1,
ul.style2 {
  list-style: none;
  margin: 20px 0;
  padding: 0;
}
ul.style1 li,
ul.style2 li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
}
ul.style1 li:last-child,
ul.style2 li:last-child {
  margin-bottom: 0;
}
ul.style1 li:before,
ul.style2 li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 2px solid;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
ul.style1 li:after,
ul.style2 li:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 10px;
  width: 5px;
  height: 5px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
ul.style2 {
  padding: 30px;
  background: #f1f2f8;
}
img {
  border: medium none;
  height: auto;
  max-width: 100%;
  vertical-align: top;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input.input-text {
  background: #d8e7ef;
  position: relative;
  border: none;
  padding: 0;
  border: 1px solid transparent;
  height: 45px;
  max-width: 100%;
  padding: 10px 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
textarea:disabled,
input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="datetime"]:disabled,
input[type="datetime-local"]:disabled,
input[type="date"]:disabled,
input[type="month"]:disabled,
input[type="time"]:disabled,
input[type="week"]:disabled,
input[type="number"]:disabled,
input[type="email"]:disabled,
input[type="url"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="color"]:disabled,
input.input-text:disabled {
  cursor: no-drop;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input.input-text:focus {
  background: #ffffff;
}
textarea {
  height: auto;
  line-height: 1.8em;
  max-height: 150px;
  resize: none;
}
select {
  border: 1px solid transparent;
  // background: #d8e7ef;
  height: 45px;
  padding: 0 10px;
  line-height: 100%;
  outline: 0;
  max-width: 100%;
//   background-image: url(../images/caret.png);
  background-position: 93% center;
  background-repeat: no-repeat;
  position: relative;
  text-indent: 0.01px;
  text-overflow: '';
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
input[type="submit"],
button[type="submit"],
.button {
  color: #ffffff;
  background-image: none;
  height: 45px;
  line-height: 45px;
  padding: 0 30px;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  display: inline-block;
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
input[type="submit"]:hover,
button[type="submit"]:hover,
.button:hover {
  color: #ffffff;
}
input[type="submit"]:disabled,
button[type="submit"]:disabled,
.button:disabled {
  background: #f2f2f2;
  color: #b3b3b3;
  cursor: no-drop;
  border: none;
}
input[type="submit"].outline,
button[type="submit"].outline,
.button.outline {
  background: none;
  border: 2px solid;
  line-height: 41px;
  text-shadow: none;
}
input[type="submit"].outline:hover,
button[type="submit"].outline:hover,
.button.outline:hover {
  background: none !important;
}
input[type="submit"].rounded,
button[type="submit"].rounded,
.button.rounded {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}
input[type="submit"].lg,
button[type="submit"].lg,
.button.lg {
  height: 55px;
  line-height: 55px;
  padding: 0 35px;
}
input[type="submit"].lg.outline,
button[type="submit"].lg.outline,
.button.lg.outline {
  line-height: 51px;
}
input[type="submit"].sm,
button[type="submit"].sm,
.button.sm {
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
}
input[type="submit"].sm.outline,
button[type="submit"].sm.outline,
.button.sm.outline {
  line-height: 36px;
}
input[type="submit"].white,
button[type="submit"].white,
.button.white {
  background-color: #ffffff;
  text-shadow: none;
}
input[type="submit"].white:hover,
button[type="submit"].white:hover,
.button.white:hover {
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
input[type="submit"].white.outline,
button[type="submit"].white.outline,
.button.white.outline {
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: none;
}
input[type="submit"].dark,
button[type="submit"].dark,
.button.dark {
  background-color: #333333;
}
input[type="submit"].scheme2,
button[type="submit"].scheme2,
.button.scheme2 {
  color: #ffffff;
}
input[type="submit"].scheme2.outline,
button[type="submit"].scheme2.outline,
.button.scheme2.outline {
  background: none;
}
input[type="submit"].scheme3,
button[type="submit"].scheme3,
.button.scheme3 {
  color: #ffffff;
}
input[type="submit"].scheme3.outline,
button[type="submit"].scheme3.outline,
.button.scheme3.outline {
  background: none;
}
input[type="submit"].download,
button[type="submit"].download,
.button.download {
  width: 100%;
  text-align: center;
  color: #ffffff;
  padding-left: 0;
  padding-right: 0;
}
input[type="submit"].border,
button[type="submit"].border,
.button.border {
  border: 1px solid rgba(54, 70, 115, 0.08);
}
input[type="submit"].border i,
button[type="submit"].border i,
.button.border i {
  border-left: 1px solid rgba(54, 70, 115, 0.08);
  text-align: center;
  vertical-align: top;
  width: 45px;
  height: 43px;
  line-height: 43px;
  margin-left: 30px;
  margin-right: -31px;
}
input[type="submit"].border.sm i,
button[type="submit"].border.sm i,
.button.border.sm i {
  width: 40px;
  height: 38px;
  line-height: 38px;
  margin-left: 25px;
  margin-right: -26px;
}
input[type="submit"].border.lg i,
button[type="submit"].border.lg i,
.button.border.lg i {
  width: 55px;
  height: 53px;
  line-height: 53px;
  margin-left: 35px;
  margin-right: -36px;
}
input[type="submit"] i,
button[type="submit"] i,
.button i {
  margin-left: 10px;
  font-weight: normal;
  font-size: 14px;
}
blockquote {
  margin: 30px 50px;
  position: relative;
  border-left: 3px solid;
  padding-left: 20px;
}
cite {
  display: block;
  margin: 15px 0 0 0;
  font-size: 0.9em;
  color: #b3b3b3;
}
table {
  width: 100%;
  background: #ffffff;
}
table thead {
  text-align: left;
}
table thead th {
  padding: 20px;
  color: #ffffff;
  font-weight: normal;
}
table tbody tr:nth-child(2n) {
  background: #e8f1f5;
}
table tbody tr td,
table tbody tr th {
  padding: 20px;
  text-align: left;
}
table tfoot tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: left;
}
table tfoot tr:last-child {
  border-bottom: none;
}
table tfoot tr th,
table tfoot tr td {
  padding: 20px;
  color: #ffffff;
}
table tfoot tr th a,
table tfoot tr td a {
  color: #ffffff;
}
dl dd {
  margin: 0 0 20px 0;
}
pre {
  background: rgba(0, 0, 0, 0.05);
  padding: 20px;
}
figure {
  margin: 1em 0;
}
figcaption {
  margin: 10px 0;
}
article:after {
  clear: both;
  content: "";
  display: table;
}
/* ============================================================ 
    2. LAYOUT
=============================================================*/
.sidebar-right #page-body .content-wrap {
  position: relative;
}
.sidebar-right #page-body .content-wrap:before {
  width: 1px;
  top: 0;
  bottom: 0;
  content: "";
  background: rgba(54, 70, 115, 0.08);
  left: 70%;
  position: absolute;
}
.sidebar-right #page-body .content-wrap:after {
  clear: both;
  content: "";
  display: table;
}
.sidebar-right #page-body .content-wrap .content {
  padding-right: 30px;
  width: 70%;
}
.sidebar-right #page-body .content-wrap .sidebars {
  padding-left: 30px;
  width: 30%;
}
.sidebar-left #page-body .content-wrap {
  position: relative;
}
.sidebar-left #page-body .content-wrap:before {
  width: 1px;
  top: 0;
  bottom: 0;
  content: "";
  background: rgba(54, 70, 115, 0.08);
  left: 30%;
  position: absolute;
}
.sidebar-left #page-body .content-wrap:after {
  clear: both;
  content: "";
  display: table;
}
.sidebar-left #page-body .content-wrap .content {
  padding-left: 30px;
  width: 70%;
  left: 30%;
}
.sidebar-left #page-body .content-wrap .sidebars {
  padding-right: 30px;
  width: 30%;
  left: -70%;
}
.layout-boxed {
  background-color: #cccccc;
}
.layout-boxed #site-wrapper {
  margin: 0 auto;
  overflow: hidden;
  background: #ffffff;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.layout-boxed.page-blank #site-wrapper {
  width: 100%;
}
.admin-bar #site-header #masthead.stick {
  top: 32px;
}
.bg-scheme,
.bg-scheme2 {
  color: #ffffff;
}
.pageheader-hidden #site-content #page-breadcrumbs {
  border: none;
  background: #f1f2f8;
}
/* ============================================================ 
    3. HEADER
=============================================================*/
#site-header {
  /* ============================================================ 
	    3.1. Headerbar
	=============================================================*/
  /* ============================================================ 
	    3.1. Masthead
	=============================================================*/
}
#site-header #headerbar {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  padding: 9px 0;
}
#site-header #headerbar a {
  color: #ffffff;
}
#site-header #headerbar a:hover {
  color: inherit;
}
#site-header #headerbar .social-links {
  float: left;
  margin-right: 20px;
}
#site-header #headerbar .social-links a {
  float: left;
  display: block;
  margin-right: 10px;
}
#site-header #headerbar .custom-info {
  float: left;
}
#site-header #headerbar .custom-info i {
  margin-right: 10px;
  margin-left: 30px;
  width: 28px;
  height: 28px;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 26px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.1);
}
#site-header #headerbar .custom-info .spacer {
  width: 30px;
  display: inline-block;
}
#site-header #headerbar .top-navigator {
  float: right;
  margin-top: 2px;
}
#site-header #headerbar .top-navigator .menu {
  margin: 0;
  padding: 0;
}
#site-header #headerbar .top-navigator .menu li {
  list-style: none;
  float: left;
  z-index: 9;
}
#site-header #headerbar .top-navigator .menu li:last-child a {
  border-right: 0;
  padding-right: 0;
}
#site-header #headerbar .top-navigator .menu li a {
  padding: 0 15px;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
  font-weight: bold;
}
#site-header #headerbar .top-navigator .menu li:hover a {
  color: #ffffff;
}
#site-header #headerbar .top-navigator .menu li.menu-item-has-children {
  position: relative;
  z-index: 999;
}
#site-header #headerbar .top-navigator .menu li.menu-item-has-children:hover > .sub-menu {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  display: block\9;
  visibility: visible;
}
#site-header #headerbar .top-navigator .menu li .sub-menu {
  position: absolute;
  top: 35px;
  left: 0;
  width: 200px;
  margin: 0;
  padding: 10px 0;
  visibility: hidden;
  display: none\9;
  background: #ffffff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
#site-header #headerbar .top-navigator .menu li .sub-menu li {
  float: none;
}
#site-header #headerbar .top-navigator .menu li .sub-menu li a {
  padding: 5px 18px;
  display: block;
  margin: 0;
  border: none;
  text-transform: none;
  font-weight: normal;
  position: relative;
}
#site-header #headerbar .top-navigator .menu li .sub-menu li a:before {
  display: inline-block;
  width: 5px;
  height: 5px;
  content: "";
  vertical-align: middle;
  margin: -1px 10px 0 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#site-header #headerbar .top-navigator .menu li .sub-menu li .sub-menu {
  right: 100%;
  left: auto;
  top: -10px;
}
#site-header #headerbar .top-navigator .menu li .sub-menu li:hover > a,
#site-header #headerbar .top-navigator .menu li .sub-menu li.current-menu-item > a,
#site-header #headerbar .top-navigator .menu li .sub-menu li.current-page-item > a {
  color: #ffffff;
}
#site-header #headerbar .top-navigator .menu > li:last-child .sub-menu {
  left: auto;
  right: 0;
}
#site-header #headerbar .top-navigator .menu > li.current-menu-item > a,
#site-header #headerbar .top-navigator .menu > li.current-page-item > a {
  color: #ffffff;
  position: relative;
}
#site-header #headerbar .top-navigator .menu > li.current-menu-item > a:after,
#site-header #headerbar .top-navigator .menu > li.current-page-item > a:after {
  content: "";
  left: 0;
  right: 0;
  height: 4px;
  position: absolute;
  bottom: -10px;
}
#site-header #masthead {
  position: relative;
  z-index: 99;
  background: #ffffff;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  /* ============================================================ 
		    3.1.1. Site Brand
		=============================================================*/
  /* ============================================================ 
		    3.1.2. Site Navigator
		=============================================================*/
  /* ============================================================ 
		    3.1.3. Site Navigator Mobile
		=============================================================*/
}
#site-header #masthead.stick {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
#site-header #masthead #site-brand {
  float: left;
  margin-right: 40px;
}
#site-header #masthead #site-brand #site-logo .logo {
  margin: 0;
}
#site-header #masthead #site-brand #site-logo .logo a {
  display: block;
}
#site-header #masthead #site-brand #site-logo .logo a img {
  vertical-align: middle;
  display: table;
}
#site-header #masthead #site-brand #site-logo .logo a img.logo-retina {
  display: none;
}
#site-header #masthead #site-navigator {
  float: left;
  margin-top: 28px;
}
#site-header #masthead #site-navigator .menu {
  padding: 0;
  margin: 0;
}
#site-header #masthead #site-navigator .menu:after {
  clear: both;
  content: "";
  display: table;
}
#site-header #masthead #site-navigator .menu li {
  list-style: none;
  float: left;
  position: relative;
}
#site-header #masthead #site-navigator .menu li:hover > .sub-menu {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  visibility: visible;
  display: block\9;
  top: 100%;
}
#site-header #masthead #site-navigator .menu li a {
  display: block;
  padding: 6px 15px 7px 15px;
  position: relative;
  text-transform: uppercase;
  border: 1px solid transparent;
}
#site-header #masthead #site-navigator .menu li .sub-menu {
  position: absolute;
  width: 250px;
  padding: 10px 0;
  margin: 0;
  visibility: hidden;
  display: none\9;
  left: 0;
  top: 150%;
  background: #ffffff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
#site-header #masthead #site-navigator .menu li .sub-menu li {
  display: block;
  float: none;
}
#site-header #masthead #site-navigator .menu li .sub-menu li a {
  padding: 5px 18px;
  display: block;
  margin: 0;
  border: none;
  text-transform: none;
  font-weight: normal;
  position: relative;
  color: inherit;
}
#site-header #masthead #site-navigator .menu li .sub-menu li a:before {
  display: inline-block;
  width: 5px;
  height: 5px;
  content: "";
  vertical-align: middle;
  margin: -1px 10px 0 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#site-header #masthead #site-navigator .menu li .sub-menu li .sub-menu {
  left: 250px;
  top: -10px;
}
#site-header #masthead #site-navigator .menu li .sub-menu li:hover > a,
#site-header #masthead #site-navigator .menu li .sub-menu li.current-menu-item > a,
#site-header #masthead #site-navigator .menu li .sub-menu li .current_page_item > a,
#site-header #masthead #site-navigator .menu li .sub-menu li.current-menu-parent > a {
  color: #ffffff;
}
#site-header #masthead #site-navigator .menu li.megamenu > .sub-menu {
  display: table;
}
#site-header #masthead #site-navigator .menu li.megamenu > .sub-menu > li {
  display: table-cell;
  table-layout: fixed;
}
#site-header #masthead #site-navigator .menu li.megamenu > .sub-menu > li > a {
  color: #ffffff;
  background: none;
}
#site-header #masthead #site-navigator .menu li.megamenu .sub-menu .sub-menu {
  visibility: inherit;
  display: block\9;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  position: static;
  padding: 0;
}
#site-header #masthead #site-navigator .menu.menu-extra {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
}
#site-header #masthead #site-navigator .menu.menu-extra .search-box > a {
  display: none;
}
#site-header #masthead #site-navigator .menu.menu-extra .search-box .widget_search {
  width: 250px;
}
#site-header #masthead #site-navigator .menu.menu-extra .search-box .widget_search .search-field {
  height: 40px;
  font-size: 14px;
  color: rgba(21, 65, 110, 0.65);
}
#site-header #masthead #site-navigator .menu.menu-extra .search-box .widget_search .search-submit {
  top: 10px;
  right: 13px;
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart:hover .submenu {
  top: 100%;
  visibility: visible;
  display: block\9;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart > a {
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 10px;
  line-height: 38px;
  text-align: center;
  padding: 0;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart > a .shopping-cart-items-count {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -11px;
  right: -11px;
  line-height: 16px;
  text-align: center;
  z-index: 9;
  border: 2px solid #ffffff;
  font-weight: normal;
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart > a .shopping-cart-items-count.no-items:after {
  content: "0";
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart .submenu {
  position: absolute;
  width: 300px;
  right: 0;
  top: 150%;
  background: #ffffff;
  padding: 20px;
  z-index: -1;
  visibility: hidden;
  display: none\9;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart .submenu .widget_shopping_cart_content .cart_list {
  overflow: hidden;
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart .submenu .widget_shopping_cart_content .cart_list li:first-child {
  padding-top: 0;
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart .submenu .widget_shopping_cart_content .cart_list li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart .submenu .widget_shopping_cart_content .cart_list li a {
  padding: 0;
  font-family: inherit;
  text-transform: none;
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart .submenu .widget_shopping_cart_content .cart_list li a:hover {
  color: #333333;
}
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart .submenu .widget_shopping_cart_content .buttons a {
  float: left;
  color: #ffffff;
  font-family: inherit;
  font-weight: bold;
  padding: 0;
}
#site-header #masthead #site-navigator-mobile {
  display: none;
}
#site-header #masthead #site-navigator-mobile.navigator-mobile.active .menu {
  display: block;
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .navigator-toggle {
  position: absolute;
  top: 25px;
  right: 15px;
  width: 50px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  color: #ffffff;
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .menu {
  margin: 0;
  padding: 0;
  display: none;
  background: #ffffff;
  position: relative;
  z-index: 9;
  top: 0;
  border-top: 1px solid rgba(54, 70, 115, 0.08);
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .menu li {
  list-style: none;
  position: relative;
  border-bottom: 1px solid rgba(54, 70, 115, 0.08);
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .menu li a {
  display: block;
  line-height: 56px;
  margin-right: 56px;
  padding: 0 15px;
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .menu li .sub-menu {
  border-top: 1px solid rgba(54, 70, 115, 0.08);
  margin-left: 20px;
  display: none;
  padding: 0;
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .menu li:last-child {
  border-bottom: none;
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .menu li.menu-item-has-children .toggler {
  position: absolute;
  display: block;
  width: 56px;
  height: 56px;
  border-left: 1px solid rgba(54, 70, 115, 0.08);
  right: 0;
  top: 0;
  z-index: 99;
  cursor: pointer;
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .menu li.menu-item-has-children .toggler:after {
  content: "";
  width: 14px;
  height: 2px;
  background: #cccccc;
  position: absolute;
  left: 50%;
  margin-left: -7px;
  top: 50%;
  margin-top: -1px;
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .menu li.menu-item-has-children .toggler:before {
  content: "";
  width: 2px;
  height: 14px;
  background: #cccccc;
  position: absolute;
  left: 50%;
  margin-left: -1px;
  top: 50%;
  margin-top: -7px;
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .menu li.menu-item-has-children.active > .sub-menu {
  display: block;
}
#site-header #masthead #site-navigator-mobile.navigator-mobile .menu li.menu-item-has-children.active > .toggler:before {
  display: none;
}
#site-header #masthead-placeholder {
  display: none;
}
#site-header #masthead.stick + #masthead-placeholder {
  display: block;
}
/* ============================================================ 
    4. CONTENT
=============================================================*/
#site-content {
  /* ============================================================ 
	    4.1. Page Header
	=============================================================*/
  /* ============================================================ 
	    4.2. Page Breadcrumbs
	=============================================================*/
  /* ============================================================ 
	    4.3. Page Body
	=============================================================*/
}
#site-content #page-header {
  padding: 50px 0;
  text-align: center;
}
#site-content #page-header .page-title .title {
  margin: 0;
  font-size: 36px;
  text-transform: uppercase;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
#site-content #page-breadcrumbs {
  margin-bottom: 50px;
  border-bottom: 1px solid rgba(54, 70, 115, 0.08);
}
#site-content #page-breadcrumbs .wrapper {
  padding-bottom: 15px;
  padding-top: 15px;
}
#site-content #page-breadcrumbs .breadcrumb-trail {
  position: relative;
}
#site-content #page-breadcrumbs .breadcrumbs .trail-browse {
  margin-right: 10px;
  font-family: inherit;
  text-transform: none;
}
#site-content #page-breadcrumbs .breadcrumbs .trail-items li:after {
  background: #b9c1cf;
  padding: 0;
  display: inline-block;
  width: 5px;
  height: 5px;
  content: "";
  vertical-align: middle;
  margin: -1px 15px 0 15px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#site-content #page-breadcrumbs .breadcrumbs .trail-items li.trail-end {
  color: #b9c1cf;
}
#site-content #page-breadcrumbs .breadcrumbs .trail-items li.trail-end:after {
  display: none;
}
#site-content #page-body {
  margin-bottom: 50px;
}
#site-content #page-body .content,
#site-content #page-body .sidebars {
  position: relative;
}
/* ============================================================ 
    5. WIDGET
=============================================================*/
.widget {
  margin-bottom: 60px;
}
.widget select {
  width: 100%;
}
.widget:last-child {
  margin-bottom: 0;
}
.widget .widget-title {
  font-size: 24px;
  margin-bottom: 30px;
  margin-top: 0;
  padding: 40px 0 0 0;
  position: relative;
  overflow: hidden;
}
.widget .widget-title:before {
  width: 11px;
  height: 11px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
.widget .widget-title:after {
  width: 200%;
  height: 1px;
  background: rgba(54, 70, 115, 0.08);
  content: "";
  position: absolute;
  top: 5px;
  left: 25px;
}
.widget ul {
  margin: 0;
  padding: 0;
}
.widget ul li {
  list-style: none;
}
.widget ul li .children,
.widget ul li .sub-menu {
  margin: 10px 0 10px 20px;
}
.widget.widget_archive ul li,
.widget.widget_categories ul li {
  color: #b9c1cf;
}
.widget.widget_archive select,
.widget.widget_categories select {
  width: 100%;
}
.widget.widget_archive label,
.widget.widget_categories label {
  margin-bottom: 10px;
  display: inline-block;
}
.widget.widget_archive ul {
  display: inline-block;
  width: 100%;
}
.widget.widget_archive ul li {
  width: 50%;
  float: left;
  position: relative;
  margin-bottom: 5px;
}
.widget.widget_archive ul li:before {
  display: inline-block;
  width: 5px;
  height: 5px;
  content: "";
  vertical-align: middle;
  margin: -1px 10px 0 2px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.widget.widget_categories ul li,
.widget.widget_pages ul li,
.widget.widget_meta ul li {
  margin-bottom: 5px;
}
.widget.widget_categories ul li a,
.widget.widget_pages ul li a,
.widget.widget_meta ul li a {
  position: relative;
}
.widget.widget_categories ul li a:before,
.widget.widget_pages ul li a:before,
.widget.widget_meta ul li a:before {
  display: inline-block;
  width: 5px;
  height: 5px;
  content: "";
  vertical-align: middle;
  margin: -1px 10px 0 2px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.widget.widget_categories ul li .children,
.widget.widget_pages ul li .children,
.widget.widget_meta ul li .children {
  margin: 10px 0 10px 18px;
}
.widget.widget_categories ul li .children li a:before,
.widget.widget_pages ul li .children li a:before,
.widget.widget_meta ul li .children li a:before {
  content: "";
  width: 7px;
  height: 1px;
  margin-top: 0;
  margin-left: 0;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.widget.widget_calendar caption {
  margin-bottom: 15px;
  text-align: inherit;
}
.widget.widget_calendar table thead {
  text-align: center;
}
.widget.widget_calendar table thead th {
  padding: 5px 0;
}
.widget.widget_calendar table tbody tr td,
.widget.widget_calendar table tbody tr th {
  text-align: center;
  padding: 5px 0;
}
.widget.widget_calendar table tbody tr td#today,
.widget.widget_calendar table tbody tr th#today {
  color: #ffffff;
}
.widget.widget_calendar table tfoot tr td {
  padding: 5px 0;
  text-align: center;
}
.widget.widget_recent_entries a {
  font-weight: bold;
  display: block;
}
.widget.widget_recent_entries .post-date {
  display: block;
  position: relative;
  margin-top: 5px;
  font-size: 14px;
  color: #b9c1cf;
}
.widget.widget_recent_entries .post-date:before {
  content: "\f017";
  font-family: "FontAwesome";
  font-size: 14px;
  margin-right: 10px;
}
.widget.widget_recent_entries li {
  padding: 15px 0;
  border-bottom: 1px dotted rgba(54, 70, 115, 0.1);
}
.widget.widget_recent_entries li:last-child {
  padding-bottom: 0;
  border: none;
}
.widget.widget_recent_entries li:first-child {
  padding-top: 0;
}
.widget.widget_rss ul li {
  padding: 20px 0;
  border-bottom: 1px dotted rgba(54, 70, 115, 0.1);
}
.widget.widget_rss ul li:first-child {
  padding-top: 0;
}
.widget.widget_rss ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.widget.widget_rss ul li a {
  font-weight: bold;
}
.widget.widget_rss .widget-title img {
  vertical-align: inherit;
}
.widget.widget_search:after {
  display: none;
}
.widget.widget_search .search-form {
  position: relative;
}
.widget.widget_search .search-form label .screen-reader-text {
  display: none;
}
.widget.widget_search .search-form label .search-field {
  width: 100%;
}
.widget.widget_search .search-form .search-submit {
//   background-image: url(../fonts/search.svg);
  background-color: transparent;
  background-size: 13px 13px;
  background-position: center right;
  background-repeat: no-repeat;
  width: 24px;
  height: 21px;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px 0 5px 10px;
  text-indent: -9999px;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 12px;
  -webkit-opacity: 0.3;
  -khtml-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
  filter: alpha(opacity=30);
}
.widget.widget_search .search-form .search-submit:hover {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  border-color: rgba(0, 0, 0, 0.2);
}
.widget.widget_tag_cloud .tagcloud {
  overflow: hidden;
}
.widget.widget_tag_cloud .tagcloud a {
  color: #ffffff;
  padding: 5px 20px;
  margin: 0 5px 5px 0;
  float: left;
  position: relative;
  font-size: 14px !important;
}
.widget.widget_recent_comments ul li {
  color: #b9c1cf;
  position: relative;
  padding: 10px 0 10px 25px;
  border-bottom: 1px dotted rgba(54, 70, 115, 0.1);
}
.widget.widget_recent_comments ul li:last-child {
  padding-bottom: 0;
  border: none;
}
.widget.widget_recent_comments ul li:first-child {
  padding-top: 0;
}
.widget.widget_recent_comments ul li:first-child:after {
  top: 0;
}
.widget.widget_recent_comments ul li:after {
  content: "\f27a";
  position: absolute;
  left: 0;
  top: 10px;
  font-family: "FontAwesome";
  font-size: 14px;
}
.widget select[name="monster-widget-just-testing"] option {
  width: 300px;
}
.sidebar .widget.widget_nav_menu .menu {
  padding: 5px 0;
}
.sidebar .widget.widget_nav_menu .menu li a {
  display: block;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
}
.sidebar .widget.widget_nav_menu .menu li a:hover {
  color: #ffffff;
}
.sidebar .widget.widget_nav_menu .menu li .sub-menu {
  margin: 0 0 0 22px;
  padding: 0;
}
.sidebar .widget.widget_nav_menu .menu li .sub-menu .sub-menu {
  margin-left: 16px;
}
.sidebar .widget.widget_nav_menu .menu li .sub-menu li a {
  position: relative;
  margin-bottom: 7px;
}
.sidebar .widget.widget_nav_menu .menu li .sub-menu li a:before {
  display: inline-block;
  width: 5px;
  height: 5px;
  content: "";
  vertical-align: middle;
  margin: -1px 10px 0 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sidebar .widget.widget_nav_menu .menu > li {
  position: relative;
}
.sidebar .widget.widget_nav_menu .menu > li:after {
  position: absolute;
}
.sidebar .widget.widget_nav_menu .menu > li > a {
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px 20px;
  color: #ffffff;
  position: relative;
}
.sidebar .widget.widget_nav_menu .menu > li > a:before {
  content: "\f054";
  font-family: "FontAwesome";
  font-size: 14px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: 20px;
}
.sidebar .widget.widget_nav_menu .menu > li > a:hover {
  background: rgba(0, 0, 0, 0.15);
}
.sidebar .widget.widget_nav_menu .menu > li.current-menu-item > a,
.sidebar .widget.widget_nav_menu .menu > li.current_page_item > a {
  background: #ffffff;
  position: relative;
}
.sidebar .widget.widget_nav_menu .menu > li.current-menu-item > a:after,
.sidebar .widget.widget_nav_menu .menu > li.current_page_item > a:after {
  width: 5px;
  height: 100%;
  left: -5px;
  top: 0;
  content: "";
  position: absolute;
}
.sidebar .widget.widget_nav_menu .menu > li.current-menu-item > ul,
.sidebar .widget.widget_nav_menu .menu > li.current_page_item > ul {
  margin-top: 20px;
}
.sidebar-right .sidebar .widget.widget_nav_menu .menu > li.current-menu-item > a::after,
.sidebar-right .sidebar .widget.widget_nav_menu .menu > li.current_page_item > a::after {
  left: auto;
  right: -5px;
}
/* ============================================================ 
    6. FOOTER
=============================================================*/
#content-bottom-widgets {
  color: rgba(255, 255, 255, 0.75);
}
#content-bottom-widgets .wrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  padding-top: 30px;
  padding-bottom: 30px;
}
#content-bottom-widgets .widget .custom-info a {
  color: #ffffff;
}
#content-bottom-widgets .widget .custom-info a:hover {
  color: inherit;
}
#content-bottom-widgets .widget .custom-info i {
  margin-right: 10px;
  margin-left: 30px;
  width: 30px;
  height: 30px;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 28px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.1);
}
#content-bottom-widgets .widget .logo-ft {
  text-align: left;
}
#site-footer {
  font-size: 14px;
}
#site-footer #footer-widgets {
  padding: 50px 0;
  color: rgba(255, 255, 255, 0.75);
}
#site-footer #footer-widgets a:hover {
  color: #ffffff;
}
#site-footer #footer-widgets .widget .widget-title {
  color: #ffffff;
}
#site-footer #footer-widgets .widget.widget_nav_menu ul {
  display: inline-block;
  width: 100%;
}
#site-footer #footer-widgets .widget.widget_nav_menu ul li {
  width: 50%;
  float: left;
  position: relative;
  margin-bottom: 7px;
}
#site-footer #footer-widgets .widget.widget_nav_menu ul li:before {
  display: inline-block;
  width: 5px;
  height: 5px;
  content: "";
  vertical-align: middle;
  margin: -1px 10px 0 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#site-footer #footer-widgets .widget.widget_nav_menu ul li a:hover {
  color: #ffffff;
}
#site-footer #footer-widgets .widget.widget_mc4wp_form_widget label {
  display: block;
  margin-bottom: 10px;
}
#site-footer #footer-widgets .widget.widget_mc4wp_form_widget input[type="email"] {
  width: 100%;
  color: rgba(21, 65, 110, 0.65);
  border: none;
}
#site-footer #footer-widgets .widget a {
  color: rgba(255, 255, 255, 0.85);
}
#site-footer #footer-widgets .widget a:hover {
  color: #ffffff;
}
#site-footer #footer-content {
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
}
#site-footer #footer-content .wrapper {
  padding-top: 25px;
  padding-bottom: 25px;
}
#site-footer .social-links {
  margin: 10px 0 20px 0;
}
#site-footer .social-links a {
  margin: 0 5px;
}
#site-footer .copyright {
  color: rgba(255, 255, 255, 0.75);
}
#site-footer .copyright a {
  color: rgba(255, 255, 255, 0.85);
  font-weight: bold;
}
#site-footer .copyright a:hover {
  color: #ffffff;
}
#site-footer .counter .counter-content .counter-value {
  color: #ffffff;
}
/* ============================================================ 
    7. BLOG
=============================================================*/
/* ============================================================ 
	8. NAVIGATION
=============================================================*/
.navigation:after {
  clear: both;
  content: "";
  display: table;
}
.navigation.post-navigation .nav-links {
  margin: 0;
  padding: 20px 0 17px 0;
  border-top: 1px solid rgba(54, 70, 115, 0.08);
  border-bottom: 1px solid rgba(54, 70, 115, 0.08);
}
.navigation.post-navigation .nav-links:after {
  clear: both;
  content: "";
  display: table;
}
.navigation.post-navigation .nav-links li {
  list-style: none;
  float: left;
  width: 50%;
}
.navigation.post-navigation .nav-links li a {
  display: block;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding: 0 10px 0 60px;
}
.navigation.post-navigation .nav-links li a:after {
  content: "\f053";
  font-family: "FontAwesome";
  font-size: 14px;
  font-weight: normal;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  background: rgba(54, 70, 115, 0.08);
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -24px;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.navigation.post-navigation .nav-links li a span {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
}
.navigation.post-navigation .nav-links li.next-post {
  float: right;
}
.navigation.post-navigation .nav-links li.next-post a {
  text-align: right;
  padding: 0 60px 0 10px;
}
.navigation.post-navigation .nav-links li.next-post a:after {
  content: "\f054";
  right: 0;
  left: auto;
}
.navigation.pager a {
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  margin: 0 30px 0 0;
}
.navigation .page-numbers {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  margin-right: 5px;
  background: #e8f1f5;
}
.navigation .page-numbers.current {
  color: #ffffff;
}
.navigation .page-numbers.dots {
  background: none;
}
.navigation .page-numbers.prev,
.navigation .page-numbers.next {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.navigation.loadmore {
  text-align: center;
}
.navigation.loadmore a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  font-weight: bold;
  padding: 0 50px;
  position: relative;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  border-radius: 40px;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.navigation.loadmore a:after {
  position: absolute;
  content: "";
  left: 50%;
  width: 64px;
  height: 64px;
  margin-left: -32px;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
//   background: url(../images/preloader.gif);
}
.navigation.loadmore.loading a {
  text-indent: -9999px;
  background: none;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.navigation.loadmore.loading a:after {
  text-indent: 0;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.navigation.loadmore.loading a:hover {
  background: transparent;
}
/* ============================================================ 
	9. OTHER PAGES
=============================================================*/
/* ============================================================ 
    9.1. Author
=============================================================*/
.author .box.author-box {
  margin: 0 0 50px 0;
  border: none;
  padding: 0;
  position: relative;
}
.author .box.author-box .box-title {
  display: none;
}
/* ============================================================ 
    9.2. Search
=============================================================*/
.search #main-content .content-inner .search-form {
  position: relative;
}
.search #main-content .content-inner .search-form label .screen-reader-text {
  display: none;
}
.search #main-content .content-inner .search-form label .search-field {
  width: 100%;
}
.search #main-content .content-inner .search-form .search-submit {
//   background-image: url(../fonts/search.svg);
  background-color: transparent;
  background-size: 13px 13px;
  background-position: center right;
  background-repeat: no-repeat;
  width: 24px;
  height: 21px;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px 0 5px 10px;
  text-indent: -9999px;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 12px;
  -webkit-opacity: 0.3;
  -khtml-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
  filter: alpha(opacity=30);
}
.search #main-content .content-inner .search-form .search-submit:hover {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  border-color: rgba(0, 0, 0, 0.2);
}
.search #main-content .content-inner .search-results {
  margin: 30px 0 0 0;
}
.search #main-content .content-inner .search-results article {
  padding: 30px 0 30px 75px;
  position: relative;
  border-bottom: 1px dotted rgba(54, 70, 115, 0.1);
  text-align: inherit;
}
.search #main-content .content-inner .search-results article .counter {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  top: 30px;
  left: 0;
  font-size: 1.5em;
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
.search #main-content .content-inner .search-results article .entry-title {
  font-size: 20px;
  margin: 0 0 10px 0;
}
.search #main-content .content-inner .search-results article .entry-date {
  margin-bottom: 10px;
  color: #b9c1cf;
}
.search #main-content .content-inner .search-results article .entry-date:before {
  content: "\f017";
  font-family: "FontAwesome";
  display: inline-block;
  margin: 0 5px 0 0;
}
.search #main-content .navigation {
  margin-top: 30px;
}
/* ============================================================ 
    9.3. Error404
=============================================================*/
.error404 #main-content .content-inner {
  text-align: center;
  padding: 50px 0;
}
.error404 #main-content .content-inner:after {
  clear: both;
  content: "";
  display: table;
}
.error404 #main-content .content-inner .heading-404 {
  margin-bottom: 50px;
}
.error404 #main-content .content-inner .content-404 {
  margin: 0 auto;
  width: 50%;
}
.error404 #main-content .content-inner .content-404 h3 {
  margin-bottom: 10px;
  margin-top: 0;
  font-size: 24px;
}
.error404 #main-content .content-inner .content-404 .search-form {
  position: relative;
  width: 100%;
}
.error404 #main-content .content-inner .content-404 .search-form label .screen-reader-text {
  display: none;
}
.error404 #main-content .content-inner .content-404 .search-form label .search-field {
  width: 100%;
}
.error404 #main-content .content-inner .content-404 .search-form .search-submit {
//   background-image: url(../fonts/search.svg);
  background-color: transparent;
  background-size: 13px 13px;
  background-position: center right;
  background-repeat: no-repeat;
  width: 24px;
  height: 21px;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px 0 5px 10px;
  text-indent: -9999px;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 12px;
  -webkit-opacity: 0.3;
  -khtml-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
  filter: alpha(opacity=30);
}
.error404 #main-content .content-inner .content-404 .search-form .search-submit:hover {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  border-color: rgba(0, 0, 0, 0.2);
}
/* ============================================================ 
    9.4. Blank Page
=============================================================*/
.page-blank {
  display: table;
  height: 100%;
  left: 0;
  margin: 0;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
.page-blank #site-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 0;
}
.page-blank #site-wrapper #main-content {
  margin-bottom: 0;
}
/* ============================================================ 
	10. MEMBER
=============================================================*/
/* ============================================================ 
	12. OTHER
=============================================================*/
/* ============================================================ 
	12.1 Comments
=============================================================*/
#comments .comments-title,
#comments #reply-title {
  font-size: 20px;
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
  margin: 0 0 30px 0;
}
#comments .comments-title:after,
#comments #reply-title:after {
  width: 50px;
  height: 3px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}
#comments .comment-list {
  margin: 0;
  padding: 0;
}
#comments .comment-list li {
  list-style: none;
  margin-bottom: 50px;
}
#comments .comment-list li .comment-body .comment-meta .comment-author .fn {
  font-weight: normal;
}
#comments .comment-list li .comment-body .comment-meta .comment-author img {
  float: left;
  width: 34px;
  height: 34px;
  margin-right: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
#comments .comment-list li .comment-body .comment-meta .comment-metadata {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 10px;
}
#comments .comment-list li .comment-body .comment-meta .comment-metadata a {
  color: #cccccc;
}
#comments .comment-list li .comment-body .comment-meta .comment-metadata a:hover {
  color: #333333;
}
#comments .comment-list li .comment-body .comment-content,
#comments .comment-list li .comment-body .reply {
  padding-left: 54px;
}
#comments .comment-list li .comment-body .reply a {
  font-style: italic;
}
#comments .comment-list li .children {
  padding-left: 54px;
  margin-top: 50px;
}
#comments #respond .comment-form p label {
  display: block;
  margin-bottom: 5px;
}
#comments #respond .comment-form p input,
#comments #respond .comment-form p textarea {
  width: 100%;
}
#comments #respond .comment-form p input.submit,
#comments #respond .comment-form p textarea.submit {
  width: auto;
}
#comments #respond .comment-form .logged-in-as {
  margin-bottom: 10px;
}
#comments #respond .comment-form .comment-form-url {
  margin-bottom: 30px;
}
/* ============================================================ 
	12.2 Form
=============================================================*/
.quick-form {
  padding: 35px 40px 40px 40px;
}
.quick-form h1,
.quick-form h2,
.quick-form h3,
.quick-form h4,
.quick-form h5,
.quick-form h6 {
  margin-top: 0;
  position: relative;
  margin-bottom: 30px;
}
.quick-form h1:after,
.quick-form h2:after,
.quick-form h3:after,
.quick-form h4:after,
.quick-form h5:after,
.quick-form h6:after {
  width: 50px;
  height: 3px;
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
}
.quick-form.bg-scheme h1,
.quick-form.bg-scheme h2,
.quick-form.bg-scheme h3,
.quick-form.bg-scheme h4,
.quick-form.bg-scheme h5,
.quick-form.bg-scheme h6 {
  color: #ffffff;
}
.quick-form.bg-scheme .wpcf7-text {
  border: none;
  margin-bottom: 20px;
  color: rgba(21, 65, 110, 0.65);
}
.quick-form .row input[type="text"],
.quick-form .row input[type="password"],
.quick-form .row input[type="datetime"],
.quick-form .row input[type="datetime-local"],
.quick-form .row input[type="date"],
.quick-form .row input[type="month"],
.quick-form .row input[type="time"],
.quick-form .row input[type="week"],
.quick-form .row input[type="number"],
.quick-form .row input[type="email"],
.quick-form .row input[type="url"],
.quick-form .row input[type="search"],
.quick-form .row input[type="tel"],
.quick-form .row input[type="color"],
.quick-form .row textarea,
.quick-form .row select {
  width: 100%;
  margin-bottom: 30px;
}
.quick-form textarea {
  height: 195px;
  max-height: 195px;
  margin-bottom: 0;
  vertical-align: middle;
}
.contact-form .row input[type="text"],
.contact-form .row input[type="password"],
.contact-form .row input[type="datetime"],
.contact-form .row input[type="datetime-local"],
.contact-form .row input[type="date"],
.contact-form .row input[type="month"],
.contact-form .row input[type="time"],
.contact-form .row input[type="week"],
.contact-form .row input[type="number"],
.contact-form .row input[type="email"],
.contact-form .row input[type="url"],
.contact-form .row input[type="search"],
.contact-form .row input[type="tel"],
.contact-form .row input[type="color"],
.contact-form .row textarea,
.contact-form .row select {
  width: 100%;
  margin-bottom: 30px;
}
.contact-form textarea {
  height: 195px;
  max-height: 195px;
  margin-bottom: 0;
  vertical-align: middle;
}
/* ============================================================ 
	12.3 Gallery
=============================================================*/
.gallery {
  margin: 0 -2px 20px -2px;
  position: relative;
}
.gallery:after {
  clear: both;
  content: "";
  display: block;
}
.gallery .gallery-item {
  float: left;
  padding: 0 2px;
  overflow: hidden;
  position: relative;
  margin: 0 0 4px 0;
}
.gallery .gallery-item:hover .gallery-caption {
  margin-bottom: 0;
}
.gallery.gallery-columns-1 .gallery-item {
  max-width: 100%;
}
.gallery.gallery-columns-1.gallery-size-medium .gallery-item:nth-of-type(1n+1),
.gallery.gallery-columns-1.gallery-size-thumbnail .gallery-item:nth-of-type(1n+1) {
  clear: left;
}
.gallery.gallery-columns-2 .gallery-item {
  max-width: 50%;
}
.gallery.gallery-columns-2.gallery-size-thumbnail .gallery-item:nth-of-type(2n+1) {
  clear: left;
}
.gallery.gallery-columns-3 .gallery-item {
  max-width: 33.3333333%;
}
.gallery.gallery-columns-3.gallery-size-thumbnail .gallery-item:nth-of-type(3n+1) {
  clear: left;
}
.gallery.gallery-columns-4 .gallery-item {
  max-width: 25%;
}
.gallery.gallery-columns-5 .gallery-item {
  max-width: 20%;
}
.gallery.gallery-columns-6 .gallery-item {
  max-width: 16.6666667%;
}
.gallery.gallery-columns-7 .gallery-item {
  max-width: 14.2857143%;
}
.gallery.gallery-columns-7 .gallery-caption {
  display: none;
}
.gallery.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}
.gallery.gallery-columns-8 .gallery-caption {
  display: none;
}
.gallery.gallery-columns-9 .gallery-item {
  max-width: 11.1111111%;
}
.gallery.gallery-columns-9 .gallery-caption {
  display: none;
}
.gallery-caption {
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  max-height: 50%;
  margin: 0;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 2px;
  right: 2px;
  margin-bottom: -100%;
}
.caption_slider {
  background: rgba(255, 255, 255, 0.9);
  padding: 50px;
  text-align: center;
}
.caption_slider h1,
.caption_slider h2,
.caption_slider h3,
.caption_slider h4,
.caption_slider h5,
.caption_slider h6 {
  margin-top: 0;
}
/* ============================================================ 
	12.4 Go To Top
=============================================================*/
.goto-top.active a {
  visibility: visible;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.goto-top a {
  text-indent: -9999px;
  width: 40px;
  height: 40px;
  outline: none;
  position: fixed;
  z-index: 99;
  right: 25px;
  bottom: 20px;
  visibility: hidden;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
.goto-top a:after {
  content: "\f077";
  position: absolute;
  color: #ffffff;
  text-indent: 0;
  font-family: 'FontAwesome';
  left: 13px;
  top: 6px;
  font-size: 14px;
}
#site-content .tp-bullets {
  z-index: 98;
}
.vc_column-inner {
  padding-top: 0 !important;
}
.vc_row.arrow {
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) inset;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) inset;
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) inset;
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) inset;
}
.vc_row.arrow:before {
  background-color: #ffffff;
  top: -15px;
  content: "";
  height: 30px;
  left: 50%;
  margin-left: -15px;
  position: absolute;
  width: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
}
.vc_row[data-vc-full-width].fix-overflow {
  overflow: visible;
}
.history {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.history:after {
  clear: both;
  content: "";
  display: table;
}
.history:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 4px;
  margin-left: -2px;
  background: #f1f2f8;
}
.history li {
  float: left;
  width: calc(50% + 1px);
  padding-right: 33px;
  text-align: right;
  position: relative;
}
.history li:nth-child(2n) {
  float: right;
  padding-left: 33px;
  padding-right: 0;
  text-align: left;
}
.history li:nth-child(2n):after {
  right: auto;
  left: -11px;
}
.history li:nth-child(2n):before {
  right: auto;
  left: -2px;
}
.history li:after {
  width: 25px;
  height: 25px;
  border: 5px solid #ffffff;
  content: "";
  position: absolute;
  top: -5px;
  right: -12px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
.history li:before {
  width: 7px;
  height: 7px;
  background: #ffffff;
  content: "";
  position: absolute;
  top: 4px;
  right: -3px;
  z-index: 1;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
.history li h3 {
  margin: 0 0 15px 0;
  font-size: 20px;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 10px 20px;
}
.history li .year-content {
  padding: 0 30px 30px 30px;
  background: #f1f2f8;
}
.social-links a i {
  width: 28px;
  height: 28px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 0;
  border: 1px solid transparent;
}
.social-links a i.fa-behance {
  background: #007af7;
}
.social-links a i.fa-bitcoin {
  background: #fea33f;
}
.social-links a i.fa-bitbucket {
  background: #246590;
}
.social-links a i.fa-codepen {
  background: #000;
}
.social-links a i.fa-delicious {
  background: #3399ff;
}
.social-links a i.fa-deviantart {
  background: #429976;
}
.social-links a i.fa-digg {
  background: #196c9e;
}
.social-links a i.fa-dribbble {
  background: #f86499;
}
.social-links a i.fa-facebook-official {
  background: #496fa4;
}
.social-links a i.fa-flickr {
  background: #fa3892;
}
.social-links a i.fa-foursquare {
  background: #35cbe7;
}
.social-links a i.fa-github-alt {
  background: #008bbf;
}
.social-links a i.fa-google-plus {
  background: #e6694a;
}
.social-links a i.fa-instagram {
  background: #5f92b2;
}
.social-links a i.fa-jsfiddle {
  background: #000;
}
.social-links a i.fa-linkedin {
  background: #008ec1;
}
.social-links a i.fa-pinterest {
  background: #da5b4c;
}
.social-links a i.fa-reddit {
  background: #ff5234;
}
.social-links a i.fa-skype {
  background: #3cd2f2;
}
.social-links a i.fa-slack {
  background: #439fe0;
}
.social-links a i.fa-soundcloud {
  background: #ff722c;
}
.social-links a i.fa-spotify {
  background: #89bd4d;
}
.social-links a i.fa-stack-exchange {
  background: #fd8a07;
}
.social-links a i.fa-steam {
  background: #6d6862;
}
.social-links a i.fa-stumbleupon {
  background: #00b87a;
}
.social-links a i.fa-tumblr {
  background: #34586f;
}
.social-links a i.fa-twitter {
  background: #1cb7eb;
}
.social-links a i.fa-vimeo-square {
  background: #00bdee;
}
.social-links a i.fa-youtube-play {
  background: #f36251;
}
.social-links a i.fa-rss {
  background: #f09040;
}
.social-links a i:hover {
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}
.wp-block-gallery[class*="columns-"],
.wp-block-text-columns[class*="columns-"] {
  width: 100%;
}
.wp-block-gallery[class*="columns-"] figure,
.wp-block-text-columns[class*="columns-"] figure,
.wp-block-gallery[class*="columns-"] figcaption,
.wp-block-text-columns[class*="columns-"] figcaption {
  margin: 0;
}
.wp-block-columns figure,
.wp-block-columns figcaption {
  margin: 0;
}
.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
  margin: 30px 50px;
  padding: 0 0 0 20px;
}
.wp-block-separator {
  border: none;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  /* Retina-specific stuff here */
  select {
    // background-image: url(../images/caret%402x.png);
    background-size: 9px 5px;
  }
  #site-header #masthead #site-brand #site-logo .logo a img.logo-standard {
    display: none;
  }
  #site-header #masthead #site-brand #site-logo .logo a img.logo-retina {
    display: table;
  }
}
.projects-shortcode.projects {
  margin-bottom: 0;
}
.projects {
  /**
	 * Animation
	 */
}
.projects[data-columns="2"] .projects-items .hentry {
  width: 50%;
}
.projects[data-columns="3"] .projects-items .hentry {
  width: 33.3333333%;
}
.projects[data-columns="4"] .projects-items .hentry {
  width: 25%;
}
.projects[data-columns="5"] .projects-items .hentry {
  width: 20%;
}
.projects.projects-grid .projects-items,
.projects.projects-grid-alt .projects-items {
  margin: 0 -15px;
}
.projects.projects-grid .projects-items .hentry,
.projects.projects-grid-alt .projects-items .hentry {
  padding: 0 15px;
  margin-bottom: 30px;
}
.projects.projects-grid .projects-items .hentry .project-wrap,
.projects.projects-grid-alt .projects-items .hentry .project-wrap {
  position: relative;
}
.projects.projects-grid .projects-items .hentry .project-wrap:hover,
.projects.projects-grid-alt .projects-items .hentry .project-wrap:hover {
  z-index: 9;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
}
.projects.projects-grid .projects-items .hentry .project-wrap:hover .project-info,
.projects.projects-grid-alt .projects-items .hentry .project-wrap:hover .project-info {
  background: #ffffff;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail {
  margin: 0;
  position: relative;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail:hover .project-buttons a,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail:hover .project-buttons a {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  visibility: visible;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail:hover img,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail:hover img {
  -webkit-opacity: 0.2;
  -khtml-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=20);
  filter: alpha(opacity=20);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail a,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail a {
  display: block;
  overflow: hidden;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a {
  position: absolute;
  right: 50%;
  bottom: 50%;
  margin-right: -25px;
  margin-bottom: -25px;
  z-index: 9;
  visibility: hidden;
  text-indent: -9999px;
  width: 50px;
  height: 50px;
  background: none;
  color: #ffffff;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a:after,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a:after {
  font-family: "fontAwesome";
  content: "\f002";
  text-indent: 0;
  position: absolute;
  top: 12px;
  right: 19px;
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a:hover,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a:hover {
  background: #ffffff;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail figcaption,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail figcaption {
  margin: 0;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-info,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-info {
  padding: 15px 25px 20px 25px;
  background: #f2f4f8;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-info .project-title,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-info .project-title {
  font-size: 14px;
  margin: 0;
  line-height: 1.4;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-info .project-categories,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-info .project-categories {
  margin: 0 0 5px 0;
  padding: 0;
  list-style: none;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-info .project-categories li,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-info .project-categories li {
  display: inline-block;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-info .project-categories li:after,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-info .project-categories li:after {
  content: ",";
  display: inline-block;
  color: #b9c1cf;
  margin: 0 2px;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-info .project-categories li a,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-info .project-categories li a {
  color: #b9c1cf;
  font-size: 14px;
}
.projects.projects-grid .projects-items .hentry .project-wrap .project-info .project-categories li:last-child:after,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-info .project-categories li:last-child:after {
  display: none;
}
.projects.projects-masonry .projects-items {
  margin: 0 -15px;
}
.projects.projects-masonry .projects-items .hentry {
  padding: 0 15px;
  margin-bottom: 30px;
}
.projects.projects-masonry .projects-items .hentry .project-wrap {
  position: relative;
  overflow: hidden;
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-thumbnail {
  margin: 0;
}
.projects.projects-masonry .projects-items .hentry .project-wrap figcaption {
  display: none;
}
.projects.projects-masonry .projects-items .hentry .project-wrap:hover .project-info {
  display: block\9;
  visibility: visible;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info {
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: none\9;
  visibility: visible;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-info-wrap {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-info-wrap:after {
  width: 50px;
  height: 3px;
  content: "";
  position: absolute;
  left: 0;
  top: -15px;
  background: #ffffff;
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-title {
  margin: 0;
  font-size: 18px;
  line-height: 1.4;
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-title a {
  color: #ffffff;
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-categories {
  margin: 0 0 5px 0;
  padding: 0;
  list-style: none;
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-categories li {
  display: inline-block;
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-categories li:after {
  content: ",";
  display: inline-block;
  color: #b9c1cf;
  margin: 0 2px;
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-categories li a {
  color: #b9c1cf;
  font-size: 14px;
  display: inline-block;
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-categories li a:hover {
  color: #ffffff;
}
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-categories li:last-child:after {
  display: none;
}
.projects.projects-grid-alt .projects-items .hentry {
  margin-bottom: 50px;
}
.projects.projects-grid-alt .projects-items .hentry .project-wrap {
  border-bottom: 2px solid #f1f2f8;
  padding-bottom: 20px;
}
.projects.projects-grid-alt .projects-items .hentry .project-wrap:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.projects.projects-grid-alt .projects-items .hentry .project-wrap:hover .project-info {
  background: none;
}
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail {
  margin-bottom: 20px;
}
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-info {
  padding: 0;
  background: none;
}
.projects.projects-justified {
  margin-bottom: 30px;
}
.projects.projects-justified .projects-items {
  margin: 0 -5px;
}
.projects.projects-justified .projects-items .hentry {
  border: none;
  margin: 5px;
}
.projects.projects-justified .projects-items .hentry > a {
  position: relative;
  display: block;
  height: 100%;
  z-index: 9;
}
.projects.projects-justified .projects-items .hentry > a:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
.projects.projects-justified .projects-items .hentry .project-info {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.projects.projects-justified .projects-items .hentry .project-info:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.projects.projects-justified .projects-items .hentry .project-info .project-info-wrap {
  display: inline-block;
  height: auto;
  vertical-align: middle;
  width: 80%;
  position: relative;
  z-index: 10;
}
.projects.projects-justified .projects-items .hentry .project-info .project-title {
  font-size: 18px;
  margin: 0 0 5px 0;
}
.projects.projects-justified .projects-items .hentry .project-info .project-title a {
  color: #ffffff;
  display: block;
  -webkit-transform: translateX(-150px);
  -moz-transform: translateX(-150px);
  -ms-transform: translateX(-150px);
  -o-transform: translateX(-150px);
  transform: translateX(-150px);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  visibility: hidden;
}
.projects.projects-justified .projects-items .hentry .project-info .project-categories {
  margin: 0;
  padding: 0;
}
.projects.projects-justified .projects-items .hentry .project-info .project-categories li {
  list-style: none;
  display: inline-block;
}
.projects.projects-justified .projects-items .hentry .project-info .project-categories li a {
  color: #b9c1cf;
  display: block;
  margin: 0 5px;
  -webkit-transform: translateX(-150px);
  -moz-transform: translateX(-150px);
  -ms-transform: translateX(-150px);
  -o-transform: translateX(-150px);
  transform: translateX(-150px);
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -ms-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  visibility: hidden;
}
.projects.projects-justified .projects-items .hentry .project-info .project-categories li a:hover {
  color: #ffffff;
}
.projects.projects-justified .projects-items .hentry:hover > a:after {
  visibility: visible;
  -webkit-opacity: 0.9;
  -khtml-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=90);
  filter: alpha(opacity=90);
}
.projects.projects-justified .projects-items .hentry:hover .project-info .project-title a {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  visibility: visible;
}
.projects.projects-justified .projects-items .hentry:hover .project-info .project-categories li a {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  visibility: visible;
}
.projects.projects-carousel .projects-items .elements-carousel {
  margin: 0 !important;
}
.projects.projects-carousel .projects-items .hentry {
  float: none;
  padding: 0 !important;
}
.projects .projects-filter ul {
  padding: 0;
  margin: 0 0 30px 0;
  overflow: hidden;
}
.projects .projects-filter ul li {
  list-style: none;
  float: left;
  margin: 0;
}
.projects .projects-filter ul li a {
  display: block;
  font-weight: bold;
  padding: 15px 20px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 14px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid transparent;
}
.projects .projects-filter ul li a:after {
  left: 0;
  right: 0;
  height: 4px;
  content: "";
  bottom: -1px;
  position: absolute;
}
.projects .projects-filter ul li:first-child a {
  border-left: none;
}
.projects .projects-filter ul li.active a {
  background: rgba(0, 0, 0, 0.1);
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-left-color: rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.2);
}
.projects .projects-items:after {
  clear: both;
  content: "";
  display: table;
}
.projects .projects-items .hentry {
  float: left;
}
.projects:not(.projects-carousel) .projects-items .project .project-wrap {
  visibility: hidden;
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  transition-duration: inherit;
}
.projects:not(.projects-carousel) .projects-items .project.animate .project-wrap {
  visibility: visible;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.single-nproject.no-sidebar #site-content #page-body {
  margin-bottom: 0;
}
.single-nproject.no-sidebar .navigation.post-navigation .nav-links {
  border: none;
  padding-left: 1000px;
  padding-right: 1000px;
  margin-right: -1000px;
  margin-left: -1000px;
  background: #f1f2f8;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
.project-single {
  margin: 0 0 50px 0;
}
.project-single.project-content-right .project-single-wrap:after {
  clear: both;
  content: "";
  display: table;
}
.project-single.project-content-right .project-single-wrap .project-gallery {
  float: left;
  width: 30%;
  padding-right: 25px;
  margin-bottom: 50px;
}
.project-single.project-content-right .project-single-wrap .project-content {
  float: right;
  width: 70%;
  padding-left: 25px;
}
.project-single.project-content-left .project-single-wrap:after {
  clear: both;
  content: "";
  display: table;
}
.project-single.project-content-left .project-single-wrap .project-gallery {
  float: right;
  width: 30%;
  padding-left: 25px;
  margin-bottom: 50px;
}
.project-single.project-content-left .project-single-wrap .project-content {
  float: left;
  width: 70%;
  padding-right: 25px;
}
.project-single.project-content-fullwidth .project-content-wrap {
  padding: 0;
  border: none;
}
.project-single.project-content-fullwidth .project-gallery {
  margin-bottom: 50px;
}
.project-single.project-content-fullwidth .project-title {
  margin-top: 30px;
}
.project-single .project-gallery-list .project-media-item {
  margin-bottom: 30px;
}
.project-single .project-gallery-list .project-media-item:last-child {
  margin-bottom: 0;
}
.project-single .project-gallery-grid .project-gallery-wrap {
  margin: 0 -5px;
}
.project-single .project-gallery-grid .project-gallery-wrap:after {
  clear: both;
  content: "";
  display: table;
}
.project-single .project-gallery-grid[data-columns="2"] .project-media-item {
  width: 50%;
}
.project-single .project-gallery-grid[data-columns="3"] .project-media-item {
  width: 33.3333333%;
}
.project-single .project-gallery-grid[data-columns="4"] .project-media-item {
  width: 25%;
}
.project-single .project-gallery-grid[data-columns="5"] .project-media-item {
  width: 20%;
}
.project-single .project-gallery-grid .project-media-item {
  float: left;
  padding: 0 5px;
  margin-bottom: 10px;
}
.project-single .project-media-item a {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: zoom-in;
}
.project-single .project-title {
  font-size: 18px;
  margin-top: 0;
}
.project-single .project-content ul {
  list-style: none;
  margin: 20px 0;
  padding: 0;
}
.project-single .project-content ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}
.project-single .project-content ul li:last-child {
  margin-bottom: 0;
}
.project-single .project-content ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 2px solid;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
.project-single .project-content ul li:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 10px;
  width: 5px;
  height: 5px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
.project-single .project-content .iconlist {
  margin-bottom: 20px !important;
}
.project-single .project-content .iconlist li {
  border: 1px solid rgba(54, 70, 115, 0.08);
  padding: 10px 15px;
  margin-bottom: 10px !important;
}
.project-single .project-content .iconlist li i {
  border-right: 1px solid rgba(54, 70, 115, 0.08);
  margin-right: 10px;
  width: 24px;
}
.projects-related {
  padding-top: 60px;
  margin-bottom: 20px;
}
.projects-related .projects-related-title {
  font-size: 20px;
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
  margin: 0 0 30px 0;
}
.projects-related .projects-related-title:after {
  width: 50px;
  height: 3px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}
.blog .hentry,
.single-post .hentry {
  margin-bottom: 50px;
  position: relative;
  border-bottom: 1px solid rgba(54, 70, 115, 0.08);
  padding-bottom: 50px;
}
.blog .hentry.sticky,
.single-post .hentry.sticky {
  padding: 30px;
  border-bottom: none;
}
.blog .hentry.sticky .entry-header .entry-title a,
.single-post .hentry.sticky .entry-header .entry-title a {
  color: #ffffff;
}
.blog .hentry.sticky .entry-header .entry-meta i,
.single-post .hentry.sticky .entry-header .entry-meta i {
  color: #ffffff;
}
.blog .hentry.sticky .entry-header .entry-meta a,
.single-post .hentry.sticky .entry-header .entry-meta a {
  color: #ffffff;
}
.blog .hentry.sticky .entry-content,
.single-post .hentry.sticky .entry-content {
  color: #ffffff;
}
.blog .hentry.sticky .entry-content .readmore .more-link,
.single-post .hentry.sticky .entry-content .readmore .more-link {
  color: #ffffff;
}
.blog .hentry .entry-cover,
.single-post .hentry .entry-cover {
  margin-bottom: 30px;
}
.blog .hentry .entry-cover a,
.single-post .hentry .entry-cover a {
  display: block;
  position: relative;
  background: #000000;
}
.blog .hentry .entry-cover a:after,
.single-post .hentry .entry-cover a:after,
.blog .hentry .entry-cover a:before,
.single-post .hentry .entry-cover a:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 30px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  z-index: 9;
  margin-top: -15px;
  margin-left: -1px;
  visibility: hidden;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
.blog .hentry .entry-cover a:before,
.single-post .hentry .entry-cover a:before {
  height: 1px;
  width: 30px;
  margin-top: -1px;
  margin-left: -15px;
}
.blog .hentry .entry-cover a:hover img,
.single-post .hentry .entry-cover a:hover img {
  -webkit-opacity: 0.4;
  -khtml-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=40);
  filter: alpha(opacity=40);
}
.blog .hentry .entry-cover a:hover:after,
.single-post .hentry .entry-cover a:hover:after,
.blog .hentry .entry-cover a:hover:before,
.single-post .hentry .entry-cover a:hover:before {
  visibility: visible;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.blog .hentry .entry-cover.flexslider a:hover,
.single-post .hentry .entry-cover.flexslider a:hover {
  cursor: zoom-in;
}
.blog .hentry .entry-header,
.single-post .hentry .entry-header {
  margin-bottom: 30px;
}
.blog .hentry .entry-header:after,
.single-post .hentry .entry-header:after {
  clear: both;
  content: "";
  display: table;
}
.blog .hentry .entry-header .entry-time,
.single-post .hentry .entry-header .entry-time {
  text-transform: uppercase;
  margin: 0;
  float: left;
  width: 85px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  line-height: 1;
}
.blog .hentry .entry-header .entry-time span,
.single-post .hentry .entry-header .entry-time span {
  font-size: 14px;
  line-height: 0.7;
}
.blog .hentry .entry-header .entry-time span.entry-day,
.single-post .hentry .entry-header .entry-time span.entry-day {
  display: block;
  font-size: 64px;
}
.blog .hentry .entry-header .entry-header-content,
.single-post .hentry .entry-header .entry-header-content {
  overflow: hidden;
}
.blog .hentry .entry-header .entry-title,
.single-post .hentry .entry-header .entry-title {
  font-size: 24px;
  margin: 3px 0 10px 0;
  text-transform: uppercase;
}
.blog .hentry .entry-header .entry-meta a,
.single-post .hentry .entry-header .entry-meta a {
  color: #b9c1cf;
}
.blog .hentry .entry-header .entry-meta i,
.single-post .hentry .entry-header .entry-meta i {
  margin-right: 5px;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid rgba(54, 70, 115, 0.08);
  color: #b9c1cf;
}
.blog .hentry .entry-header .entry-meta i:first-child,
.single-post .hentry .entry-header .entry-meta i:first-child {
  margin-left: 0;
  border-left: none;
  padding-left: 0;
}
.blog .hentry .entry-content .readmore,
.single-post .hentry .entry-content .readmore {
  margin-top: 25px;
}
.blog .hentry .entry-content .readmore .more-link,
.single-post .hentry .entry-content .readmore .more-link {
  padding: 7px 25px;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.blog .hentry .entry-content .readmore .more-link:after,
.single-post .hentry .entry-content .readmore .more-link:after {
  font-family: "fontAwesome";
  content: "\f054";
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px;
}
.single-post .hentry {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 50px;
}
.single-post .hentry .entry-cover {
  margin-bottom: 40px;
}
.single-post .hentry .entry-footer {
  margin-top: 40px;
}
.single-post .hentry .entry-footer .entry-tags {
  overflow: hidden;
}
.single-post .hentry .entry-footer .entry-tags strong {
  display: none;
}
.single-post .hentry .entry-footer .entry-tags a {
  color: #ffffff;
  padding: 5px 20px;
  margin: 0 5px 5px 0;
  float: left;
  position: relative;
  font-size: 14px !important;
}
.single-post .navigation {
  margin-bottom: 50px;
}
.box {
  margin-bottom: 50px;
}
.box:after {
  clear: both;
  content: "";
  display: table;
}
.box .box-title {
  font-size: 20px;
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
  margin: 0 0 30px 0;
}
.box .box-title:after {
  width: 50px;
  height: 3px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}
.box.author-box .author-avatar {
  float: left;
  margin-right: 30px;
  width: 50px;
}
.box.author-box .author-description {
  overflow: hidden;
}
#site-wrapper .box.related-posts-box {
  margin-bottom: 20px;
}
#site-wrapper .box.related-posts-box .blog-grid.blog-shortcode .hentry .entry-wrapper {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
#site-wrapper .box.related-posts-box .blog-grid.blog-shortcode .hentry .entry-header {
  position: static;
  padding: 0;
  margin: 0;
}
#site-wrapper .box.related-posts-box .blog-grid.blog-shortcode .hentry .entry-header .entry-title {
  position: static;
  padding-bottom: 0;
  border: none;
  font-size: 18px;
}
#site-wrapper .box.related-posts-box .blog-grid.blog-shortcode .hentry .entry-header .entry-title:after {
  display: none;
}
#site-wrapper .box.related-posts-box .blog-grid.blog-shortcode .hentry .entry-content {
  padding: 0;
  margin: 15px 0 0 0;
}
.woocommerce-edit-account .woocommerce,
.woocommerce-edit-address .woocommerce {
  margin-bottom: 50px;
}
.woocommerce-edit-account .woocommerce h3,
.woocommerce-edit-address .woocommerce h3 {
  font-size: 24px;
  margin: 50px 0 42px 0;
  position: relative;
}
.woocommerce-edit-account .woocommerce h3:before,
.woocommerce-edit-address .woocommerce h3:before {
  background: rgba(54, 70, 115, 0.08);
  content: "";
  height: 1px;
  left: 50px;
  right: 0;
  position: absolute;
  bottom: -12px;
}
.woocommerce-edit-account .woocommerce h3:after,
.woocommerce-edit-address .woocommerce h3:after {
  content: "";
  height: 3px;
  left: 0;
  width: 40px;
  position: absolute;
  bottom: -13px;
}
.content-inner > .woocommerce .myaccount_user + h2 {
  font-size: 24px;
  margin: 50px 0 30px 0;
  position: relative;
}
.content-inner > .woocommerce .address {
  margin-bottom: 50px;
}
.content-inner > .woocommerce .address h3 {
  font-size: 24px;
  margin: 50px 0 30px 0;
  position: relative;
}
.woocommerce-result-count {
  padding: 8px 0;
  margin-bottom: 32px;
}
.woocommerce-ordering {
  position: absolute;
  right: 0;
  top: 0;
}
.woocommerce,
.woocommerce-page {
  /* ============================================================ 
	    1. PRODUCTS
	=============================================================*/
}
.woocommerce.no-sidebar .product .images,
.woocommerce-page.no-sidebar .product .images {
  width: 30%;
}
.woocommerce.no-sidebar .product .summary,
.woocommerce-page.no-sidebar .product .summary {
  width: 70%;
}
.woocommerce.no-sidebar #reviews:after,
.woocommerce-page.no-sidebar #reviews:after {
  clear: both;
  content: "";
  display: table;
}
.woocommerce.no-sidebar #reviews #comments,
.woocommerce-page.no-sidebar #reviews #comments {
  float: left;
  width: 50%;
  padding-right: 30px;
}
.woocommerce.no-sidebar #reviews #review_form_wrapper,
.woocommerce-page.no-sidebar #reviews #review_form_wrapper {
  float: right;
  width: 50%;
  padding-left: 30px;
}
.woocommerce form.login,
.woocommerce-page form.login {
  margin-bottom: 80px;
}
.woocommerce[class*="columns-"],
.woocommerce-page[class*="columns-"] {
  width: 100%;
}
.woocommerce.columns-1 .products li,
.woocommerce-page.columns-1 .products li {
  width: 100%;
}
.woocommerce.columns-2 .products li,
.woocommerce-page.columns-2 .products li {
  width: 50%;
}
.woocommerce.columns-3 .products li,
.woocommerce-page.columns-3 .products li {
  width: 33.3333333%;
}
.woocommerce.columns-4 .products li,
.woocommerce-page.columns-4 .products li {
  width: 25%;
}
.woocommerce.columns-5 .products li,
.woocommerce-page.columns-5 .products li {
  width: 20%;
}
.woocommerce .onsale,
.woocommerce-page .onsale {
  position: absolute;
  padding: 0 20px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  left: 0;
  top: 15px;
  z-index: 2;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
}
.woocommerce .onsale:after,
.woocommerce-page .onsale:after {
  background: transparent;
  border: 15px solid;
  border-right-color: transparent !important;
  bottom: 0;
  content: "";
  position: absolute;
  right: -15px;
  z-index: 9;
}
.woocommerce .star-rating,
.woocommerce-page .star-rating {
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1.1em;
  font-size: 14px;
  width: 66px;
  font-family: 'FontAwesome';
}
.woocommerce .star-rating:before,
.woocommerce-page .star-rating:before {
  content: "\f006\f006\f006\f006\f006";
  float: left;
  left: 1px;
  position: absolute;
  top: 0;
}
.woocommerce .star-rating span,
.woocommerce-page .star-rating span {
  float: left;
  left: 0;
  overflow: hidden;
  padding-top: 1.5em;
  position: absolute;
  top: 0;
}
.woocommerce .star-rating span:before,
.woocommerce-page .star-rating span:before {
  left: 1px;
  position: absolute;
  top: 0;
  content: "\f005\f005\f005\f005\f005";
}
.woocommerce .woocommerce-pagination ul,
.woocommerce-page .woocommerce-pagination ul {
  margin: 50px 0 0 0;
  padding: 0;
}
.woocommerce .woocommerce-pagination ul li,
.woocommerce-page .woocommerce-pagination ul li {
  list-style: none;
  display: inline-block;
}
.woocommerce .woocommerce-pagination ul li .page-numbers,
.woocommerce-page .woocommerce-pagination ul li .page-numbers {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  margin-right: 5px;
  background: #e8f1f5;
}
.woocommerce .woocommerce-pagination ul li .page-numbers.current,
.woocommerce-page .woocommerce-pagination ul li .page-numbers.current {
  color: #ffffff;
}
.woocommerce .woocommerce-pagination ul li .page-numbers.dots,
.woocommerce-page .woocommerce-pagination ul li .page-numbers.dots {
  background: none;
}
.woocommerce .cart-empty,
.woocommerce-page .cart-empty,
.woocommerce .return-to-shop,
.woocommerce-page .return-to-shop {
  text-align: center;
}
.woocommerce .woocommerce-message,
.woocommerce-page .woocommerce-message,
.woocommerce .woocommerce-info,
.woocommerce-page .woocommerce-info,
.woocommerce .woocommerce-error,
.woocommerce-page .woocommerce-error {
  padding: 20px 20px 20px 60px;
  margin-bottom: 40px;
  color: #3c763d;
  position: relative;
  background: #ffffff;
  border: 2px solid;
}
.woocommerce .woocommerce-message:after,
.woocommerce-page .woocommerce-message:after,
.woocommerce .woocommerce-info:after,
.woocommerce-page .woocommerce-info:after,
.woocommerce .woocommerce-error:after,
.woocommerce-page .woocommerce-error:after {
  position: absolute;
  font-family: 'FontAwesome';
  left: 20px;
  top: 50%;
  margin-top: -24px;
  font-size: 28px;
  content: "\f058";
}
.woocommerce .woocommerce-message .button,
.woocommerce-page .woocommerce-message .button,
.woocommerce .woocommerce-info .button,
.woocommerce-page .woocommerce-info .button,
.woocommerce .woocommerce-error .button,
.woocommerce-page .woocommerce-error .button {
  height: auto;
  line-height: 1.8;
  font-weight: bold;
  background: none;
  padding: 0 0 2px 0;
  border-bottom: 2px solid;
  float: right;
  color: inherit;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.woocommerce .woocommerce-message .showlogin,
.woocommerce-page .woocommerce-message .showlogin,
.woocommerce .woocommerce-info .showlogin,
.woocommerce-page .woocommerce-info .showlogin,
.woocommerce .woocommerce-error .showlogin,
.woocommerce-page .woocommerce-error .showlogin,
.woocommerce .woocommerce-message .showcoupon,
.woocommerce-page .woocommerce-message .showcoupon,
.woocommerce .woocommerce-info .showcoupon,
.woocommerce-page .woocommerce-info .showcoupon,
.woocommerce .woocommerce-error .showcoupon,
.woocommerce-page .woocommerce-error .showcoupon {
  font-weight: bold;
}
.woocommerce .woocommerce-message li,
.woocommerce-page .woocommerce-message li,
.woocommerce .woocommerce-info li,
.woocommerce-page .woocommerce-info li,
.woocommerce .woocommerce-error li,
.woocommerce-page .woocommerce-error li {
  list-style: none;
}
.woocommerce .woocommerce-info,
.woocommerce-page .woocommerce-info {
  color: #31708f;
}
.woocommerce .woocommerce-info:after,
.woocommerce-page .woocommerce-info:after {
  content: "\f05a";
}
.woocommerce .woocommerce-error,
.woocommerce-page .woocommerce-error {
  color: #a94442;
}
.woocommerce .woocommerce-error:after,
.woocommerce-page .woocommerce-error:after {
  content: "\f071";
}
.woocommerce .checkout_coupon,
.woocommerce-page .checkout_coupon {
  margin-bottom: 50px;
}
.woocommerce .login .lost_password,
.woocommerce-page .login .lost_password,
.woocommerce .checkout_coupon .lost_password,
.woocommerce-page .checkout_coupon .lost_password,
.woocommerce .checkout .lost_password,
.woocommerce-page .checkout .lost_password {
  margin-bottom: 20px;
}
.woocommerce .login .lost_password a,
.woocommerce-page .login .lost_password a,
.woocommerce .checkout_coupon .lost_password a,
.woocommerce-page .checkout_coupon .lost_password a,
.woocommerce .checkout .lost_password a,
.woocommerce-page .checkout .lost_password a {
  color: #b9c1cf;
}
.woocommerce .login .lost_password a:hover,
.woocommerce-page .login .lost_password a:hover,
.woocommerce .checkout_coupon .lost_password a:hover,
.woocommerce-page .checkout_coupon .lost_password a:hover,
.woocommerce .checkout .lost_password a:hover,
.woocommerce-page .checkout .lost_password a:hover {
  color: inherit;
}
.woocommerce .login h1,
.woocommerce-page .login h1,
.woocommerce .checkout_coupon h1,
.woocommerce-page .checkout_coupon h1,
.woocommerce .checkout h1,
.woocommerce-page .checkout h1,
.woocommerce .login h2,
.woocommerce-page .login h2,
.woocommerce .checkout_coupon h2,
.woocommerce-page .checkout_coupon h2,
.woocommerce .checkout h2,
.woocommerce-page .checkout h2,
.woocommerce .login h3,
.woocommerce-page .login h3,
.woocommerce .checkout_coupon h3,
.woocommerce-page .checkout_coupon h3,
.woocommerce .checkout h3,
.woocommerce-page .checkout h3,
.woocommerce .login h4,
.woocommerce-page .login h4,
.woocommerce .checkout_coupon h4,
.woocommerce-page .checkout_coupon h4,
.woocommerce .checkout h4,
.woocommerce-page .checkout h4,
.woocommerce .login h4,
.woocommerce-page .login h4,
.woocommerce .checkout_coupon h4,
.woocommerce-page .checkout_coupon h4,
.woocommerce .checkout h4,
.woocommerce-page .checkout h4,
.woocommerce .login h6,
.woocommerce-page .login h6,
.woocommerce .checkout_coupon h6,
.woocommerce-page .checkout_coupon h6,
.woocommerce .checkout h6,
.woocommerce-page .checkout h6 {
  font-size: 20px;
  margin: 0 0 30px 0;
  position: relative;
  border-bottom: 1px solid rgba(54, 70, 115, 0.08);
  padding-bottom: 15px;
}
.woocommerce .form-row label,
.woocommerce-page .form-row label {
  display: block;
  margin-bottom: 5px;
  margin-top: 20px;
}
.woocommerce .form-row label.checkbox,
.woocommerce-page .form-row label.checkbox {
  display: inline-block;
}
.woocommerce .form-row .input-text,
.woocommerce-page .form-row .input-text {
  width: 50%;
}
.woocommerce .checkout,
.woocommerce-page .checkout {
  display: inline-block;
  width: 100%;
}
.woocommerce #customer_details,
.woocommerce-page #customer_details {
  float: left;
  width: 45%;
  margin-bottom: 50px;
}
.woocommerce #customer_details .input-text,
.woocommerce-page #customer_details .input-text {
  width: 100%;
}
.woocommerce #customer_details .woocommerce-billing-fields,
.woocommerce-page #customer_details .woocommerce-billing-fields {
  margin-bottom: 30px;
}
.woocommerce #order_review_heading,
.woocommerce-page #order_review_heading {
  width: 48%;
  float: right;
}
.woocommerce #order_review,
.woocommerce-page #order_review {
  text-align: left;
  float: right;
  width: 48%;
  position: relative;
  margin-bottom: 50px;
}
.woocommerce #order_review .shop_table,
.woocommerce-page #order_review .shop_table {
  margin-bottom: 20px;
}
.woocommerce #order_review #payment .payment_methods,
.woocommerce-page #order_review #payment .payment_methods {
  padding: 0 20px;
  margin: 40px 0;
  border: 1px solid rgba(54, 70, 115, 0.08);
}
.woocommerce #order_review #payment .payment_methods li,
.woocommerce-page #order_review #payment .payment_methods li {
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
}
.woocommerce #order_review #payment .payment_methods li.woocommerce-notice,
.woocommerce-page #order_review #payment .payment_methods li.woocommerce-notice {
  padding-left: 40px;
  margin: 0;
}
.woocommerce #order_review #payment .payment_methods li.woocommerce-notice:after,
.woocommerce-page #order_review #payment .payment_methods li.woocommerce-notice:after {
  left: 0;
}
.woocommerce #order_review #payment .payment_methods li:last-child,
.woocommerce-page #order_review #payment .payment_methods li:last-child {
  border: none;
}
.woocommerce #order_review #payment .payment_methods li label,
.woocommerce-page #order_review #payment .payment_methods li label {
  cursor: pointer;
}
.woocommerce #order_review #payment .payment_methods li label img,
.woocommerce-page #order_review #payment .payment_methods li label img {
  display: block;
}
.woocommerce #order_review #payment .payment_methods li label .about_paypal,
.woocommerce-page #order_review #payment .payment_methods li label .about_paypal {
  display: block;
}
.woocommerce #order_review #payment .payment_methods li .payment_box,
.woocommerce-page #order_review #payment .payment_methods li .payment_box {
  margin-top: 15px;
}
.woocommerce #order_review #payment #place_order,
.woocommerce-page #order_review #payment #place_order {
  margin-bottom: 20px;
}
.woocommerce .order_details,
.woocommerce-page .order_details {
  margin: 0;
  padding: 20px 0;
  border-top: 1px solid #e6e6e6;
  display: inline-block;
  width: 100%;
}
.woocommerce .order_details li,
.woocommerce-page .order_details li {
  list-style: none;
  float: left;
  border-right: 1px dotted #cccccc;
  padding: 0 20px;
}
.woocommerce .order_details li:first-child,
.woocommerce-page .order_details li:first-child {
  padding-left: 0;
}
.woocommerce .order_details li:last-child,
.woocommerce-page .order_details li:last-child {
  border: none;
}
.woocommerce .order_details li strong,
.woocommerce-page .order_details li strong {
  display: block;
}
.woocommerce .products,
.woocommerce-page .products {
  padding: 0;
  margin: 0;
  position: relative;
  clear: both;
}
.woocommerce .products[class*="columns-"],
.woocommerce-page .products[class*="columns-"] {
  width: 100%;
}
.woocommerce .products:after,
.woocommerce-page .products:after {
  clear: both;
  content: "";
  display: block;
}
.woocommerce .products.columns-2 > li,
.woocommerce-page .products.columns-2 > li {
  width: 50%;
}
.woocommerce .products.columns-2 > li:nth-child(2n + 1),
.woocommerce-page .products.columns-2 > li:nth-child(2n + 1) {
  clear: left;
}
.woocommerce .products.columns-2 > li:nth-child(2n),
.woocommerce-page .products.columns-2 > li:nth-child(2n) {
  border-right: 1px solid #f1f2f8;
}
.woocommerce .products.columns-3 > li,
.woocommerce-page .products.columns-3 > li {
  width: 33.3333333%;
}
.woocommerce .products.columns-3 > li:nth-child(3n + 1),
.woocommerce-page .products.columns-3 > li:nth-child(3n + 1) {
  clear: left;
}
.woocommerce .products.columns-3 > li:nth-child(3n),
.woocommerce-page .products.columns-3 > li:nth-child(3n) {
  border-right: 1px solid #f1f2f8;
}
.woocommerce .products.columns-4 > li,
.woocommerce-page .products.columns-4 > li {
  width: 25%;
}
.woocommerce .products.columns-4 > li:nth-child(4n + 1),
.woocommerce-page .products.columns-4 > li:nth-child(4n + 1) {
  clear: left;
}
.woocommerce .products.columns-4 > li:nth-child(4n),
.woocommerce-page .products.columns-4 > li:nth-child(4n) {
  border-right: 1px solid #f1f2f8;
}
.woocommerce .products.columns-5 > li,
.woocommerce-page .products.columns-5 > li {
  width: 20%;
}
.woocommerce .products.columns-5 > li:nth-child(5n + 1),
.woocommerce-page .products.columns-5 > li:nth-child(5n + 1) {
  clear: left;
}
.woocommerce .products.columns-5 > li:nth-child(5n),
.woocommerce-page .products.columns-5 > li:nth-child(5n) {
  border-right: 1px solid #f1f2f8;
}
.woocommerce .products li,
.woocommerce-page .products li {
  list-style: none;
  float: left;
  padding: 30px;
  margin-bottom: -1px;
  position: relative;
  text-align: center;
  border: 1px solid #f1f2f8;
  border-right-color: transparent;
}
.woocommerce .products li:hover,
.woocommerce-page .products li:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.woocommerce .products li:last-child,
.woocommerce-page .products li:last-child {
  border-right: 1px solid #f1f2f8;
}
.woocommerce .products li .product-thumbnail,
.woocommerce-page .products li .product-thumbnail {
  margin-bottom: 30px;
  padding: 0 30px;
}
.woocommerce .products li .product-info,
.woocommerce-page .products li .product-info {
  overflow: hidden;
}
.woocommerce .products li .product-info h3,
.woocommerce-page .products li .product-info h3,
.woocommerce .products li .product-info .woocommerce-loop-product__title,
.woocommerce-page .products li .product-info .woocommerce-loop-product__title {
  font-size: 16px;
  margin: 0 0 5px 0;
  text-transform: none;
}
.woocommerce .products li .product-info .price,
.woocommerce-page .products li .product-info .price {
  display: block;
  margin: 0 0 15px 0;
  font-size: 16px;
  color: #b9c1cf;
}
.woocommerce .products li .product-info .price del,
.woocommerce-page .products li .product-info .price del {
  font-weight: normal;
  font-size: 14px;
}
.woocommerce .products li .product-info .price ins,
.woocommerce-page .products li .product-info .price ins {
  text-decoration: none;
}
.woocommerce .products li .product-info .star-rating,
.woocommerce-page .products li .product-info .star-rating {
  display: inline-block;
}
.woocommerce .products li .product-info .secondary-image,
.woocommerce-page .products li .product-info .secondary-image {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 15px;
  visibility: hidden;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
.woocommerce .products li .add_to_cart_button.loading,
.woocommerce-page .products li .add_to_cart_button.loading {
  -webkit-opacity: 0.3 !important;
  -khtml-opacity: 0.3 !important;
  -moz-opacity: 0.3 !important;
  opacity: 0.3 !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30) !important;
  filter: alpha(opacity=30) !important;
}
.woocommerce .products li .wc-forward,
.woocommerce-page .products li .wc-forward {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
  text-indent: -9999px;
  z-index: 2;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
.woocommerce .products li .wc-forward:after,
.woocommerce-page .products li .wc-forward:after {
  position: absolute;
  content: "\f00c";
  font-family: 'FontAwesome';
  left: 17px;
  top: 8px;
  font-size: 18px;
  color: #ffffff;
  text-indent: 0;
}
.woocommerce .products li.product-category a,
.woocommerce-page .products li.product-category a {
  display: block;
  background: #000000;
}
.woocommerce .products li.product-category a:hover img,
.woocommerce-page .products li.product-category a:hover img {
  -webkit-opacity: 0.5;
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
}
.woocommerce .products li.product-category h3,
.woocommerce-page .products li.product-category h3 {
  font-size: 18px;
  margin: 0;
  position: absolute;
  background: #ffffff;
  bottom: 20px;
  left: 35px;
  right: 35px;
  text-align: center;
  padding: 15px 30px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.woocommerce .products li.product-category h3 mark,
.woocommerce-page .products li.product-category h3 mark {
  background: none;
  position: relative;
  font-size: 14px;
  top: -10px;
  color: #777;
}
.woocommerce .products li.product-category.last,
.woocommerce-page .products li.product-category.last {
  margin-bottom: 50px;
}
.woocommerce .quantity .input-text,
.woocommerce-page .quantity .input-text {
  width: 90px;
  vertical-align: middle;
  text-align: center;
}
.woocommerce .quantity .screen-reader-text,
.woocommerce-page .quantity .screen-reader-text {
  display: none;
}
.woocommerce .product,
.woocommerce-page .product {
  position: relative;
}
.woocommerce .product .images,
.woocommerce-page .product .images {
  float: left;
  width: 50%;
  padding-right: 25px;
  position: relative;
}
.woocommerce .product .images a,
.woocommerce-page .product .images a {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: zoom-in;
}
.woocommerce .product .images .thumbnails,
.woocommerce-page .product .images .thumbnails {
  overflow: hidden;
  width: 100%;
}
.woocommerce .product .images .thumbnails a,
.woocommerce-page .product .images .thumbnails a {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  width: 90px;
}
.woocommerce .product .summary,
.woocommerce-page .product .summary {
  float: right;
  width: 50%;
  padding-left: 25px;
}
.woocommerce .product .summary .product_title,
.woocommerce-page .product .summary .product_title {
  font-size: 30px;
  margin-top: 0;
}
.woocommerce .product .summary .woocommerce-product-rating,
.woocommerce-page .product .summary .woocommerce-product-rating {
  margin-bottom: 10px;
}
.woocommerce .product .summary .woocommerce-product-rating .star-rating,
.woocommerce-page .product .summary .woocommerce-product-rating .star-rating {
  display: inline-block;
  margin-right: 10px;
}
.woocommerce .product .summary .woocommerce-product-rating .woocommerce-review-link,
.woocommerce-page .product .summary .woocommerce-product-rating .woocommerce-review-link {
  color: #b9c1cf;
}
.woocommerce .product .summary .woocommerce-product-rating .woocommerce-review-link:hover,
.woocommerce-page .product .summary .woocommerce-product-rating .woocommerce-review-link:hover {
  color: inherit;
}
.woocommerce .product .summary p.price,
.woocommerce-page .product .summary p.price {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.woocommerce .product .summary p.price .amount,
.woocommerce-page .product .summary p.price .amount {
  display: inline-block;
}
.woocommerce .product .summary p.price del,
.woocommerce-page .product .summary p.price del {
  color: #b9c1cf;
}
.woocommerce .product .summary p.price del .amount,
.woocommerce-page .product .summary p.price del .amount {
  background: none;
  padding: 0;
  margin-right: 10px;
  text-decoration: inherit;
  font-weight: normal;
}
.woocommerce .product .summary p.price ins,
.woocommerce-page .product .summary p.price ins {
  text-decoration: none;
}
.woocommerce .product .summary p.cart,
.woocommerce-page .product .summary p.cart {
  margin-bottom: 50px;
}
.woocommerce .product .summary .cart,
.woocommerce-page .product .summary .cart {
  margin: 30px 0;
}
.woocommerce .product .summary .cart.variations_form,
.woocommerce-page .product .summary .cart.variations_form {
  margin-bottom: 0;
  margin-top: 30px;
}
.woocommerce .product .summary .cart.variations_form .variations_button,
.woocommerce-page .product .summary .cart.variations_form .variations_button {
  margin-bottom: 50px;
}
.woocommerce .product .summary .cart button.single_add_to_cart_button,
.woocommerce-page .product .summary .cart button.single_add_to_cart_button {
  padding-top: 0;
  vertical-align: middle;
}
.woocommerce .product .summary .cart button.single_add_to_cart_button:disabled,
.woocommerce-page .product .summary .cart button.single_add_to_cart_button:disabled {
  background: #f2f2f2;
  cursor: no-drop;
}
.woocommerce .product .summary .cart button.single_add_to_cart_button:disabled:hover,
.woocommerce-page .product .summary .cart button.single_add_to_cart_button:disabled:hover {
  background: #f2f2f2;
}
.woocommerce .product .summary .cart .quantity,
.woocommerce-page .product .summary .cart .quantity {
  float: left;
  margin-right: 10px;
}
.woocommerce .product .summary .cart .variations,
.woocommerce-page .product .summary .cart .variations {
  position: relative;
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.woocommerce .product .summary .cart .variations tr,
.woocommerce-page .product .summary .cart .variations tr {
  margin-bottom: 10px;
  border: none;
  background: none;
}
.woocommerce .product .summary .cart .variations tr td,
.woocommerce-page .product .summary .cart .variations tr td {
  vertical-align: sub;
}
.woocommerce .product .summary .cart .variations tr td.label,
.woocommerce-page .product .summary .cart .variations tr td.label {
  padding: 10px 10px 0 0;
  border: none;
}
.woocommerce .product .summary .cart .variations tr td.value,
.woocommerce-page .product .summary .cart .variations tr td.value {
  width: 100%;
  padding: 0 0 10px 0;
  border: none;
}
.woocommerce .product .summary .cart .variations tr td.value select,
.woocommerce-page .product .summary .cart .variations tr td.value select {
  width: 100%;
}
.woocommerce .product .summary .cart .variations tr td.value a.reset_variations,
.woocommerce-page .product .summary .cart .variations tr td.value a.reset_variations {
  margin-top: 10px;
  display: block;
  color: #b9c1cf;
  font-size: 11px;
}
.woocommerce .product .summary .cart .variations tr td.value a.reset_variations:hover,
.woocommerce-page .product .summary .cart .variations tr td.value a.reset_variations:hover {
  color: inherit;
}
.woocommerce .product .summary .cart .single_variation_wrap .single_variation .price,
.woocommerce-page .product .summary .cart .single_variation_wrap .single_variation .price {
  font-size: 18px;
}
.woocommerce .product .summary .cart table,
.woocommerce-page .product .summary .cart table {
  border: none;
}
.woocommerce .product .summary .product_meta .posted_in,
.woocommerce-page .product .summary .product_meta .posted_in,
.woocommerce .product .summary .product_meta .tagged_as,
.woocommerce-page .product .summary .product_meta .tagged_as,
.woocommerce .product .summary .product_meta .sku_wrapper,
.woocommerce-page .product .summary .product_meta .sku_wrapper {
  margin-right: 20px;
  display: block;
}
.woocommerce .product .summary .product_meta .posted_in a,
.woocommerce-page .product .summary .product_meta .posted_in a,
.woocommerce .product .summary .product_meta .tagged_as a,
.woocommerce-page .product .summary .product_meta .tagged_as a,
.woocommerce .product .summary .product_meta .sku_wrapper a,
.woocommerce-page .product .summary .product_meta .sku_wrapper a {
  color: #b9c1cf;
}
.woocommerce .product .summary .product_meta .posted_in a:hover,
.woocommerce-page .product .summary .product_meta .posted_in a:hover,
.woocommerce .product .summary .product_meta .tagged_as a:hover,
.woocommerce-page .product .summary .product_meta .tagged_as a:hover,
.woocommerce .product .summary .product_meta .sku_wrapper a:hover,
.woocommerce-page .product .summary .product_meta .sku_wrapper a:hover {
  color: inherit;
}
.woocommerce .product .woocommerce-tabs,
.woocommerce-page .product .woocommerce-tabs {
  padding-top: 50px;
  clear: both;
}
.woocommerce .product .woocommerce-tabs:after,
.woocommerce-page .product .woocommerce-tabs:after {
  clear: both;
  content: "";
  display: table;
}
.woocommerce .product .woocommerce-tabs .tabs,
.woocommerce-page .product .woocommerce-tabs .tabs {
  padding: 0 5px;
  margin-bottom: 0;
}
.woocommerce .product .woocommerce-tabs .tabs:after,
.woocommerce-page .product .woocommerce-tabs .tabs:after {
  clear: both;
  content: "";
  display: table;
}
.woocommerce .product .woocommerce-tabs .tabs li,
.woocommerce-page .product .woocommerce-tabs .tabs li {
  list-style: none;
  float: left;
}
.woocommerce .product .woocommerce-tabs .tabs li.active a,
.woocommerce-page .product .woocommerce-tabs .tabs li.active a {
  background: #f1f2f8;
}
.woocommerce .product .woocommerce-tabs .tabs li.active a:before,
.woocommerce-page .product .woocommerce-tabs .tabs li.active a:before {
  display: block;
}
.woocommerce .product .woocommerce-tabs .tabs li a,
.woocommerce-page .product .woocommerce-tabs .tabs li a {
  color: #ffffff;
  padding: 15px 25px;
  display: block;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}
.woocommerce .product .woocommerce-tabs .tabs li a:before,
.woocommerce-page .product .woocommerce-tabs .tabs li a:before {
  width: 100%;
  left: 0;
  bottom: 100%;
  height: 5px;
  content: "";
  position: absolute;
  top: auto;
  display: none;
}
.woocommerce .product .woocommerce-tabs .entry-content,
.woocommerce-page .product .woocommerce-tabs .entry-content {
  padding: 25px 30px;
  background: #f1f2f8;
}
.woocommerce .product .woocommerce-tabs .entry-content img.alignnone,
.woocommerce-page .product .woocommerce-tabs .entry-content img.alignnone {
  margin-top: 50px;
  margin-bottom: 50px;
}
.woocommerce .product .woocommerce-tabs .entry-content h2,
.woocommerce-page .product .woocommerce-tabs .entry-content h2 {
  display: none;
}
.woocommerce .product .woocommerce-tabs .shop_attributes,
.woocommerce-page .product .woocommerce-tabs .shop_attributes {
  width: 100%;
}
.woocommerce .product .woocommerce-tabs .shop_attributes tr,
.woocommerce-page .product .woocommerce-tabs .shop_attributes tr {
  text-align: left;
}
.woocommerce .product .woocommerce-tabs .shop_attributes tr:last-child,
.woocommerce-page .product .woocommerce-tabs .shop_attributes tr:last-child {
  border: none;
}
.woocommerce .product .woocommerce-tabs .shop_attributes tr th,
.woocommerce-page .product .woocommerce-tabs .shop_attributes tr th {
  width: 200px;
  font-weight: normal;
  color: #000000;
}
.woocommerce .product .woocommerce-tabs .shop_attributes tr td,
.woocommerce-page .product .woocommerce-tabs .shop_attributes tr td {
  font-style: italic;
  color: #666;
}
.woocommerce .product .woocommerce-tabs #comments,
.woocommerce-page .product .woocommerce-tabs #comments {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  padding: 0;
}
.woocommerce .product .related,
.woocommerce-page .product .related,
.woocommerce .product .upsells,
.woocommerce-page .product .upsells {
  padding-top: 50px;
  margin: 0;
}
.woocommerce .product .related h2:not(.woocommerce-loop-product__title),
.woocommerce-page .product .related h2:not(.woocommerce-loop-product__title),
.woocommerce .product .upsells h2:not(.woocommerce-loop-product__title),
.woocommerce-page .product .upsells h2:not(.woocommerce-loop-product__title) {
  font-size: 20px;
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
  margin: 0 0 40px 0;
}
.woocommerce .product .related h2:not(.woocommerce-loop-product__title):after,
.woocommerce-page .product .related h2:not(.woocommerce-loop-product__title):after,
.woocommerce .product .upsells h2:not(.woocommerce-loop-product__title):after,
.woocommerce-page .product .upsells h2:not(.woocommerce-loop-product__title):after {
  width: 50px;
  height: 3px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}
.woocommerce .product .related .products li,
.woocommerce-page .product .related .products li,
.woocommerce .product .upsells .products li,
.woocommerce-page .product .upsells .products li {
  margin-bottom: 0;
}
.woocommerce .product .related .products li .product-inner,
.woocommerce-page .product .related .products li .product-inner,
.woocommerce .product .upsells .products li .product-inner,
.woocommerce-page .product .upsells .products li .product-inner {
  padding-bottom: 0;
  border-bottom: none;
}
.woocommerce #respond #reply-title,
.woocommerce-page #respond #reply-title {
  font-size: 18px;
  margin-top: 0;
}
.woocommerce #respond #commentform,
.woocommerce-page #respond #commentform {
  width: 100%;
}
.woocommerce #respond #commentform p label,
.woocommerce-page #respond #commentform p label {
  display: inline-block;
  margin-bottom: 5px;
}
.woocommerce #respond #commentform p input,
.woocommerce-page #respond #commentform p input,
.woocommerce #respond #commentform p textarea#comment,
.woocommerce-page #respond #commentform p textarea#comment {
  width: 100%;
  margin: 0;
}
.woocommerce #respond #commentform p.stars,
.woocommerce-page #respond #commentform p.stars {
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1.1em;
  font-size: 14px;
  width: 66px;
  font-family: 'FontAwesome';
  margin-top: 10px;
}
.woocommerce #respond #commentform p.stars:before,
.woocommerce-page #respond #commentform p.stars:before {
  content: "\f006\f006\f006\f006\f006";
  float: left;
  left: 0;
  position: absolute;
  top: 0;
}
.woocommerce #respond #commentform p.stars span a,
.woocommerce-page #respond #commentform p.stars span a {
  float: left;
  height: 0;
  left: 0;
  overflow: hidden;
  padding-top: 1em;
  position: absolute;
  top: 0;
}
.woocommerce #respond #commentform p.stars span a:before,
.woocommerce-page #respond #commentform p.stars span a:before {
  left: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
  content: "\f005\f005\f005\f005\f005";
}
.woocommerce #respond #commentform p.stars span a.star-1,
.woocommerce-page #respond #commentform p.stars span a.star-1 {
  width: 13px;
  z-index: 10;
}
.woocommerce #respond #commentform p.stars span a.star-2,
.woocommerce-page #respond #commentform p.stars span a.star-2 {
  width: 26px;
  z-index: 9;
}
.woocommerce #respond #commentform p.stars span a.star-3,
.woocommerce-page #respond #commentform p.stars span a.star-3 {
  width: 39px;
  z-index: 8;
}
.woocommerce #respond #commentform p.stars span a.star-4,
.woocommerce-page #respond #commentform p.stars span a.star-4 {
  width: 52px;
  z-index: 7;
}
.woocommerce #respond #commentform p.stars span a.star-5,
.woocommerce-page #respond #commentform p.stars span a.star-5 {
  width: 65px;
  z-index: 6;
}
.woocommerce #respond #commentform p.stars span a.active:before,
.woocommerce-page #respond #commentform p.stars span a.active:before {
  color: #000000;
  visibility: visible;
}
.woocommerce #respond #commentform p.comment-form-comment,
.woocommerce-page #respond #commentform p.comment-form-comment {
  float: none;
  padding: 0;
  width: 100%;
}
.woocommerce #respond #commentform p.comment-form-comment textarea,
.woocommerce-page #respond #commentform p.comment-form-comment textarea {
  height: 100px;
}
.woocommerce #respond #commentform p.form-submit input,
.woocommerce-page #respond #commentform p.form-submit input {
  width: auto;
}
.woocommerce #reviews #comments,
.woocommerce-page #reviews #comments {
  margin-top: 0;
  margin-bottom: 40px;
}
.woocommerce #reviews #comments ol.commentlist,
.woocommerce-page #reviews #comments ol.commentlist {
  padding: 0;
  margin: 0;
}
.woocommerce #reviews #comments ol.commentlist li,
.woocommerce-page #reviews #comments ol.commentlist li {
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px dotted rgba(54, 70, 115, 0.1);
}
.woocommerce #reviews #comments ol.commentlist li:first-child,
.woocommerce-page #reviews #comments ol.commentlist li:first-child {
  padding-top: 0;
}
.woocommerce #reviews #comments ol.commentlist li img.avatar,
.woocommerce-page #reviews #comments ol.commentlist li img.avatar {
  float: left;
}
.woocommerce #reviews #comments ol.commentlist li .comment-text,
.woocommerce-page #reviews #comments ol.commentlist li .comment-text {
  margin-left: 80px;
  position: relative;
}
.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta,
.woocommerce-page #reviews #comments ol.commentlist li .comment-text p.meta {
  margin-bottom: 5px;
}
.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta time,
.woocommerce-page #reviews #comments ol.commentlist li .comment-text p.meta time {
  color: #b9c1cf;
}
.woocommerce #reviews #comments ol.commentlist li .star-rating,
.woocommerce-page #reviews #comments ol.commentlist li .star-rating {
  position: absolute;
  right: 0;
  top: 0;
}
.woocommerce #reviews #comments .add_review,
.woocommerce-page #reviews #comments .add_review {
  padding-left: 75px;
}
.woocommerce #reviews #comments .add_review a,
.woocommerce-page #reviews #comments .add_review a {
  margin: 0;
}
.woocommerce .shop_table,
.woocommerce-page .shop_table {
  display: table;
}
.woocommerce .shop_table tbody tr td.product-remove,
.woocommerce-page .shop_table tbody tr td.product-remove {
  padding-right: 20px;
}
.woocommerce .shop_table tbody tr td.product-remove .remove,
.woocommerce-page .shop_table tbody tr td.product-remove .remove {
  width: 24px;
  height: 24px;
  line-height: 22px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  background: #e8f1f5;
  border: 2px solid #ffffff;
}
.woocommerce .shop_table tbody tr td.product-price .amount,
.woocommerce-page .shop_table tbody tr td.product-price .amount,
.woocommerce .shop_table tbody tr td.product-subtotal .amount,
.woocommerce-page .shop_table tbody tr td.product-subtotal .amount {
  color: #e74c3c;
  font-weight: bold;
}
.woocommerce .shop_table tbody tr td.actions .coupon label[for="coupon_code"],
.woocommerce-page .shop_table tbody tr td.actions .coupon label[for="coupon_code"] {
  display: none;
}
.woocommerce .shop_table tbody tr td.actions .coupon #coupon_code,
.woocommerce-page .shop_table tbody tr td.actions .coupon #coupon_code {
  float: left;
}
.woocommerce .shop_table tbody tr td.actions .coupon .button,
.woocommerce-page .shop_table tbody tr td.actions .coupon .button {
  float: left;
  margin: 0 10px;
}
.woocommerce .shop_table tbody tr td img,
.woocommerce-page .shop_table tbody tr td img {
  width: 50px;
}
.woocommerce .shop_table.order_details,
.woocommerce-page .shop_table.order_details {
  border-top: none;
  margin-bottom: 30px;
}
.woocommerce .customer_details,
.woocommerce-page .customer_details {
  margin: 0;
}
.woocommerce .customer_details dt,
.woocommerce-page .customer_details dt,
.woocommerce .customer_details dd,
.woocommerce-page .customer_details dd {
  display: block;
  margin: 0;
}
.woocommerce .customer_details dt,
.woocommerce-page .customer_details dt {
  margin-top: 10px;
}
.woocommerce .customer_details h3,
.woocommerce-page .customer_details h3 {
  font-size: 24px;
}
.woocommerce .cart-collaterals,
.woocommerce-page .cart-collaterals {
  margin-bottom: 50px;
}
.woocommerce .cart-collaterals h2,
.woocommerce-page .cart-collaterals h2 {
  font-size: 20px;
  margin: 50px 0 30px 0;
  border-bottom: 1px solid rgba(54, 70, 115, 0.08);
  padding-bottom: 15px;
}
.woocommerce .cart-collaterals .products li,
.woocommerce-page .cart-collaterals .products li {
  margin-bottom: 0;
}
.woocommerce .cart-collaterals .cart_totals table,
.woocommerce-page .cart-collaterals .cart_totals table {
  margin-bottom: 20px;
}
.woocommerce .cart-collaterals .cart_totals table tr th,
.woocommerce-page .cart-collaterals .cart_totals table tr th,
.woocommerce .cart-collaterals .cart_totals table tr td,
.woocommerce-page .cart-collaterals .cart_totals table tr td {
  width: 50%;
}
.woocommerce .cart-collaterals .cart_totals table tr th .amount,
.woocommerce-page .cart-collaterals .cart_totals table tr th .amount,
.woocommerce .cart-collaterals .cart_totals table tr td .amount,
.woocommerce-page .cart-collaterals .cart_totals table tr td .amount {
  color: #e74c3c;
}
.woocommerce .cart-collaterals .cart_totals table tr.shipping .woocommerce-shipping-calculator > p,
.woocommerce-page .cart-collaterals .cart_totals table tr.shipping .woocommerce-shipping-calculator > p {
  margin-bottom: 0;
}
.woocommerce .cart-collaterals .cart_totals table tr.shipping .shipping-calculator-form,
.woocommerce-page .cart-collaterals .cart_totals table tr.shipping .shipping-calculator-form {
  margin-top: 10px;
}
.woocommerce .return-to-shop,
.woocommerce-page .return-to-shop {
  margin-bottom: 50px;
}
.woocommerce .return-to-shop .wc-backward,
.woocommerce-page .return-to-shop .wc-backward {
  height: 45px;
  line-height: 45px;
  padding: 0 50px;
  display: inline-block;
  width: auto;
}
.woocommerce fieldset {
  border: none;
  padding: 0;
  margin: 0 0 50px 0;
}
.woocommerce fieldset legend {
  color: #333333;
  font-size: 18px;
  margin: 30px 0 10px;
}
/* ============================================================ 
    2. WIDGET STYLES
=============================================================*/
.widget_shopping_cart .cart_list,
.widget_shopping_cart_content .cart_list {
  margin: 0;
  padding: 0;
}
.widget_shopping_cart .cart_list li,
.widget_shopping_cart_content .cart_list li {
  padding: 20px 0;
  border-bottom: 1px dotted rgba(54, 70, 115, 0.1);
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}
.widget_shopping_cart .cart_list li.empty,
.widget_shopping_cart_content .cart_list li.empty {
  padding: 0;
}
.widget_shopping_cart .cart_list li a,
.widget_shopping_cart_content .cart_list li a {
  display: block;
  font-weight: bold;
}
.widget_shopping_cart .cart_list li a img,
.widget_shopping_cart_content .cart_list li a img {
  max-width: 55px;
  height: auto;
  float: right;
  margin-left: 15px;
}
.widget_shopping_cart .cart_list li .quantity,
.widget_shopping_cart_content .cart_list li .quantity {
  color: #b9c1cf;
  display: inline-block;
  margin: 6px 0 0 35px;
}
.widget_shopping_cart .cart_list li .remove,
.widget_shopping_cart_content .cart_list li .remove {
  font-size: 20px;
  position: absolute !important;
  left: 0;
  bottom: 40px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 24px;
  background: #e8f1f5;
  font-weight: normal;
}
.widget_shopping_cart .cart_list li .remove:hover,
.widget_shopping_cart_content .cart_list li .remove:hover {
  color: #333333;
  border-color: #333333;
}
.widget_shopping_cart .cart_list li:first-child,
.widget_shopping_cart_content .cart_list li:first-child {
  padding-top: 0;
}
.widget_shopping_cart .cart_list li:last-child .remove,
.widget_shopping_cart_content .cart_list li:last-child .remove {
  bottom: 20px;
}
.widget_shopping_cart .cart_list li .variation dt,
.widget_shopping_cart_content .cart_list li .variation dt,
.widget_shopping_cart .cart_list li .variation dd,
.widget_shopping_cart_content .cart_list li .variation dd {
  display: inline-block;
  margin: 0;
}
.widget_shopping_cart .total,
.widget_shopping_cart_content .total {
  padding-top: 20px;
  text-align: center;
}
.widget_shopping_cart .buttons,
.widget_shopping_cart_content .buttons {
  display: inline-block;
  width: 100%;
}
.widget_shopping_cart .buttons .button,
.widget_shopping_cart_content .buttons .button {
  width: 50%;
  text-align: center;
}
.widget_shopping_cart .buttons .button.checkout,
.widget_shopping_cart_content .buttons .button.checkout {
  float: right;
}
.widget.widget_layered_nav ul {
  margin: 0;
  padding: 0;
}
.widget.widget_layered_nav ul li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid rgba(54, 70, 115, 0.08);
}
.widget.widget_layered_nav ul li .count {
  float: right;
  font-size: 100%;
}
.widget.widget_layered_nav ul li a {
  font-weight: bold;
}
.widget.widget_price_filter .price_slider_wrapper .ui-slider-horizontal {
  height: 2px;
}
.widget.widget_price_filter .price_slider_wrapper .ui-slider {
  position: relative;
  background: #e8f1f5;
}
.widget.widget_price_filter .price_slider_wrapper .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  height: 100%;
  top: 0;
}
.widget.widget_price_filter .price_slider_wrapper .ui-slider .ui-slider-handle {
  height: 10px;
  width: 10px;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: -4px;
  outline: 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.widget.widget_price_filter .price_slider_wrapper .ui-slider .ui-slider-handle:last-child {
  margin-left: -10px;
}
.widget.widget_price_filter .price_slider_wrapper .price_slider_amount {
  margin-top: 25px;
}
.widget.widget_price_filter .price_slider_wrapper .price_slider_amount .button {
  float: right;
  margin: 0;
  cursor: pointer;
  padding: 0 20px;
  height: 35px;
  line-height: 35px;
}
.widget.widget_price_filter .price_slider_wrapper .price_slider_amount .price_label {
  padding: 5px 0;
}
.widget.widget_product_categories ul {
  margin: 0;
  padding: 0;
}
.widget.widget_product_categories ul li {
  list-style: none;
  margin-bottom: 5px;
}
.widget.widget_product_categories ul li:before {
  display: inline-block;
  width: 5px;
  height: 5px;
  content: "";
  vertical-align: middle;
  margin: -1px 10px 0 2px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.widget.widget_products ul,
.widget.widget_recently_viewed_products ul,
.widget.widget_recent_reviews ul,
.widget.widget_top_rated_products ul {
  padding: 0;
  margin: 0;
}
.widget.widget_products ul li,
.widget.widget_recently_viewed_products ul li,
.widget.widget_recent_reviews ul li,
.widget.widget_top_rated_products ul li {
  display: inline-block;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px dotted rgba(54, 70, 115, 0.1);
}
.widget.widget_products ul li:last-child,
.widget.widget_recently_viewed_products ul li:last-child,
.widget.widget_recent_reviews ul li:last-child,
.widget.widget_top_rated_products ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.widget.widget_products ul li:first-child,
.widget.widget_recently_viewed_products ul li:first-child,
.widget.widget_recent_reviews ul li:first-child,
.widget.widget_top_rated_products ul li:first-child {
  padding-top: 0;
}
.widget.widget_products ul li a,
.widget.widget_recently_viewed_products ul li a,
.widget.widget_recent_reviews ul li a,
.widget.widget_top_rated_products ul li a {
  display: block;
  font-weight: bold;
}
.widget.widget_products ul li a img,
.widget.widget_recently_viewed_products ul li a img,
.widget.widget_recent_reviews ul li a img,
.widget.widget_top_rated_products ul li a img {
  float: right;
  max-width: 55px;
  height: auto;
  margin-left: 15px;
}
.widget.widget_products ul li .star-rating,
.widget.widget_recently_viewed_products ul li .star-rating,
.widget.widget_recent_reviews ul li .star-rating,
.widget.widget_top_rated_products ul li .star-rating {
  margin: 5px 0;
}
.widget.widget_products ul li .amount,
.widget.widget_recently_viewed_products ul li .amount,
.widget.widget_recent_reviews ul li .amount,
.widget.widget_top_rated_products ul li .amount {
  color: #b9c1cf;
}
.widget.widget_products ul li del,
.widget.widget_recently_viewed_products ul li del,
.widget.widget_recent_reviews ul li del,
.widget.widget_top_rated_products ul li del {
  color: #b9c1cf;
  font-size: 13px;
}
.widget.widget_products ul li ins,
.widget.widget_recently_viewed_products ul li ins,
.widget.widget_recent_reviews ul li ins,
.widget.widget_top_rated_products ul li ins {
  text-decoration: none;
}
.widget.widget_product_search .woocommerce-product-search {
  display: block;
  position: relative;
}
.widget.widget_product_search .woocommerce-product-search:after {
  position: absolute;
  top: 9px;
  right: 15px;
  color: #cccccc;
  content: "\f002";
  font-family: 'FontAwesome';
  font-size: 14px;
}
.widget.widget_product_search .woocommerce-product-search .screen-reader-text {
  display: none;
}
.widget.widget_product_search .woocommerce-product-search .search-field {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.widget.widget_product_search .woocommerce-product-search input[type="submit"] {
  display: none;
}
.widget.widget_product_tag_cloud .tagcloud {
  overflow: hidden;
}
.widget.widget_product_tag_cloud .tagcloud a {
  color: #ffffff;
  padding: 5px 20px;
  margin: 0 5px 5px 0;
  float: left;
  position: relative;
  font-size: 14px !important;
}
@media only screen and (max-width: 768px) {
  .woocommerce .products[class*="columns-"] li,
  .woocommerce-page .products[class*="columns-"] li {
    width: 50%;
    clear: none !important;
    border-right: 1px solid transparent !important;
  }
  .woocommerce .products[class*="columns-"] li:nth-child(2n + 1),
  .woocommerce-page .products[class*="columns-"] li:nth-child(2n + 1) {
    clear: left !important;
  }
  .woocommerce .products[class*="columns-"] li:nth-child(2n),
  .woocommerce-page .products[class*="columns-"] li:nth-child(2n),
  .woocommerce .products[class*="columns-"] li:last-child,
  .woocommerce-page .products[class*="columns-"] li:last-child {
    border-right: 1px solid #f1f2f8 !important;
  }
  .woocommerce .cart-collaterals .cart_totals,
  .woocommerce-page .cart-collaterals .cart_totals,
  .woocommerce .cart-collaterals .shipping_calculator,
  .woocommerce-page .cart-collaterals .shipping_calculator {
    float: none;
    width: 100%;
  }
  .woocommerce #customer_details,
  .woocommerce-page #customer_details,
  .woocommerce #order_review,
  .woocommerce-page #order_review,
  .woocommerce #order_review_heading,
  .woocommerce-page #order_review_heading {
    float: none;
    width: 100%;
  }
  .woocommerce .order_details li,
  .woocommerce-page .order_details li {
    float: none;
    border-right: none;
    border-bottom: 1px dotted #cccccc;
    padding: 20px 0;
  }
  .woocommerce.no-sidebar #reviews #comments,
  .woocommerce-page.no-sidebar #reviews #comments,
  .woocommerce.no-sidebar #reviews #review_form_wrapper,
  .woocommerce-page.no-sidebar #reviews #review_form_wrapper {
    float: none;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 568px) {
  .woocommerce.no-sidebar .product .summary,
  .woocommerce-page.no-sidebar .product .summary {
    width: 100%;
  }
  .woocommerce.no-sidebar .product .images,
  .woocommerce-page.no-sidebar .product .images {
    width: 100%;
  }
  .woocommerce .product .images,
  .woocommerce-page .product .images,
  .woocommerce .product .summary,
  .woocommerce-page .product .summary {
    float: none;
    width: 100%;
    padding: 0;
  }
  .woocommerce .product .images,
  .woocommerce-page .product .images {
    margin-bottom: 50px;
  }
  .woocommerce .product .woocommerce-tabs,
  .woocommerce-page .product .woocommerce-tabs {
    float: none;
    width: 100%;
    padding-left: 0;
  }
  .woocommerce .products[class*="columns-"] li,
  .woocommerce-page .products[class*="columns-"] li {
    width: 100%;
    border-right: 1px solid #f1f2f8 !important;
  }
}
/* WooCommerce 3.0 Gallery */
.woocommerce-product-gallery {
  position: relative;
  margin-bottom: 3em;
}
.woocommerce-product-gallery figure {
  margin: 0;
}
.woocommerce-product-gallery .woocommerce-product-gallery__image:nth-child(n+2) {
  width: 25%;
  display: inline-block;
}
.woocommerce-product-gallery .flex-control-thumbs li {
  list-style: none;
  float: left;
  cursor: pointer;
}
.woocommerce-product-gallery .flex-control-thumbs img {
  -webkit-opacity: 0.5;
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
}
.woocommerce-product-gallery .flex-control-thumbs img.flex-active,
.woocommerce-product-gallery .flex-control-thumbs img:hover {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.woocommerce-product-gallery img {
  display: block;
}
.woocommerce-product-gallery--columns-3 .flex-control-thumbs li {
  width: 33.3333%;
}
.woocommerce-product-gallery--columns-4 .flex-control-thumbs li {
  width: 25%;
}
.woocommerce-product-gallery--columns-5 .flex-control-thumbs li {
  width: 20%;
}
.woocommerce-product-gallery__trigger {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 99;
}
a.woocommerce-product-gallery__trigger {
  text-decoration: none;
}
.single-product div.product .woocommerce-product-gallery .woocommerce-product-gallery__trigger {
  position: absolute;
  top: .875em;
  right: 40px;
  display: block;
  height: 2em;
  width: 2em;
  border-radius: 3px;
  z-index: 99;
  text-align: center;
  text-indent: -999px;
  overflow: hidden;
}
.single-product div.product .woocommerce-product-gallery .woocommerce-product-gallery__trigger {
  background-color: #169fda;
  color: #ffffff;
}
.single-product div.product .woocommerce-product-gallery .woocommerce-product-gallery__trigger:hover {
  background-color: #1781ae;
  border-color: #1781ae;
  color: #ffffff;
}
.single-product div.product .woocommerce-product-gallery .woocommerce-product-gallery__trigger:before {
  font: normal normal normal 1em/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  content: "\f00e";
  line-height: 2;
  text-indent: 0;
}
.woocommerce-product-gallery .flex-control-thumbs {
  margin: 5px -5px 0 -5px;
  padding: 0;
}
.woocommerce-product-gallery .flex-control-thumbs:after {
  display: table;
  clear: both;
  content: "";
}
.woocommerce-product-gallery .flex-control-thumbs li {
  padding: 5px;
}
.woocommerce-product-gallery .flex-control-thumbs img {
  width: 100%;
}
@media only screen and (max-width: 1211px) {
  body.layout-boxed #site-wrapper {
    width: 100%;
    //
    padding: 0;
    max-width: 95%;
  }
  body .wrapper {
    width: 100%;
    // width: 20%;
    padding-left: 15px;
    padding-right: 15px;
  }
  body #site-header #masthead #site-navigator .menu li a {
    padding-right: 13px;
    padding-left: 13px;
  }
  body #site-header #masthead #site-navigator .menu.menu-extra {
    margin-left: 20px;
  }
  body.header-v2 #site-header #masthead .wrapper {
    padding: 0 15px;
  }
  body.header-v2 #site-header #masthead .wrapper:before {
    border: none;
  }
}
@media only screen and (max-width: 991px) {
  body #site-header #headerbar {
    text-align: center;
    padding: 10px 0;
  }
  body #site-header #headerbar .custom-info {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  body #site-header #headerbar .social-links {
    float: none;
    margin: 0 0 15px 0;
  }
  body #site-header #headerbar .social-links a {
    float: none;
    margin: 0 5px;
    display: inline-block;
  }
  body #site-header #headerbar .top-navigator {
    float: none;
  }
  body #site-header #headerbar .top-navigator .menu li {
    float: none;
    display: inline-block;
  }
  body #site-header #headerbar .top-navigator .menu li a {
    padding: 6px 8px;
    display: inline-block;
    margin: 0;
    border: none;
  }
  body #site-header #headerbar .top-navigator .menu li .sub-menu {
    text-align: left;
  }
  body #site-header #headerbar .top-navigator .menu li .sub-menu li {
    display: block;
  }
  body #site-header #masthead {
    padding-bottom: 0;
    // top: auto !important;
  }
  body #site-header #masthead #site-navigator {
    display: none;
  }
  body #site-header #masthead #site-navigator-mobile {
    display: block;
  }
//   body #site-header #masthead.stick {
//     // position: relative;
//   }
  body #site-header #masthead.stick + #masthead-placeholder {
    display: none;
  }
  body.header-v2 #site-header #headerbar {
    padding-bottom: 10px;
  }
  body.header-v2 #site-header #masthead {
    margin-top: 0;
    position: relative;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  body.header-v2 #site-header #masthead.stick {
    position: relative;
  }
  body.header-v2 #site-header #masthead .wrapper {
    padding: 0 15px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  body.header-v2 #site-content #page-header {
    padding-top: 50px;
  }
  body.header-v2.pageheader-hidden #site-content #page-breadcrumbs .wrapper {
    padding-top: 15px;
  }
  body.header-v3 #site-header #masthead {
    margin-top: 0;
    position: relative;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  body.header-v3 #site-header #masthead.stick {
    position: relative;
  }
  body.header-v3 #site-header #masthead .wrapper {
    padding: 0 15px;
  }
  body.header-v3 #site-content #page-header {
    padding-top: 50px;
  }
  body.header-v3.pageheader-hidden #site-content #page-breadcrumbs .wrapper {
    padding-top: 15px;
  }
  body.header-v4 #site-header #masthead .stick + #site-navigator-placeholder {
    display: none;
  }
  body.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget:first-child {
    margin-right: 100px;
  }
  body.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget .info-icon i {
    margin-right: 10px;
    font-size: 20px;
  }
  body #site-content #page-header {
    background-position: 0 0 !important;
  }
  body #site-footer .columns,
  body #content-bottom-widgets .columns {
    margin-bottom: 50px;
  }
  body #site-footer .columns:last-child,
  body #content-bottom-widgets .columns:last-child {
    margin-bottom: 0;
  }
  body #site-footer .columns .widget .subscrbe-form,
  body #content-bottom-widgets .columns .widget .subscrbe-form {
    padding: 0;
  }
  body #content-bottom-widgets .custom-info span {
    display: block;
    margin-bottom: 10px;
  }
  body #content-bottom-widgets .custom-info span:last-child {
    margin-bottom: 0;
  }
  body #content-bottom-widgets .custom-info span i {
    margin-left: 0 !important;
  }
  body #content-bottom-widgets .widget .logo-ft {
    text-align: inherit;
  }
  body.sidebar-right #page-body .content-wrap .content {
    padding-right: 15px;
    width: 100%;
  }
  body.sidebar-right #page-body .content-wrap .sidebars {
    padding-left: 15px;
    width: 100%;
    margin-top: 60px;
  }
  body.sidebar-right #page-body .content-wrap:before {
    display: none;
  }
  body.sidebar-left #page-body .content-wrap .content {
    left: 0;
    padding-left: 15px;
    width: 100%;
  }
  body.sidebar-left #page-body .content-wrap .sidebars {
    right: 0;
    left: auto;
    padding-right: 15px;
    width: 100%;
    margin-top: 60px;
  }
  body.sidebar-left #page-body .content-wrap:before {
    display: none;
  }
  body #site-wrapper .blog-shortcode.blog-grid.blog-two-columns .hentry,
  body #site-wrapper .blog-shortcode.blog-grid.blog-three-columns .hentry,
  body #site-wrapper .blog-shortcode.blog-grid.blog-four-columns .hentry {
    width: 50%;
  }
  body #site-wrapper .blog-shortcode.blog-grid.blog-two-columns .hentry:nth-of-type(2n+1),
  body #site-wrapper .blog-shortcode.blog-grid.blog-three-columns .hentry:nth-of-type(2n+1),
  body #site-wrapper .blog-shortcode.blog-grid.blog-four-columns .hentry:nth-of-type(2n+1),
  body #site-wrapper .blog-shortcode.blog-grid.blog-two-columns .hentry:nth-of-type(3n+1),
  body #site-wrapper .blog-shortcode.blog-grid.blog-three-columns .hentry:nth-of-type(3n+1),
  body #site-wrapper .blog-shortcode.blog-grid.blog-four-columns .hentry:nth-of-type(3n+1),
  body #site-wrapper .blog-shortcode.blog-grid.blog-two-columns .hentry:nth-of-type(4n+1),
  body #site-wrapper .blog-shortcode.blog-grid.blog-three-columns .hentry:nth-of-type(4n+1),
  body #site-wrapper .blog-shortcode.blog-grid.blog-four-columns .hentry:nth-of-type(4n+1) {
    clear: none;
  }
  body .projects[data-columns="2"] .projects-items .hentry,
  body .projects[data-columns="3"] .projects-items .hentry,
  body .projects[data-columns="4"] .projects-items .hentry,
  body .projects[data-columns="5"] .projects-items .hentry {
    width: 50%;
  }
  body .project-single.project-content-right .project-single-wrap .project-gallery,
  body .project-single.project-content-left .project-single-wrap .project-gallery,
  body .project-single.project-content-right .project-single-wrap .project-content,
  body .project-single.project-content-left .project-single-wrap .project-content {
    float: none;
    padding: 0;
    width: 100%;
  }
  body .project-single.project-content-right .project-single-wrap .project-gallery .project-title,
  body .project-single.project-content-left .project-single-wrap .project-gallery .project-title,
  body .project-single.project-content-right .project-single-wrap .project-content .project-title,
  body .project-single.project-content-left .project-single-wrap .project-content .project-title {
    margin-top: 30px;
  }
  body .project-single.project-content-right .project-single-wrap .project-content,
  body .project-single.project-content-left .project-single-wrap .project-content {
    position: static !important;
  }
  .columns-1,
  .columns-2,
  .columns-3,
  .columns-4,
  .columns-5,
  .columns-6,
  .columns-7,
  .columns-8,
  .columns-9,
  .columns-10,
  .columns-11,
  .columns-12,
  .sidebar-right #page-body .content-wrap .content,
  .sidebar-right #page-body .content-wrap .sidebars,
  .sidebar-left #page-body .content-wrap .content,
  .sidebar-left #page-body .content-wrap .sidebars {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  body #site-header #masthead #site-brand .header-widgets .widget {
    border: none;
    padding: 0;
  }
  body #site-header #masthead #site-brand .header-widgets .widget:last-child {
    margin-right: 30px;
  }
  body #site-content #page-header .page-title .title {
    font-size: 30px;
  }
  body #site-content .wpb_column .wpb_column {
    margin-bottom: 30px;
  }
  body #site-content .wpb_column .wpb_column:last-child {
    margin-bottom: 0;
  }
  body #site-footer #footer-content {
    text-align: center;
  }
  body #site-footer #footer-content .copyright {
    float: none;
  }
  body #site-footer #footer-content .social-links {
    float: none;
    margin-bottom: 20px;
  }
  body #site-footer #footer-content .social-links a {
    margin: 0 5px;
    float: none;
    display: inline-block;
  }
  body.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets {
    float: none;
    clear: both;
    margin: 0;
    border-top: 1px solid rgba(54, 70, 115, 0.08);
    padding-top: 20px;
    padding-bottom: 10px;
  }
  body.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget {
    float: none;
    display: inline-block;
    margin: 0 20px 0 0;
  }
  body.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget:first-child {
    margin-right: 20px;
    margin-left: 0;
  }
  body .projects .projects-filter ul li {
    float: none;
  }
  body .projects .projects-filter ul li a {
    border: none;
  }
  body .projects .projects-filter ul li a:after {
    height: 100%;
    left: 0;
    width: 4px;
    top: 0;
    bottom: 0;
  }
  body .title-button .button {
    position: static;
  }
  body table.shop_table_responsive tbody th,
  body table.shop_table_responsive thead,
  body table.shop_table_responsive tr td.product-thumbnail:before,
  body table.wishlist_table tbody th,
  body table.wishlist_table thead,
  body table.wishlist_table tr td.product-thumbnail:before {
    display: none;
  }
  body table.shop_table_responsive tr td,
  body table.wishlist_table tr td {
    display: block;
    clear: both;
    overflow: hidden;
  }
  body table.shop_table_responsive tr td:not(.product-quantity) {
    text-align: right;
  }
  body table.shop_table_responsive tr.bundle-products td:not(.product-quantity) {
    text-align: left;
  }
  body table.wishlist_table tr td:not(.product-quantity) {
    text-align: left;
  }
  body table.shop_table_responsive tr td:before {
    content: attr(data-title) ": ";
    font-weight: 600;
    float: left;
    color: #222;
  }
  body table.shop_table_responsive tr.bundle-products td:before {
    display: none;
  }
  body table.shop_table_responsive tr td.product-remove:before,
  body table.shop_table_responsive tr td.actions:before,
  body table.wishlist_table tr td.product-remove:before,
  body table.wishlist_table tr td.actions:before {
    content: '';
  }
  body table.shop_table_responsive tr td.product-thumbnail,
  body table.wishlist_table tr td.product-thumbnail {
    text-align: center;
  }
  body table.shop_table_responsive .product-quantity .quantity {
    float: right;
  }
  body .woocommerce .shop_table .actions .coupon {
    display: block;
    margin: 0;
  }
  body .woocommerce .shop_table .actions .coupon input {
    width: 100%;
    margin: 10px 0 !important;
  }
  body .woocommerce .shop_table .actions {
    text-align: left;
  }
}
@media only screen and (max-width: 568px) {
  body #site-header #headerbar .custom-info {
    margin-bottom: 10px;
  }
  body #site-header #headerbar .custom-info span {
    display: block;
  }
  body #site-header #headerbar .custom-info i {
    border: none;
    line-height: 24px;
    width: auto;
    background: none;
    margin-left: 10px;
    margin-right: 5px;
  }
  body .projects[data-columns="2"] .projects-items .hentry,
  body .projects[data-columns="3"] .projects-items .hentry,
  body .projects[data-columns="4"] .projects-items .hentry,
  body .projects[data-columns="5"] .projects-items .hentry {
    width: 100%;
  }
  body #site-wrapper .blog-shortcode.blog-grid.blog-two-columns .hentry,
  body #site-wrapper .blog-shortcode.blog-grid.blog-three-columns .hentry,
  body #site-wrapper .blog-shortcode.blog-grid.blog-four-columns .hentry {
    width: 100%;
  }
  body.blog .hentry .entry-header .entry-time,
  body.single-post .hentry .entry-header .entry-time {
    float: none;
    display: inline-block;
    border: none;
    margin: 0 0 5px 0;
    width: auto;
    line-height: 1.4;
    overflow: hidden;
    padding: 10px 15px;
    border: 1px solid ;
  }
  body.blog .hentry .entry-header .entry-time span,
  body.single-post .hentry .entry-header .entry-time span {
    font-size: 14px;
    float: left;
  }
  body.blog .hentry .entry-header .entry-time span.entry-day,
  body.single-post .hentry .entry-header .entry-time span.entry-day {
    font-size: 14px;
  }
  body.blog .hentry .entry-header .entry-time span.entry-month,
  body.single-post .hentry .entry-header .entry-time span.entry-month {
    margin: 0 3px;
  }
}
/*============================================================ 
                1. IMAGE
                2. SEPARATOR
                3. ACCORDION
                4. GELLERY
                5. CALL TO ACTION
                6. CAROUSEL
                7. GMAP
                8. TABS
                9. TOUR
                10. BUTTON
                11. PROGRESS BAR
                12. CONTENT GRID
                13. ICON BOX
                14. TESTIMONIAL
                15. MEMBER
                16. IMAGE BOX
                17. ICON LIST
                18. COUNTER
                19. OPENHOURS
                20. COUNTDOWN
                21. HEADING
                22. FLEX SLIDER
                23. CAROUSEL
                24. TOGGLE
                25. DROPCAP
                26. PRICING TABLE
                27. BLOG ELEMENTS
============================================================*/
#site-wrapper {
  /* ============================================================ 
	    1. IMAGE
	=============================================================*/
  /* ============================================================ 
	    2. SEPARATOR
	=============================================================*/
  /* ============================================================ 
	    3. ACCORDION
	=============================================================*/
  /* ============================================================ 
	    4. GELLERY
	=============================================================*/
  /* ============================================================ 
	    5. CALL TO ACTION
	=============================================================*/
  /* ============================================================ 
	    6. CAROUSEL
	=============================================================*/
  /* ============================================================ 
	    7. GMAP
	=============================================================*/
  /* ============================================================ 
	    8. TABS
	=============================================================*/
  /* ============================================================ 
	    9. TOUR
	=============================================================*/
  /* ============================================================ 
	    10. BUTTON
	=============================================================*/
  /* ============================================================ 
	    11. PROGRESS BAR
	=============================================================*/
  /* ============================================================ 
	    12. CONTENT GRID
	=============================================================*/
  /* ============================================================ 
	    13. ICON BOX
	=============================================================*/
  /* ============================================================ 
	    14. TESTIMONIAL
	=============================================================*/
  /* ============================================================ 
	    15. MEMBER
	=============================================================*/
  /* ============================================================ 
	    16. IMAGE BOX
	=============================================================*/
  /* ============================================================ 
	    17. ICON LIST
	=============================================================*/
  /* ============================================================ 
	    18. COUNTER
	=============================================================*/
  /* ============================================================ 
	    19. OPENHOURS
	=============================================================*/
  /* ============================================================ 
	    20. COUNTDOWN
	=============================================================*/
  /* ============================================================ 
	    21. HEADING
	=============================================================*/
  /* ============================================================ 
	    22. FLEX SLIDER
	=============================================================*/
  /* ============================================================ 
	    23. CAROUSEL
	=============================================================*/
  /* ============================================================ 
	    24. TOGGLE
	=============================================================*/
  /* ============================================================ 
	    25. DROPCAP
	=============================================================*/
  /* ============================================================ 
	    26. PRICING TABLE
	=============================================================*/
  /* ============================================================ 
	    27. BLOG ELEMENTS
	=============================================================*/
}
#site-wrapper .vc_column_container:after {
  clear: both;
  content: "";
  display: table;
}
#site-wrapper .wpb_row {
  margin-bottom: 0;
}
#site-wrapper .wpb_content_element,
#site-wrapper ul.wpb_thumbnails-fluid > li,
#site-wrapper .last_toggle_el_margin,
#site-wrapper .wpb_button {
  margin-bottom: 0;
}
#site-wrapper .wpb_single_image .vc_single_image-wrapper {
  display: block;
}
#site-wrapper .wpb_single_image .vc_single_image-wrapper .vc_box_rounded {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
#site-wrapper .wpb_single_image .vc_single_image-wrapper .vc_box_rounded img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
#site-wrapper .wpb_single_image .vc_single_image-wrapper.vc_box_outline img,
#site-wrapper .wpb_single_image .vc_single_image-wrapper.vc_box_outline_circle img {
  border: none;
}
#site-wrapper .wpb_single_image .vc_figure {
  display: block;
  margin: 0;
  position: relative;
}
#site-wrapper .wpb_single_image .vc_figure:hover .vc_figure-caption {
  bottom: 110%;
  visibility: visible;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
#site-wrapper .wpb_single_image .vc_figure .vc_figure-caption {
  margin: 0;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 7px 25px;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  color: #ffffff;
  visibility: hidden;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
#site-wrapper .wpb_single_image .vc_figure .vc_figure-caption:after {
  border-top: 6px solid rgba(0, 0, 0, 0.8);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  left: 50%;
  margin-left: -6px;
  top: 100%;
}
#site-wrapper .wpb_single_image .vc_figure a {
  display: block;
}
#site-wrapper .wpb_single_image .vc_figure a .vc_single_image-wrapper {
  margin: 0;
}
#site-wrapper .vc_box_shadow_3d_wrap:after,
#site-wrapper .vc_box_shadow_3d_wrap:before {
  bottom: 30px;
}
#site-wrapper .vc_box_shadow_circle,
#site-wrapper .vc_box_shadow_border_circle {
  background: #ffffff;
}
#site-wrapper .vc_separator.vc_sep_double .vc_sep_line {
  height: 6px;
}
#site-wrapper .vc_tta-container {
  margin-bottom: 0;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel-heading {
  background: none;
  border: none;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel-heading .vc_tta-panel-title {
  font-size: 14px;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel-heading .vc_tta-panel-title a {
  padding: 15px 0 15px 20px;
  color: inherit;
  line-height: 1.4;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel-heading .vc_tta-panel-title a .vc_tta-icon {
  font-size: 28px;
  width: 50px;
  text-align: inherit;
  float: left;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel-heading .vc_tta-panel-title a .vc_tta-title-text {
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel-heading .vc_tta-panel-title a i {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  right: 15px;
  left: auto;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel-heading .vc_tta-panel-title a i.vc_tta-controls-icon-plus:before {
  left: 5px;
  right: 5px;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel-heading .vc_tta-panel-title a i.vc_tta-controls-icon-plus:after {
  top: 5px;
  bottom: 5px;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel-body {
  background: #f1f2f8;
  border: none;
  padding: 25px 30px;
  margin: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel {
  margin-bottom: 10px;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel .vc_tta-panel-heading {
  background-color: rgba(228, 230, 242, 0.5);
  margin: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-heading {
  color: #ffffff;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-heading .vc_tta-panel-title a {
  color: #ffffff;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-heading .vc_tta-panel-title a i.vc_tta-controls-icon-plus:after,
#site-wrapper .vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-heading .vc_tta-panel-title a i.vc_tta-controls-icon-plus:before {
  border-color: #ffffff;
}
#site-wrapper .vc_tta-accordion .vc_tta-panel:last-child {
  margin-bottom: 0;
}
#site-wrapper .wpb_gallery .flexslider {
  padding: 0;
  margin-bottom: 0;
  border: none;
  overflow: hidden;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
#site-wrapper .wpb_gallery .flexslider:hover .flex-control-nav {
  bottom: 0;
}
#site-wrapper .wpb_gallery .flexslider .flex-control-nav {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
#site-wrapper .wpb_gallery .wpb_slider_nivo {
  position: relative;
}
#site-wrapper .wpb_gallery .wpb_slider_nivo .nivoSlider {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
#site-wrapper .wpb_gallery .wpb_slider_nivo .nivo-controlNav {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  bottom: 5px;
  z-index: 99;
}
#site-wrapper .wpb_gallery .wpb_image_grid {
  margin: 0 -15px;
}
#site-wrapper .wpb_gallery .wpb_image_grid .wpb_image_grid_ul .isotope-item img {
  max-width: 100%;
}
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item {
  padding: 0 15px;
  margin: 0 0 30px 0;
  width: 25%;
}
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item a {
  position: relative;
  overflow: hidden;
  display: block;
  background: #000000;
}
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item a:after,
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item a:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 30px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  z-index: 9;
  margin-top: -15px;
  margin-left: -1px;
  visibility: hidden;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item a:before {
  height: 1px;
  width: 30px;
  margin-top: -1px;
  margin-left: -15px;
}
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item a:hover img {
  -webkit-opacity: 0.5;
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
}
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item a:hover:after,
#site-wrapper .wpb_gallery .wpb_image_grid .isotope-item a:hover:before {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  visibility: visible;
}
#site-wrapper .wpb_gallery.wpb_posts_slider .slides li {
  position: relative;
}
#site-wrapper .wpb_gallery.wpb_posts_slider .slides li .flex-caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: #ffffff;
  width: 50%;
  padding: 35px;
}
#site-wrapper .wpb_gallery.wpb_posts_slider .slides li .flex-caption .post-title {
  font-size: 18px;
  text-transform: uppercase;
  position: relative;
}
#site-wrapper .wpb_gallery.wpb_posts_slider .slides li .flex-caption .post-title:after {
  width: 25px;
  height: 2px;
  background: #333333;
  content: "";
  position: absolute;
  left: 0;
  bottom: -8px;
}
#site-wrapper .wpb_gallery.wpb_posts_slider .post-title {
  font-size: 1em;
  line-height: 1.4;
  margin-bottom: 20px;
  margin-top: 0;
}
#site-wrapper .wpb_gallery.wpb_posts_slider .post-title a {
  color: #333333;
}
#site-wrapper .wpb_gallery.wpb_posts_slider .flexslider .flex-direction-nav li a {
  bottom: 20px;
  right: 20px;
  top: auto;
  margin: 0;
}
#site-wrapper .wpb_gallery.wpb_posts_slider .flexslider .flex-direction-nav li a.flex-prev {
  left: auto;
  right: 62px;
}
#site-wrapper .wpb_gallery.wpb_posts_slider .flexslider .flex-control-nav {
  top: 20px;
  right: 20px;
  left: auto;
  bottom: auto;
  text-align: right;
}
#site-wrapper .wpb_gallery.wpb_posts_slider .flexslider .flex-control-nav li a {
  text-indent: 9999px;
}
#site-wrapper .vc_cta3 {
  padding: 30px;
  border: none;
}
#site-wrapper .vc_cta3.vc_cta3-style-flat {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
#site-wrapper .vc_cta3.vc_cta3-shape-rounded {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}
#site-wrapper .vc_cta3 .vc_cta3-content-header h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
#site-wrapper .vc_cta3.vc_cta3-icons-left .vc_cta3_content-container {
  padding-left: 30px;
}
#site-wrapper .vc_cta3.vc_cta3-icons-right .vc_cta3_content-container {
  padding-right: 30px;
}
#site-wrapper .vc_cta3 .vc_cta3-content-header h2 {
  position: relative;
  margin-bottom: 40px;
}
#site-wrapper .vc_cta3 .vc_cta3-content-header h2:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 3px;
  background: #333;
  left: 0;
  bottom: -15px;
}
#site-wrapper .vc_cta3 .vc_cta3-actions {
  margin-top: 30px;
}
#site-wrapper .wpb_images_carousel {
  max-width: 100%;
}
#site-wrapper .wpb_images_carousel .vc_item .vc_inner {
  margin: 0;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_item .vc_inner {
  border: 1px solid #e6e6e6;
  padding: 20px;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_item .vc_inner .post-thumb {
  margin: -21px -21px 25px -21px;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_item .vc_inner .post-title {
  font-size: 1em;
  line-height: 1.4;
  margin-top: 0;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_item .vc_inner .post-title a {
  color: #333333;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_item .vc_inner .entry-content {
  margin-bottom: 10px;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_item .vc_inner .vc_read_more {
  color: #b3b3b3;
  margin-top: 10px;
  font-size: 12px;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_item .vc_inner .vc_read_more:hover {
  border-bottom: 1px dotted #333333;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_carousel-indicators {
  bottom: -40px;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_carousel-indicators li {
  background: rgba(0, 0, 0, 0.4);
  border: none;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_carousel-control {
  left: 0;
}
#site-wrapper .vc_carousel.wpb_content_element .vc_carousel-control.vc_right {
  right: 0;
  left: auto;
}
#site-wrapper .vc_carousel-control {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 48px;
  height: 48px;
  margin-top: -24px;
  left: 10px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
#site-wrapper .vc_carousel-control:hover {
  border-color: rgba(0, 0, 0, 0.4);
}
#site-wrapper .vc_carousel-control.vc_right {
  right: 10px;
  left: auto;
}
#site-wrapper .vc_carousel-control span {
  background: none;
  width: 48px;
  height: 48px;
  position: static;
  margin: 6px 0 0 0;
  text-shadow: none;
}
#site-wrapper .wpb_gmaps_widget .wpb_wrapper {
  padding: 0;
}
#site-wrapper .google-maps img {
  max-width: none;
}
#site-wrapper .vc_tta-tabs .vc_tta-tabs-container {
  margin: 0;
  overflow: visible;
}
#site-wrapper .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list {
  margin: 0;
  overflow: inherit;
  padding: 0 5px;
}
#site-wrapper .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list li {
  margin: 0;
  position: relative;
}
#site-wrapper .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list li a {
  font-weight: bold;
  padding: 15px 25px;
  background: none;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  color: #ffffff;
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
#site-wrapper .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list li a:before {
  width: 100%;
  left: 0;
  bottom: 100%;
  height: 5px;
  content: "";
  position: absolute;
  top: auto;
  display: none;
}
#site-wrapper .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list li.vc_active a {
  background: #f1f2f8;
}
#site-wrapper .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list li.vc_active a:before {
  display: block;
}
#site-wrapper .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-tabs-container .vc_tta-tabs-list li:after {
  display: none;
}
#site-wrapper .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-tabs-container .vc_tta-tabs-list li a {
  margin-bottom: 20px;
  margin-right: 40px;
}
#site-wrapper .vc_tta-tabs .vc_tta-panels-container {
  background: #f1f2f8;
  padding: 25px 30px;
}
#site-wrapper .vc_tta-tabs .vc_tta-panels-container .vc_tta-panels {
  background: none;
  border: none;
}
#site-wrapper .vc_tta-tabs .vc_tta-panels-container .vc_tta-panels .vc_tta-panel {
  margin: 0;
}
#site-wrapper .vc_tta-tabs .vc_tta-panels-container .vc_tta-panels .vc_tta-panel .vc_tta-panel-body {
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
#site-wrapper .wpb_tour {
  background: #ffffff;
  padding: 30px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}
#site-wrapper .wpb_tour .wpb_tabs_nav {
  width: 25%;
  float: left;
  border-right: 1px solid rgba(54, 70, 115, 0.08);
}
#site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default {
  margin: 0;
  background: none;
}
#site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default a {
  padding: 15px 0;
  color: #333333;
  border-bottom: 1px solid rgba(54, 70, 115, 0.08);
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
}
#site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default.ui-tabs-active a:after {
  width: 2px;
  position: absolute;
  content: "";
  right: -1px;
  top: 0;
  bottom: 0;
  background: #ffffff;
}
#site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default:last-child a {
  border-bottom: none;
}
#site-wrapper .wpb_tour .wpb_tour_tabs_wrapper .wpb_tab {
  width: 75%;
  overflow: hidden;
  padding: 0 0 0 30px;
}
#site-wrapper .wpb_tour .wpb_tour_next_prev_nav {
  display: none;
}
#site-wrapper .vc_btn3-container {
  margin-bottom: 0;
}
#site-wrapper .vc_btn3 {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding-left: 30px;
  padding-right: 30px;
}
#site-wrapper .vc_btn3:hover {
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}
#site-wrapper .vc_btn3.vc_btn3-shape-rounded {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}
#site-wrapper .vc_btn3.vc_btn3-style-outline {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
#site-wrapper .vc_btn3.vc_btn3-style-3d {
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}
#site-wrapper .vc_btn3.vc_btn3-style-3d:hover,
#site-wrapper .vc_btn3.vc_btn3-style-3d:focus {
  top: 0;
}
#site-wrapper .vc_btn3.vc_btn3-style-custom {
  background-image: none;
}
#site-wrapper .vc_btn3.vc_btn3-style-custom:hover {
  color: #ffffff !important;
}
#site-wrapper .vc_btn3.vc_btn3-icon-left {
  padding-left: 55px;
}
#site-wrapper .vc_btn3.vc_btn3-icon-left i {
  left: 30px;
}
#site-wrapper .vc_btn3.vc_btn3-icon-right {
  padding-right: 55px;
}
#site-wrapper .vc_btn3.vc_btn3-icon-right i {
  right: 30px;
}
#site-wrapper .vc_progress_bar .vc_single_bar {
  background: rgba(0, 0, 0, 0.05);
  padding: 0;
  height: 40px;
  position: relative;
  margin-bottom: 30px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
#site-wrapper .vc_progress_bar .vc_single_bar:last-child {
  margin-bottom: 0;
}
#site-wrapper .vc_progress_bar .vc_label {
  padding: 0;
  top: 7px;
  left: 20px;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
#site-wrapper .vc_progress_bar .vc_label .vc_label_units {
  font-size: 1em;
}
#site-wrapper .vc_progress_bar .vc_label .vc_label_units:before {
  content: "";
  display: inline-block;
  margin: 0 8px;
  width: 5px;
  height: 2px;
  background: #ffffff;
}
#site-wrapper .vc_progress_bar .vc_bar {
  height: 40px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
#site-wrapper .wpb_teaser_grid .categories_filter {
  margin-bottom: 30px;
  font-size: 12px;
}
#site-wrapper .wpb_teaser_grid .categories_filter li {
  display: inline-block;
  margin: 0;
}
#site-wrapper .wpb_teaser_grid .categories_filter li:after {
  content: "/";
  padding: 0 15px;
  color: #b3b3b3;
  display: inline-block !important;
}
#site-wrapper .wpb_teaser_grid .categories_filter li:last-child:after {
  display: none !important;
}
#site-wrapper .wpb_teaser_grid .categories_filter li.active a,
#site-wrapper .wpb_teaser_grid .categories_filter li:hover a {
  color: #000000;
  border: none;
}
#site-wrapper .wpb_teaser_grid .categories_filter li a {
  color: #a6a6a6;
}
#site-wrapper .wpb_teaser_grid .wpb_thumbnails-fluid {
  margin-top: 0;
  margin-left: -15px;
  margin-right: -15px;
}
#site-wrapper .wpb_teaser_grid .isotope-item {
  padding: 0 15px;
}
#site-wrapper .wpb_teaser_grid .isotope-inner {
  border: 1px solid #e6e6e6;
  padding: 20px;
  word-wrap: break-word;
  background: #ffffff;
}
#site-wrapper .wpb_teaser_grid .post-thumb {
  margin: -21px -21px 25px -21px;
}
#site-wrapper .wpb_teaser_grid .post-title {
  font-size: 1em;
  line-height: 1.4;
  margin-top: 0;
}
#site-wrapper .wpb_teaser_grid .post-title a {
  color: #333333;
}
#site-wrapper .wpb_teaser_grid .entry-content {
  margin-bottom: 10px;
}
#site-wrapper .wpb_teaser_grid .vc_read_more {
  color: #b3b3b3;
  margin-top: 10px;
  font-size: 12px;
}
#site-wrapper .wpb_teaser_grid .vc_read_more:hover {
  border-bottom: 1px dotted #333333;
}
#site-wrapper .iconbox {
  background: #ffffff;
  padding: 40px 40px 30px 40px;
  position: relative;
}
#site-wrapper .iconbox:hover {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}
#site-wrapper .iconbox .box-header {
  margin-bottom: 20px;
}
#site-wrapper .iconbox .box-header .box-icon i {
  font-size: 28px;
  position: absolute;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  top: 0;
  left: 40px;
}
#site-wrapper .iconbox .box-header .box-icon i.icons {
  font-size: 32px;
}
#site-wrapper .iconbox .box-header .box-icon img {
  margin-bottom: -15px;
}
#site-wrapper .iconbox .box-header .box-title {
  margin: 45px 0 0 0;
  text-transform: uppercase;
}
#site-wrapper .iconbox .box-readmore {
  margin-top: 20px;
}
#site-wrapper .iconbox .box-readmore a {
  display: inline-block;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;
}
#site-wrapper .iconbox .box-readmore a:after {
  content: "\f054";
  font-family: "fontAwesome";
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px;
  display: inline-block;
}
#site-wrapper .iconbox .box-readmore a:before {
  content: "";
  position: absolute;
  height: 5px;
  left: 0;
  bottom: -30px;
  width: 0;
}
#site-wrapper .iconbox .box-readmore a:hover:before {
  width: 100%;
}
#site-wrapper .iconbox:hover .box-header .box-icon i:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#site-wrapper .testimonial:after {
  clear: both;
  content: "";
  display: table;
}
#site-wrapper .testimonial .testimonial-image {
  float: left;
  width: 50%;
  padding-right: 15px;
  position: relative;
}
#site-wrapper .testimonial .testimonial-image:after {
  width: 40px;
  height: 40px;
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
}
#site-wrapper .testimonial .testimonial-image:before {
  content: "''";
  position: absolute;
  top: -12px;
  left: 25px;
  color: #ffffff;
  font-size: 49px;
  font-weight: 100;
  font-style: italic;
  z-index: 1;
}
#site-wrapper .testimonial .testimonial-content {
  float: right;
  width: 50%;
  padding-left: 15px;
}
#site-wrapper .testimonial .testimonial-content blockquote {
  margin: 0;
  padding: 0;
  position: relative;
  color: inherit;
  border: none;
}
#site-wrapper .testimonial .testimonial-meta strong {
  margin-right: 3px;
}
#site-wrapper .testimonial .testimonial-author {
  margin-bottom: 10px;
}
#site-wrapper .testimonial .testimonial-author .author-info {
  font-weight: bold;
  display: inline;
}
#site-wrapper .testimonial .testimonial-author .author-name {
  text-transform: uppercase;
}
#site-wrapper .testimonial:not(.has-image) {
  background: #f1f2f8;
  padding: 60px 30px 30px 30px;
  position: relative;
}
#site-wrapper .testimonial:not(.has-image):after {
  content: "";
  position: absolute;
  background: #f1f2f8;
  width: 20px;
  height: 20px;
  bottom: -5px;
  left: 30px;
  -webkit-transform: skewY(-45deg);
  -ms-transform: skewY(-45deg);
  -o-transform: skewY(-45deg);
  transform: skewY(-45deg);
}
#site-wrapper .testimonial:not(.has-image) .testimonial-content {
  float: none;
  padding: 0;
  width: 100%;
  position: relative;
}
#site-wrapper .testimonial:not(.has-image) .testimonial-content:after {
  width: 40px;
  height: 40px;
  content: "";
  position: absolute;
  top: -60px;
  left: 0;
}
#site-wrapper .testimonial:not(.has-image) .testimonial-content:before {
  content: "''";
  position: absolute;
  top: -72px;
  left: 5px;
  color: #ffffff;
  font-size: 49px;
  font-weight: 100;
  font-style: italic;
  z-index: 1;
}
#site-wrapper .testimonial:not(.has-image) .testimonial-content .testimonial-author {
  border-bottom: 1px solid rgba(54, 70, 115, 0.08);
  margin-bottom: 20px;
  padding-bottom: 15px;
}
#site-wrapper .member:after {
  clear: both;
  content: "";
  display: table;
}
#site-wrapper .member .member-image {
  float: left;
  padding-right: 20px;
  width: 30%;
}
#site-wrapper .member .member-info {
  float: right;
  padding-left: 20px;
  width: 70%;
  margin-top: -5px;
}
#site-wrapper .member .member-info .member-name {
  font-size: 16px;
  display: inline-block;
  margin: 0 10px 0 0;
}
#site-wrapper .member .member-info .member-subtitle {
  display: inline-block;
  font-weight: bold;
}
#site-wrapper .member .member-info .member-desc {
  margin: 10px 0 20px 0;
}
#site-wrapper .member .member-info .social-links {
  overflow: hidden;
}
#site-wrapper .member .member-info .social-links a {
  float: left;
  margin: 0 10px 0 0;
}
#site-wrapper .member .member-info .social-links a i {
  border-color: transparent;
}
#site-wrapper .imagebox {
  position: relative;
}
#site-wrapper .imagebox .box-wrapper {
  overflow: hidden;
  position: relative;
}
#site-wrapper .imagebox .box-header {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  padding: 10px 80px 10px 20px;
  min-height: 65px;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
#site-wrapper .imagebox .box-header:after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  -webkit-opacity: 0.85;
  -khtml-opacity: 0.85;
  -moz-opacity: 0.85;
  opacity: 0.85;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=85);
  filter: alpha(opacity=85);
}
#site-wrapper .imagebox .box-header:before {
  content: "\f054";
  position: absolute;
  font-family: "fontAwesome";
  text-align: center;
  line-height: 55px;
  right: 5px;
  top: 5px;
  bottom: 5px;
  width: 55px;
  height: 55px;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: normal;
  font-size: 18px;
}
#site-wrapper .imagebox .box-header .box-title {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.4;
  margin: 0;
  position: relative;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
#site-wrapper .imagebox .box-header .box-title:after {
  width: 50px;
  height: 3px;
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
}
#site-wrapper .imagebox .box-header .box-title a {
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
#site-wrapper .imagebox .box-header .box-subtitle {
  margin: 5px 0 0 0;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  position: relative;
}
#site-wrapper .imagebox .box-header .box-subtitle:before {
  font-family: "FontAwesome";
  content: "\f0a9";
  display: inline-block;
  margin: 0 6px 0 0;
}
#site-wrapper .imagebox .box-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  padding: 30px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
#site-wrapper .imagebox .box-content:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-opacity: 0.9;
  -khtml-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=90);
  filter: alpha(opacity=90);
}
#site-wrapper .imagebox .box-content .box-button {
  margin-top: 30px;
}
#site-wrapper .imagebox .box-content .box-button a {
  color: #ffffff;
  font-weight: bold;
  padding: 10px 40px 10px 20px;
  text-transform: uppercase;
  position: relative;
}
#site-wrapper .imagebox .box-content .box-button a:after {
  content: "\f054";
  font-family: "FontAwesome";
  position: absolute;
  top: 9px;
  right: 20px;
  font-weight: normal;
  font-size: 14px;
}
#site-wrapper .imagebox .box-content .box-button a:hover {
  background: #ffffff;
  text-shadow: none;
}
#site-wrapper .imagebox:hover .box-header {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}
#site-wrapper .imagebox:hover .box-content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
#site-wrapper .iconlist {
  margin: 0;
  padding: 0;
}
#site-wrapper .iconlist li {
  list-style: none;
  margin-bottom: 10px;
}
#site-wrapper .iconlist li i,
#site-wrapper .iconlist li img {
  margin-right: 5px;
  vertical-align: middle;
  text-align: center;
}
#site-wrapper .iconlist li i {
  width: 20px;
}
#site-wrapper .iconlist li.circle {
  margin-bottom: 10px;
}
#site-wrapper .iconlist li.circle i {
  background: rgba(0, 0, 0, 0.05);
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  text-shadow: 0 1px 0 #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
  -ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
  -o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
}
#site-wrapper .iconlist li.circle:hover i {
  background-color: #333333;
  text-shadow: none;
  color: #ffffff;
}
#site-wrapper .counter {
  background: #f1f2f8;
  padding: 0 20px 20px 20px;
  text-align: center;
}
#site-wrapper .counter .counter-image {
  margin-bottom: 20px;
}
#site-wrapper .counter .counter-image i {
  font-size: 28px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
#site-wrapper .counter .counter-content {
  display: inline-block;
  margin-bottom: 5px;
}
#site-wrapper .counter .counter-content .counter-value {
  font-size: 30px;
  position: relative;
  line-height: 1;
}
#site-wrapper .counter .counter-title {
  clear: both;
  width: 100%;
}
#site-wrapper .vc_custom_heading h1,
#site-wrapper .vc_custom_heading h2,
#site-wrapper .vc_custom_heading h3,
#site-wrapper .vc_custom_heading h4,
#site-wrapper .vc_custom_heading h5,
#site-wrapper .vc_custom_heading h6 {
  margin: 0;
}
#site-wrapper .wpb_gallery .wpb_flexslider .flex-direction-nav a:before {
  display: none;
}
#site-wrapper .flexslider {
  position: relative;
  overflow: hidden;
}
#site-wrapper .flexslider .slides {
  margin: 0;
  padding: 0;
}
#site-wrapper .flexslider .slides li {
  list-style: none;
}
#site-wrapper .flexslider .flex-control-nav {
  margin: 0;
  padding: 0;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
}
#site-wrapper .flexslider .flex-control-nav li {
  list-style: none;
  display: inline-block;
  margin: 0 2px;
}
#site-wrapper .flexslider .flex-control-nav li a {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border: 2px solid transparent;
  display: block;
  text-indent: -9999px;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
#site-wrapper .flexslider .flex-control-nav li a.flex-active {
  background: none;
  border-color: #ffffff;
}
#site-wrapper .flexslider .flex-direction-nav {
  margin: 0;
  padding: 0;
}
#site-wrapper .flexslider .flex-direction-nav li {
  list-style: none;
}
#site-wrapper .flexslider .flex-direction-nav li a {
  position: absolute;
  width: 50px;
  height: 50px;
  background: none;
  color: #ffffff;
  bottom: 20px;
  right: 20px;
  text-indent: -9999px;
  z-index: 98;
  text-align: inherit;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
#site-wrapper .flexslider .flex-direction-nav li a.flex-prev {
  right: 80px;
}
#site-wrapper .flexslider .flex-direction-nav li a.flex-prev:after {
  content: "\f104";
}
#site-wrapper .flexslider .flex-direction-nav li a:after {
  content: "\f105";
  position: absolute;
  text-indent: 0;
  right: 0;
  top: 0;
  font-family: 'FontAwesome';
  font-size: 28px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
#site-wrapper .elements-carousel {
  margin: 0 -15px;
}
#site-wrapper .elements-carousel .owl-item {
  padding: 0 15px;
}
#site-wrapper .owl-controls .owl-buttons > div {
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  position: absolute;
  top: -107px;
  right: 54px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
#site-wrapper .owl-controls .owl-buttons > div:after {
  position: absolute;
  content: "\f104";
  font-family: 'FontAwesome';
  text-indent: 0;
  left: 14px;
  top: 50%;
  margin-top: -26px;
  font-size: 28px;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
#site-wrapper .owl-controls .owl-buttons > div.owl-next {
  right: 15px;
}
#site-wrapper .owl-controls .owl-buttons > div.owl-next:after {
  position: absolute;
  content: "\f105";
  font-family: 'FontAwesome';
  text-indent: 0;
  left: auto;
  right: 14px;
  font-size: 28px;
}
#site-wrapper .owl-controls .owl-pagination {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-top: 35px;
}
#site-wrapper .owl-controls .owl-pagination .owl-page {
  display: inline-block;
  margin: 0 2px;
  padding: 2px;
  width: 14px;
  height: 14px;
}
#site-wrapper .owl-controls .owl-pagination .owl-page span {
  width: 10px;
  height: 10px;
  display: inline-block;
  text-indent: -99999px;
  margin: 2px;
  vertical-align: middle;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
#site-wrapper .owl-controls .owl-pagination .owl-page.active span {
  width: 14px;
  height: 14px;
  position: relative;
  margin: 0;
}
#site-wrapper .vc_toggle {
  margin: 0 0 30px 0;
  padding: 20px;
  background: #f1f2f8;
}
#site-wrapper .vc_toggle:last-child {
  margin-bottom: 0;
}
#site-wrapper .vc_toggle .vc_toggle_title {
  padding: 0;
}
#site-wrapper .vc_toggle .vc_toggle_title h4 {
  margin: 0;
  position: relative;
  font-size: 14px;
  display: block;
  padding: 8px 0 7px 50px;
}
#site-wrapper .vc_toggle .vc_toggle_title h4:after {
  font-family: "FontAwesome";
  position: absolute;
  font-size: 14px;
  content: "\f078";
  left: 0;
  top: 50%;
  margin-top: -16px;
  font-weight: normal;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  color: #ffffff;
}
#site-wrapper .vc_toggle .vc_toggle_title .vc_toggle_icon {
  display: none;
}
#site-wrapper .vc_toggle .vc_toggle_content {
  margin: 0;
  padding: 10px 0 0 50px;
}
#site-wrapper .vc_toggle.vc_toggle_active .vc_toggle_title h4:after {
  content: "\f077";
}
#site-wrapper .pricing-table:after {
  clear: both;
  content: "";
  display: table;
}
#site-wrapper .pricing-table.one-column .price-column {
  width: 100%;
}
#site-wrapper .pricing-table.two-columns .price-column {
  width: 50%;
}
#site-wrapper .pricing-table.three-columns .price-column {
  width: 33.3333333%;
}
#site-wrapper .pricing-table.four-columns .price-column {
  width: 25%;
}
#site-wrapper .pricing-table.five-columns .price-column {
  width: 20%;
}
#site-wrapper .pricing-table.six-columns .price-column {
  width: 16.6666666%;
}
#site-wrapper .pricing-table .price-column {
  float: left;
  vertical-align: top;
  white-space: normal;
}
#site-wrapper .pricing-table .price-column:last-child .column-container {
  border-right: 2px solid rgba(54, 70, 115, 0.08);
}
#site-wrapper .pricing-table .price-column .column-container {
  text-align: center;
  border: 2px solid rgba(54, 70, 115, 0.08);
  border-right: none;
  background: #ffffff;
}
#site-wrapper .pricing-table .price-column .column-container .plan {
  color: #333333;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  padding-top: 45px;
}
#site-wrapper .pricing-table .price-column .column-container .price {
  line-height: 1;
}
#site-wrapper .pricing-table .price-column .column-container .price .symbol {
  font-size: 24px;
  color: #333333;
  position: relative;
  top: -15px;
}
#site-wrapper .pricing-table .price-column .column-container .price .price {
  font-weight: bold;
  font-size: 72px;
}
#site-wrapper .pricing-table .price-column .column-container .price div {
  font-size: 18px;
}
#site-wrapper .pricing-table .price-column .column-container .features {
  margin: 0;
  padding: 0;
  position: relative;
}
#site-wrapper .pricing-table .price-column .column-container .features li {
  list-style: none;
  margin-bottom: 5px;
  position: relative;
}
#site-wrapper .pricing-table .price-column .column-container .features li:before {
  content: "\f00c";
  margin-right: 8px;
  font-family: "FontAwesome";
  font-size: 14px;
}
#site-wrapper .pricing-table .price-column .column-container .features li.spacer {
  border: none;
}
#site-wrapper .pricing-table .price-column .column-container .features li.divider {
  margin: -21px -30px 0 -30px;
  border-color: #e6e6e6;
  border-width: 2px;
}
#site-wrapper .pricing-table .price-column .column-container .features li:last-child {
  border: none;
}
#site-wrapper .pricing-table .price-column .column-container .features li:first-child {
  position: relative;
  margin-top: 80px;
}
#site-wrapper .pricing-table .price-column .column-container .features li:first-child:after {
  content: "";
  position: absolute;
  height: 2px;
  background: rgba(54, 70, 115, 0.08);
  left: 30px;
  right: 30px;
  top: -40px;
}
#site-wrapper .pricing-table .price-column .column-container .features > li.spacer {
  display: none;
}
#site-wrapper .pricing-table .price-column .column-container .cta {
  display: block;
  padding: 30px 0 40px 0;
}
#site-wrapper .pricing-table .price-column.highlight {
  position: relative;
}
#site-wrapper .pricing-table .price-column.highlight + .price-column .column-container {
  border-left: none;
}
#site-wrapper .pricing-table .price-column.highlight .column-container {
  border: 2px solid;
}
#site-wrapper .pricing-table .price-column.highlight .column-container .price,
#site-wrapper .pricing-table .price-column.highlight .column-container .plan {
  color: #ffffff;
}
#site-wrapper .pricing-table .price-column.highlight .column-container .price .symbol,
#site-wrapper .pricing-table .price-column.highlight .column-container .plan .symbol,
#site-wrapper .pricing-table .price-column.highlight .column-container .price .price,
#site-wrapper .pricing-table .price-column.highlight .column-container .plan .price {
  color: #ffffff;
  background: none;
  padding: 0;
}
#site-wrapper .pricing-table .price-column.highlight .column-container .price {
  padding-bottom: 42px;
}
#site-wrapper .pricing-table .price-column.highlight .column-container .features li:first-child {
  margin-top: 38px;
}
#site-wrapper .pricing-table .price-column.highlight .column-container .features li:first-child:after {
  display: none;
}
#site-wrapper .pricing-table .price-column.highlight .popular {
  display: none;
}
#site-wrapper .blog-shortcode.blog-grid .blog-entries {
  margin: 0 -15px;
}
#site-wrapper .blog-shortcode.blog-grid .blog-entries:after {
  clear: both;
  content: "";
  display: table;
}
#site-wrapper .blog-shortcode.blog-grid .hentry {
  float: left;
  padding: 0 15px;
  margin-bottom: 30px;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-wrapper:after {
  clear: both;
  content: "";
  display: table;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-wrapper:hover {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-wrapper:hover .entry-content-wrap {
  background: #ffffff;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-wrapper:hover .entry-content-wrap:after {
  width: calc(100% - 60px);
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover {
  position: relative;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover .entry-time {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 0;
  left: 30px;
  color: #ffffff;
  z-index: 9;
  margin: 0;
  text-align: center;
  font-size: 18px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover .entry-time span {
  display: block;
  text-transform: uppercase;
  line-height: 1;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover .entry-time span.entry-day {
  font-size: 30px;
  margin-top: 4px;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover a {
  display: block;
  position: relative;
  background: #000000;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover a:after,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover a:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 30px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  z-index: 9;
  margin-top: -15px;
  margin-left: -1px;
  visibility: hidden;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover a:before {
  height: 1px;
  width: 30px;
  margin-top: -1px;
  margin-left: -15px;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover a:hover img {
  -webkit-opacity: 0.4;
  -khtml-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=40);
  filter: alpha(opacity=40);
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover a:hover:after,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover a:hover:before {
  visibility: visible;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-content-wrap {
  padding: 25px 30px 25px 30px;
  background: #f2f4f8;
  position: relative;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-content-wrap:after {
  width: 60px;
  height: 5px;
  content: "";
  top: 0;
  left: 30px;
  position: absolute;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-header {
  margin-bottom: 15px;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-header .entry-title {
  font-size: 20px;
  margin: 0 ;
  line-height: 1.3;
  text-transform: uppercase;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-content .read-more {
  display: inline;
  font-weight: bold;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-footer {
  margin-top: 15px;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-footer .entry-meta {
  color: #b9c1cf;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-footer .entry-meta a {
  color: #b9c1cf;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-footer .entry-meta i {
  display: none;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-footer .entry-meta .entry-comments-link {
  display: none;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-footer .entry-meta .entry-author {
  margin-right: 5px;
}
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-footer .entry-meta .entry-author:after {
  content: "/";
  display: inline-block;
  margin-left: 5px;
  color: #b9c1cf;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry {
  width: 100%;
  float: none;
  margin-bottom: 30px;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry .entry-wrapper:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry .entry-wrapper:hover .entry-content-wrap {
  background: none;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry .entry-wrapper:after {
  clear: both;
  content: "";
  display: table;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry .entry-wrapper .entry-cover {
  float: left;
  width: 33%;
  padding-right: 10px;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry .entry-wrapper .entry-cover .entry-time {
  display: none;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry .entry-wrapper .entry-content-wrap {
  float: right;
  background: none;
  width: 67%;
  padding: 0;
  padding-left: 10px;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry .entry-wrapper .entry-content-wrap:after {
  display: none;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry .entry-wrapper .entry-content-wrap .entry-header {
  margin-bottom: 5px;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry .entry-wrapper .entry-content-wrap .entry-header .entry-title {
  font-size: 16px;
}
#site-wrapper .blog-shortcode.blog-grid.blog-one-column .hentry .entry-wrapper .entry-content-wrap .entry-footer {
  margin-top: 0;
}
#site-wrapper .blog-shortcode.blog-grid.blog-two-columns .hentry {
  width: 50%;
}
#site-wrapper .blog-shortcode.blog-grid.blog-two-columns .hentry:nth-of-type(2n+1) {
  clear: left;
}
#site-wrapper .blog-shortcode.blog-grid.blog-three-columns .hentry {
  width: 33.3333333%;
}
#site-wrapper .blog-shortcode.blog-grid.blog-three-columns .hentry:nth-of-type(3n+1) {
  clear: left;
}
#site-wrapper .blog-shortcode.blog-grid.blog-four-columns .hentry {
  width: 25%;
}
#site-wrapper .blog-shortcode.blog-grid.blog-four-columns .hentry:nth-of-type(4n+1) {
  clear: left;
}
#site-wrapper .posts-carousel .hentry {
  margin-bottom: 0;
}
#site-wrapper .posts-carousel .hentry .entry-wrapper {
  position: relative;
}
#site-wrapper .posts-carousel .hentry .entry-cover a {
  display: block;
  position: relative;
  background: #000000;
}
#site-wrapper .posts-carousel .hentry .entry-cover a:hover img {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
#site-wrapper .posts-carousel .hentry .entry-cover a:hover:after,
#site-wrapper .posts-carousel .hentry .entry-cover a:hover:before {
  visibility: visible;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
#site-wrapper .posts-carousel .hentry .entry-cover a img {
  -webkit-opacity: 0.8;
  -khtml-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
  filter: alpha(opacity=80);
}
#site-wrapper .posts-carousel .hentry .entry-header {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 30px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
#site-wrapper .posts-carousel .hentry .entry-header .entry-title {
  font-size: 20px;
  margin: 0 0 20px 0;
  line-height: 1.3;
}
#site-wrapper .posts-carousel .hentry .entry-header .entry-title a {
  color: #ffffff;
}
#site-wrapper .posts-carousel .hentry .entry-header .post-meta .entry-time {
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 5px 15px;
  text-transform: uppercase;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  #site-wrapper .rev_slider a.button {
    height: auto;
    line-height: 1.8;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #site-wrapper .rev_slider a.button i {
    display: none;
  }
  #site-wrapper .wpb_tour .wpb_tabs_nav {
    float: none;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid rgba(54, 70, 115, 0.08);
  }
  #site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default {
    clear: none;
    float: left;
    width: auto;
    margin: 0 30px -1px 0;
    background: none;
  }
  #site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default a {
    border: none;
  }
  #site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default.ui-tabs-active,
  #site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default:hover {
    background: none;
  }
  #site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default.ui-tabs-active a,
  #site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default:hover a {
    border-bottom: 2px solid;
  }
  #site-wrapper .wpb_tour .wpb_tour_tabs_wrapper .wpb_tab {
    padding: 30px 0 0 0;
    width: 100%;
  }
  #site-wrapper .vc_tta-tabs .vc_tta-panels-container .vc_tta-panels .vc_tta-panel .vc_tta-panel-heading {
    background: #ffffff;
    border: 1px solid rgba(54, 70, 115, 0.08);
    margin: 5px 0 0 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
  #site-wrapper .vc_tta-tabs .vc_tta-panels-container .vc_tta-panels .vc_tta-panel .vc_tta-panel-heading .vc_tta-panel-title {
    font-size: 16px;
  }
  #site-wrapper .vc_tta-tabs .vc_tta-panels-container .vc_tta-panels .vc_tta-panel .vc_tta-panel-heading a {
    color: inherit;
  }
  #site-wrapper .vc_tta-tabs .vc_tta-panels-container .vc_tta-panels .vc_tta-panel .vc_tta-panel-heading a i {
    line-height: 1.8;
    font-size: 14px;
  }
  #site-wrapper .vc_tta-tabs .vc_tta-panels-container .vc_tta-panels .vc_tta-panel .vc_tta-panel-body {
    border: none;
    background: none;
    padding: 25px 0 20px 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
}
@media only screen and (max-width: 568px) {
  #site-wrapper .testimonial .testimonial-image {
    float: none;
    width: 100%;
    margin-bottom: 30px;
    padding: 0;
  }
  #site-wrapper .testimonial .testimonial-content {
    float: none;
    width: 100%;
    padding: 0;
  }
  #site-wrapper .member .member-image {
    padding: 0;
    width: 100%;
    float: none;
    margin-bottom: 30px;
  }
  #site-wrapper .member .member-info {
    float: none;
    width: 100%;
    padding: 0;
  }
  #site-wrapper .history {
    padding-left: 15px;
  }
  #site-wrapper .history:before {
    left: 0;
  }
  #site-wrapper .history li {
    width: 100%;
    float: none;
    padding: 0;
    text-align: inherit;
    margin-bottom: 15px;
  }
  #site-wrapper .history li:last-child {
    margin-bottom: 0;
  }
  #site-wrapper .history li:after {
    left: -27px;
    right: auto;
  }
  #site-wrapper .history li:before {
    left: -18px;
    right: auto;
  }
}
#site-wrapper span.wpcf7-not-valid-tip {
  margin-top: 8px;
  font-size: 12px;
}
#site-wrapper div.wpcf7-response-output {
  padding: 20px 20px 20px 60px;
  margin-left: 0;
  margin-right: 0;
  position: relative;
}
#site-wrapper div.wpcf7-response-output.wpcf7-validation-errors {
  border-color: #e74c3c;
  color: #e74c3c;
}
#site-wrapper div.wpcf7-response-output.wpcf7-validation-errors:after {
  position: absolute;
  font-family: 'FontAwesome';
  left: 20px;
  top: 50%;
  margin-top: -24px;
  font-size: 28px;
  content: "\f071";
}
#site-wrapper div.wpcf7-response-output.wpcf7-mail-sent-ok:after {
  position: absolute;
  font-family: 'FontAwesome';
  left: 20px;
  top: 50%;
  margin-top: -24px;
  font-size: 28px;
  content: "\f058";
}
/*============================================================ 
                1. GENERAL
                2. LAYOUT
                3. WIDGET
                4. ELEMENTS
                5. BLOG
                6. OTHER
                7. WOOCOMMERCE
                8. PROJECTS
                9. RESPONSIVE
============================================================*/
.rtl {
  /* ============================================================ 
	    1. GENERAL
	=============================================================*/
  direction: rtl;
  unicode-bidi: embed;
  /* ============================================================ 
	    2. LAYOUT
	=============================================================*/
  /* ============================================================ 
	    3. WIDGET
	=============================================================*/
  /* ============================================================ 
	    4. ELEMENTS
	=============================================================*/
  /* ============================================================ 
	    5. BLOG
	=============================================================*/
  /* ============================================================ 
	    6. OTHER
	=============================================================*/
  /* ============================================================ 
	    7. WOOCOMMERCE
	=============================================================*/
  /* ============================================================ 
	    8. PROJECTS
	=============================================================*/
}
.rtl .alignleft {
  float: right;
  margin: 10px 0 10px 20px;
  clear: right;
}
.rtl .alignright {
  float: left;
  margin: 10px 20px 10px 0;
  clear: left;
}
.rtl table thead {
  text-align: right;
}
.rtl table tbody tr td,
.rtl table tbody tr th {
  text-align: right;
}
.rtl select {
  background-position: left center;
}
.rtl h1.title-divider:after,
.rtl h2.title-divider:after,
.rtl h3.title-divider:after,
.rtl h4.title-divider:after,
.rtl h5.title-divider:after,
.rtl h6.title-divider:after {
  left: auto;
  right: 0;
}
.rtl h1.title-divider[style="text-align: center;"]:after,
.rtl h2.title-divider[style="text-align: center;"]:after,
.rtl h3.title-divider[style="text-align: center;"]:after,
.rtl h4.title-divider[style="text-align: center;"]:after,
.rtl h5.title-divider[style="text-align: center;"]:after,
.rtl h6.title-divider[style="text-align: center;"]:after {
  left: auto;
  right: 50%;
  margin-left: 0;
  margin-right: -15px;
}
.rtl a.download i {
  margin-right: 0;
  margin-left: 15px;
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.rtl .goto-top a {
  text-indent: 0;
}
.rtl #site-header #headerbar .custom-info {
  float: right;
}
.rtl #site-header #headerbar .custom-info i {
  margin-left: 5px;
  margin-right: 0;
}
.rtl #site-header #headerbar .social-links {
  float: left;
  margin-left: 0;
  margin-right: 10px;
}
.rtl #site-header #headerbar .social-links a {
  margin-left: 0;
  margin-right: 10px;
}
.rtl #site-header #headerbar .top-navigator {
  float: left;
}
.rtl #site-header #headerbar .top-navigator .menu li {
  float: right;
}
.rtl #site-header #headerbar .top-navigator .menu li.menu-item-has-children {
  padding-left: 3px;
  padding-right: 8px;
}
.rtl #site-header #headerbar .top-navigator .menu li.menu-item-has-children:after {
  margin: 0 0 0 10px;
}
.rtl #site-header #headerbar .top-navigator .menu li .sub-menu {
  left: auto;
  right: 0;
}
.rtl #site-header #masthead #site-brand #site-logo {
  float: right;
}
.rtl #site-header #masthead #site-brand .header-widgets {
  text-align: left;
}
.rtl #site-header #masthead #site-brand .header-widgets .widget {
  margin-right: 60px;
  margin-left: 0;
  text-align: right;
}
.rtl #site-header #masthead #site-brand .header-widgets .widget .info-icon i {
  margin-right: 0;
  margin-left: 10px;
}
.rtl #site-header #masthead #site-brand .header-widgets .widget .button i {
  margin-left: 10px;
  margin-right: 0;
}
.rtl #site-header #masthead #site-navigator .menu li .sub-menu {
  left: auto;
  right: 0;
  text-align: right;
}
.rtl #site-header #masthead #site-navigator .menu li .sub-menu li .sub-menu {
  left: auto;
  right: 250px;
}
.rtl #site-content #page-breadcrumbs .breadcrumbs {
  left: auto;
  right: 15px;
}
.rtl #site-content #page-breadcrumbs .breadcrumbs .trail-browse {
  margin-left: 5px;
  margin-right: 0;
}
.rtl #site-content #page-breadcrumbs .breadcrumbs span {
  float: right;
}
.rtl #site-footer .social-links {
  float: left;
}
.rtl #site-footer .social-links a {
  margin-left: 0;
  margin-right: 10px;
}
.rtl #site-footer .copyright {
  float: right;
}
.rtl.header-v1 #site-header #masthead #site-navigator,
.rtl.header-v3 #site-header #masthead #site-navigator {
  float: left;
}
.rtl.header-v1 #site-header #masthead #site-navigator .menu.menu-extra .search-box > a,
.rtl.header-v3 #site-header #masthead #site-navigator .menu.menu-extra .search-box > a {
  margin-left: 0;
  margin-right: 20px;
}
.rtl.header-v1 #site-header #masthead #site-navigator .menu.menu-extra .shopping-cart .submenu,
.rtl.header-v3 #site-header #masthead #site-navigator .menu.menu-extra .shopping-cart .submenu {
  right: auto;
  left: 0;
}
.rtl.header-v1 #site-header #masthead #site-navigator .menu.menu-extra .shopping-cart a .shopping-cart-items-count,
.rtl.header-v3 #site-header #masthead #site-navigator .menu.menu-extra .shopping-cart a .shopping-cart-items-count {
  right: auto;
  left: 0;
}
.rtl.header-v3 #site-header #masthead #site-navigator #menu-main-menu > li > .sub-menu {
  -webkit-border-radius: 3px 0 3px 3px;
  -moz-border-radius: 3px 0 3px 3px;
  -ms-border-radius: 3px 0 3px 3px;
  -o-border-radius: 3px 0 3px 3px;
  border-radius: 3px 0 3px 3px;
}
.rtl .widgettitle:after,
.rtl .widget-title:after {
  left: auto;
  right: 0;
}
.rtl .widget .widget-title:after {
  left: auto;
  right: 0;
}
.rtl .widget.widget_search .search-form label:after {
  right: auto;
  left: 0;
}
.rtl .widget.widget_product_search .woocommerce-product-search::after {
  left: 0;
  right: auto;
}
.rtl .widget.widget_shopping_cart .cart_list li .remove {
  float: left;
}
.rtl .widget.widget_shopping_cart .cart_list li a img {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}
.rtl .sidebars .widget.widget_nav_menu .menu li a {
  padding: 10px 25px 10px 0;
}
.rtl .sidebars .widget.widget_nav_menu .menu li a:before {
  left: auto;
  right: 0;
}
.rtl .sidebars .widget.widget_nav_menu .menu li a:hover {
  padding-right: 30px;
}
.rtl .sidebars .widget.widget_nav_menu .menu li.current_page_item a,
.rtl .sidebars .widget.widget_nav_menu .menu li.current-menu-item a {
  padding: 10px 55px 10px 30px;
}
.rtl .sidebars .widget.widget_nav_menu .menu li.current_page_item a:before,
.rtl .sidebars .widget.widget_nav_menu .menu li.current-menu-item a:before {
  left: auto;
  right: 30px;
}
.rtl .sidebars .widget.widget_nav_menu .menu li .sub-menu {
  margin-left: 0;
  margin-right: 20px;
}
.rtl .sidebars .widget.widget_archive ul li .children,
.rtl .sidebars .widget.widget_categories ul li .children,
.rtl .sidebars .widget.widget_pages ul li .children,
.rtl .sidebars .widget.widget_meta ul li .children,
.rtl .sidebars .widget.widget_recent_comments ul li .children,
.rtl .sidebars .widget.widget_recent_entries ul li .children,
.rtl .sidebars .widget.widget_rss ul li .children {
  margin-left: 0;
  margin-right: 20px;
}
.rtl #site-wrapper .iconlist li i,
.rtl #site-wrapper .iconlist li img {
  margin-right: 0;
  margin-left: 10px;
}
.rtl #site-wrapper .iconlist li.circle {
  padding-left: 0;
  padding-right: 44px;
}
.rtl #site-wrapper .iconlist li.circle i {
  left: auto;
  right: 0;
}
.rtl #site-wrapper .iconlist li.circle:after {
  left: 0;
  right: 44px;
}
.rtl #site-wrapper .iconlist li.circle:first-child:before {
  left: 0;
  right: 44px;
}
.rtl #site-wrapper .iconlist li:after {
  left: 0;
  right: 30px;
}
.rtl #site-wrapper .iconlist li:first-child:before {
  left: 0;
  right: 30px;
}
.rtl #site-wrapper .iconbox .box-header .box-title:after {
  left: auto;
  right: 0;
}
.rtl #site-wrapper .iconbox.aligncenter .box-header .box-title:after {
  right: 50%;
  margin-right: -15px;
  margin-left: 0;
}
.rtl #site-wrapper .iconbox.aligncenter:hover .box-header .box-title:after {
  right: 0;
  left: 0;
  margin-right: 0;
}
.rtl #site-wrapper .wpb_tabs .wpb_tabs_nav li {
  float: right;
}
.rtl #site-wrapper .wpb_tabs .wpb_tabs_nav li a {
  margin-right: 0;
  margin-left: 30px;
}
.rtl #site-wrapper .wpb_accordion .wpb_accordion_header a {
  padding: 0 0 0 75px;
}
.rtl #site-wrapper .wpb_accordion .wpb_accordion_header .ui-accordion-header-icon {
  left: 0;
  right: auto;
}
.rtl #site-wrapper .wpb_accordion .wpb_accordion_header .ui-accordion-header-icon:before {
  left: 8px;
  right: auto;
}
.rtl #site-wrapper .wpb_accordion .wpb_accordion_header .ui-accordion-header-icon:after {
  right: auto;
  left: 0;
}
.rtl #site-wrapper .wpb_tour .wpb_tabs_nav {
  float: right;
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.rtl #site-wrapper .wpb_tour .wpb_tabs_nav li.ui-state-default.ui-tabs-active a::after {
  left: -1px;
  right: auto;
}
.rtl #site-wrapper .wpb_tour .wpb_tour_tabs_wrapper .wpb_tab {
  padding: 0 30px 0 0;
}
.rtl #site-wrapper .vc_progress_bar .vc_single_bar .vc_bar {
  right: 0;
  left: auto;
}
.rtl #site-wrapper .vc_progress_bar .vc_label .vc_label_units {
  float: left;
}
.rtl #site-wrapper .testimonial .testimonial-content blockquote:after {
  left: auto;
  right: -35px;
}
.rtl #site-wrapper .openhours .hours-table li .hours {
  left: 0;
  right: auto;
}
.rtl #site-wrapper .openhours .hours-table li .dayname .dayname-icon {
  margin-right: 0;
  margin-left: 10px;
}
.rtl #site-wrapper .flexslider {
  direction: ltr;
}
.rtl #site-wrapper .owl-carousel {
  direction: ltr;
}
.rtl #site-wrapper .owl-carousel .owl-item {
  direction: rtl;
}
.rtl #site-wrapper .owl-controls .owl-pagination {
  right: auto;
  left: 15px;
}
.rtl #site-wrapper .owl-controls .owl-pagination .owl-page span {
  margin: 0 10px 0 0;
}
.rtl.blog .hentry .entry-header .entry-title:after,
.rtl.single .hentry .entry-header .entry-title:after {
  left: auto;
  right: 0;
}
.rtl .blog-shortcode.blog-list .hentry .entry-cover {
  float: right;
}
.rtl .blog-shortcode.blog-list .hentry .entry-header,
.rtl .blog-shortcode.blog-list .hentry .entry-content {
  margin-left: 0;
  margin-right: 65px;
}
.rtl .box .box-title::after {
  left: auto;
  right: 0;
}
.rtl .box.author-box .author-avatar {
  float: right;
  margin-left: 30px;
  margin-right: 0;
}
.rtl #comments .comments-title:after,
.rtl #comments #reply-title:after {
  left: auto;
  right: 0;
}
.rtl #comments .comment-list li .comment-body .comment-meta .comment-author img {
  float: right;
  margin-right: 0;
  margin-left: 20px;
}
.rtl #comments .comment-list li .comment-body .comment-content,
.rtl #comments .comment-list li .comment-body .reply {
  padding-left: 0;
  padding-right: 54px;
}
.rtl #comments .comment-list li .children {
  padding-left: 30px;
  padding-right: 54px;
}
.rtl.search #main-content .content-inner .search-form .search-submit {
  left: 0;
  right: auto;
}
.rtl.search #main-content .content-inner .search-results article {
  padding: 30px 65px 30px 0;
}
.rtl.search #main-content .content-inner .search-results article .counter {
  left: auto;
  right: 0;
}
.rtl .wpcf7 {
  direction: rtl;
}
.rtl.woocommerce .products li .add_to_cart_button,
.rtl.woocommerce-page .products li .add_to_cart_button {
  right: auto;
  left: 15px;
}
.rtl.woocommerce .product .summary .cart .quantity,
.rtl.woocommerce-page .product .summary .cart .quantity {
  float: right;
  margin-right: 0;
  margin-left: 10px;
}
.rtl.woocommerce .product .woocommerce-tabs .tabs li,
.rtl.woocommerce-page .product .woocommerce-tabs .tabs li {
  float: right;
}
.rtl.woocommerce .product .woocommerce-tabs .tabs li a,
.rtl.woocommerce-page .product .woocommerce-tabs .tabs li a {
  margin-right: 0;
  margin-left: 30px;
}
.rtl.woocommerce #reviews #comments ol.commentlist li .star-rating,
.rtl.woocommerce-page #reviews #comments ol.commentlist li .star-rating {
  right: auto;
  left: 0;
}
.rtl.woocommerce #reviews #comments ol.commentlist li img.avatar,
.rtl.woocommerce-page #reviews #comments ol.commentlist li img.avatar {
  float: right;
}
.rtl.woocommerce #reviews #comments ol.commentlist li .comment-text,
.rtl.woocommerce-page #reviews #comments ol.commentlist li .comment-text {
  margin-left: 0;
  margin-right: 95px;
}
.rtl .projects .projects-items .hentry .project-wrap .project-info {
  padding: 50px 15px 10px 80px;
}
.rtl .projects .projects-items .hentry .project-wrap .project-thumbnail figcaption .project-buttons a {
  right: auto;
  left: 15px;
}
.rtl .project-single .project-gallery-grid .project-media-item {
  float: right;
}
/* ============================================================ 
    9. RESPONSIVE
=============================================================*/
@media only screen and (max-width: 991px) {
  body.rtl #site-header #headerbar .custom-info {
    float: none;
  }
  body.rtl #site-header #headerbar .social-links {
    float: none;
    margin-right: 0;
  }
  body.rtl #site-header #headerbar .top-navigator {
    float: none;
  }
  body.rtl #site-header #headerbar .top-navigator .menu li {
    float: none;
  }
  body.rtl #site-header #headerbar .top-navigator .menu li .sub-menu {
    text-align: right;
  }
  body.rtl #site-header #masthead #site-brand #site-logo {
    float: none;
  }
  body.rtl #site-header #masthead #site-brand .header-widgets {
    text-align: center;
  }
  body.rtl #site-header #masthead #site-brand .header-widgets .widget {
    margin: 0 30px 30px 30px;
  }
}
@media only screen and (max-width: 768px) {
  body.rtl #site-header #masthead #site-navigator .menu.menu-extra {
    right: auto;
    left: 0;
  }
  body.rtl #site-header #masthead #site-navigator-mobile.navigator-mobile .navigator-toggle {
    left: auto;
    right: 0;
  }
  body.rtl #site-header #masthead #site-navigator-mobile.navigator-mobile .menu li.menu-item-has-children .toggler {
    right: auto;
    left: 0;
    border-left: 0;
    border-right: 1px solid #e6e6e6;
  }
  body.rtl #site-header #masthead #site-navigator-mobile.navigator-mobile .menu li a {
    margin-right: 0;
    margin-left: 56px;
  }
  body.rtl #site-header #masthead #site-navigator-mobile.navigator-mobile .menu li .sub-menu {
    margin-left: 0;
    margin-right: 20px;
  }
  body.rtl #site-wrapper .wpb_tour .wpb_tabs_nav {
    float: none;
    border-left: none;
    margin-bottom: 30px;
  }
  body.rtl #site-wrapper .wpb_tour .wpb_tabs_nav .ui-state-default {
    float: right;
    margin-right: 0;
    margin-left: 30px;
  }
  body.rtl #site-wrapper .wpb_tour .wpb_tour_tabs_wrapper .wpb_tab {
    padding: 0;
  }
}
@media only screen and (max-width: 568px) {
  body.rtl #site-header #headerbar .custom-info .spacer {
    display: block;
  }
  body.rtl #site-header #masthead #site-brand .header-widgets {
    display: block;
    text-align: center;
    padding-bottom: 30px;
  }
  body.rtl #site-header #masthead #site-brand .header-widgets .widget {
    display: block;
    border: none;
    text-align: center;
  }
  body.rtl #site-footer .copyright {
    float: none;
  }
  body.rtl #site-footer .social-links {
    float: none;
    margin-bottom: 20px;
  }
  body.rtl #site-footer .social-links a {
    margin-right: 15px;
    margin-left: 0;
  }
}
.header-v3 #site-header #masthead {
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transform: translateZ(0px);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  -webkit-transition: background 0.2s ease-in;
  -moz-transition: background 0.2s ease-in;
  -ms-transition: background 0.2s ease-in;
  -o-transition: background 0.2s ease-in;
  transition: background 0.2s ease-in;
}
.header-v3 #site-header #masthead.stick {
  top: 0;
  position: fixed;
  background: #ffffff;
}
.header-v3 #site-header #masthead.stick #site-navigator .menu.menu-extra .search-box .submenu .search-field {
  background: #d8e7ef;
  border-color: transparent;
}
.header-v3 #site-header #masthead #site-navigator .menu.menu-extra .search-box .submenu .search-field {
  background: none;
  border-color: rgba(21, 65, 110, 0.15);
}
.header-v3 #site-header #masthead #site-navigator .menu.menu-extra .search-box .submenu .search-field:focus {
  border-color: inherit;
}
.header-v3 #site-header #masthead #site-navigator .menu > .current-menu-ancestor > a,
.header-v3 #site-header #masthead #site-navigator .menu > .current-menu-item > a {
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.header-v3.admin-bar #site-header #masthead.stick {
  top: 32px;
}
.header-v3 #site-content #page-header {
  padding-top: 146px;
}
.header-v3.pageheader-hidden #site-content #page-breadcrumbs {
  border: none;
}
.header-v3.pageheader-hidden #site-content #page-breadcrumbs .wrapper {
  padding-top: 111px;
}
.header-v2 #site-header #headerbar {
  padding-bottom: 57px;
}
.header-v2 #site-header #masthead {
  background: none;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -48px;
  -webkit-transform: translateZ(0px);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background 0.2s ease-in;
  -moz-transition: background 0.2s ease-in;
  -ms-transition: background 0.2s ease-in;
  -o-transition: background 0.2s ease-in;
  transition: background 0.2s ease-in;
}
.header-v2 #site-header #masthead .wrapper {
  background: #ffffff;
  padding: 0 30px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.header-v2 #site-header #masthead.stick {
  top: 0;
  position: fixed ;
  margin-top: 0;
}
.header-v2 #site-header #masthead.stick .wrapper:before {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.header-v2 #site-header #masthead #site-navigator {
  float: right;
}
.header-v2 #site-header #masthead #site-navigator .menu {
  float: left;
}
.header-v2 #site-header #masthead #site-navigator .menu.menu-extra {
  position: static;
  float: right;
  margin: 0 0 0 50px;
}
.header-v2 #site-header #masthead #site-navigator .menu.menu-extra .search-box {
  position: relative;
}
.header-v2 #site-header #masthead #site-navigator .menu.menu-extra .search-box > a {
  display: inline-block;
  position: absolute;
  top: 0;
  right: -3px;
}
.header-v2 #site-header #masthead #site-navigator .menu.menu-extra .search-box .submenu {
  position: absolute;
  right: 0;
  visibility: hidden;
  display: none\9;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
.header-v2 #site-header #masthead #site-navigator .menu.menu-extra .search-box .submenu .search-field:focus {
  border-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.header-v2 #site-header #masthead #site-navigator .menu.menu-extra .search-box.active .submenu {
  visibility: visible;
  display: block\9;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.header-v2 #site-header #masthead #site-navigator .menu > .current-menu-ancestor > a,
.header-v2 #site-header #masthead #site-navigator .menu > .current-menu-item > a {
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.header-v2.admin-bar #site-header #masthead.stick {
  top: 32px;
}
.header-v2 #site-content #page-header {
  padding-top: 98px;
}
.header-v2.pageheader-hidden #site-content #page-breadcrumbs {
  border: none;
}
.header-v2.pageheader-hidden #site-content #page-breadcrumbs .wrapper {
  padding-top: 63px;
}
.header-v4 #site-header #masthead #site-brand {
  float: none;
  margin: 0;
}
.header-v4 #site-header #masthead #site-brand .wrapper #site-logo {
  float: left;
}
.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets {
  float: right;
  margin: 28px 0;
  font-size: 14px;
}
.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets:after {
  clear: both;
  content: "";
  display: block;
}
.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget {
  float: right;
  margin: 0 0 0 50px;
}
.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget:last-child {
  margin: 0;
}
.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget .info-icon {
  line-height: 1.4;
}
.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget .info-icon:after {
  clear: both;
  content: "";
  display: block;
}
.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget .info-icon i {
  float: left;
  margin-right: 15px;
  margin-top: 3px;
  font-size: 28px;
}
.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget .info-icon .content {
  float: right;
}
.header-v4 #site-header #masthead #site-navigator {
  float: none;
  margin: 0;
}
.header-v4 #site-header #masthead #site-navigator.stick {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.header-v4 #site-header #masthead #site-navigator .menu > li > a {
  color: rgba(255, 255, 255, 0.8);
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}
.header-v4 #site-header #masthead #site-navigator .menu > li > a:after {
  left: 0;
  right: 0;
  height: 4px;
  content: "";
  bottom: -1px;
  position: absolute;
}
.header-v4 #site-header #masthead #site-navigator .menu > li:hover > a {
  color: #ffffff;
}
.header-v4 #site-header #masthead #site-navigator .menu > .current-menu-item > a,
.header-v4 #site-header #masthead #site-navigator .menu > .current-menu-ancestor > a {
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-left-color: rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.2);
}
.header-v4 #site-header #masthead #site-navigator .menu.menu-extra .search-box .submenu .search-field:focus {
  border-color: transparent;
}
.header-v4 #site-header #masthead #site-navigator-placeholder {
  display: none;
}
.header-v4 #site-header #masthead .stick + #site-navigator-placeholder {
  display: block;
}
.header-v4.admin-bar #site-header #masthead #site-navigator.stick {
  top: 32px;
}
a,
a.link:hover,
.scheme,
#site-header #headerbar .top-navigator .menu li .sub-menu li a,
#site-wrapper .imagebox .box-content .box-button a:hover,
#site-wrapper .testimonial .testimonial-author .author-info a:hover,
#site-wrapper .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list li.vc_active a,
.woocommerce .product .woocommerce-tabs .tabs li.active a,
input.white[type="submit"],
button.white[type="submit"],
.button.white,
.sidebar .widget.widget_nav_menu .menu > li.current-menu-item > a,
.sidebar .widget.widget_nav_menu .menu > li.current_page_item > a,
.sidebar .widget.widget_nav_menu .menu > li.current-menu-item > a::before,
.sidebar .widget.widget_nav_menu .menu > li.current-page-item > a::before {
  color: #15416e;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input.input-text:focus,
select:focus,
#site-wrapper .vc_tta-accordion .vc_tta-panel-heading .vc_tta-panel-title a i.vc_tta-controls-icon-plus::before,
#site-wrapper .vc_tta-accordion .vc_tta-panel-heading .vc_tta-panel-title a i.vc_tta-controls-icon-plus::after {
  border-color: #15416e;
}
input[type="submit"]:hover,
button[type="submit"]:hover,
.button:hover,
input[type="submit"].scheme2,
button[type="submit"].scheme2,
.button.scheme2,
.bg-scheme,
#headerbar,
#site-header #headerbar .top-navigator .menu li .sub-menu li:hover > a,
#site-header #headerbar .top-navigator .menu li .sub-menu li.current-menu-item > a,
#site-header #headerbar .top-navigator .menu li .sub-menu li.current-page-item > a,
#site-header #masthead #site-navigator .menu li .sub-menu li:hover > a,
#site-header #masthead #site-navigator .menu li .sub-menu > .current-menu-item > a,
#site-header #masthead #site-navigator .menu li .sub-menu > .current-menu-ancestor > a,
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart > a .shopping-cart-items-count,
#site-wrapper .imagebox .box-header::after,
#site-wrapper .imagebox .box-content::after,
#site-wrapper .iconbox .box-header .box-icon i,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover .entry-time,
.blog .hentry .entry-content .readmore .more-link:hover,
#site-footer,
#site-wrapper .testimonial .testimonial-image::after,
.blog .hentry.sticky,
.single-post .hentry .entry-footer .entry-tags a,
a[rel="wp-video-lightbox"]::before,
#site-wrapper .owl-controls .owl-buttons > div:hover,
#site-wrapper .owl-controls .owl-pagination .owl-page span,
.widget.widget_tag_cloud .tagcloud a,
table thead,
table tfoot,
#site-wrapper .vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-heading .vc_tta-panel-title a,
#site-wrapper .vc_toggle .vc_toggle_title h4::after,
#site-wrapper .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list,
.header-v4 #site-header #masthead #site-navigator,
.woocommerce .product .woocommerce-tabs .tabs,
.projects .projects-filter ul,
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail a,
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a:hover,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail a,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a:hover,
.projects.projects-justified .projects-items .hentry > a::after,
.sidebar .widget.widget_nav_menu ul,
.woocommerce .products li .add_to_cart_button,
.woocommerce-page .products li .add_to_cart_button,
.widget.widget_product_tag_cloud .tagcloud a,
.widget_shopping_cart .buttons .button.checkout,
.widget_shopping_cart_content .buttons .button.checkout,
.widget.widget_price_filter .price_slider_wrapper .ui-slider .ui-slider-range,
.navigation.post-navigation .nav-links li a::after,
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info,
.goto-top a,
#site-wrapper .flexslider .flex-direction-nav li a:hover,
.history li h3,
#site-header #masthead #site-navigator-mobile.navigator-mobile .navigator-toggle {
  background-color: #15416e;
}
a:hover,
a.link,
.scheme2,
#site-header #masthead #site-navigator .menu > .current-menu-item a,
#site-header #masthead #site-navigator .menu > .current-menu-ancestor a,
#site-header #masthead #site-navigator .menu > li:hover > a,
#site-wrapper .iconbox .box-readmore a,
.blog .hentry .entry-header .entry-time,
.single-post .hentry .entry-header .entry-time,
.blog .hentry .entry-header .entry-meta a:hover,
.single-post .hentry .entry-header .entry-meta a:hover,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-footer .entry-meta a:hover,
#site-wrapper .testimonial .testimonial-author .author-info,
#site-wrapper .testimonial .testimonial-author .author-info a,
h1 i,
h2 i,
h3 i,
h4 i,
h5 i,
h6 i,
.navigation.post-navigation .nav-links li a span,
.widget.widget_recent_comments ul li::after,
.header-v4 #site-header #masthead #site-brand .wrapper .header-widgets .widget .info-icon i,
.projects.projects-grid .projects-items .hentry .project-wrap .project-info .project-categories li a:hover,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-info .project-categories li a:hover,
.woocommerce .star-rating,
.woocommerce-page .star-rating,
.projects.projects-masonry .projects-items .hentry .project-wrap .project-info .project-title a:hover,
.projects.projects-justified .projects-items .hentry .project-info .project-title a:hover,
#site-wrapper .member .member-info .member-subtitle {
  color: #18ba60;
}
#site-header #masthead #site-navigator .menu > .current-menu-item a,
#site-header #masthead #site-navigator .menu > .current-menu-ancestor a,
blockquote,
.woocommerce .onsale::after,
.woocommerce-page .onsale::after,
.woocommerce .products li .product-inner:hover,
.woocommerce-page .products li .product-inner:hover,
.project-single .project-content ul li::before,
ul.style1 li::before,
ul.style2 li::before,
.projects.projects-grid-alt .projects-items .hentry .project-wrap:hover {
  border-color: #18ba60;
}
input[type="submit"],
button[type="submit"],
.button,
input[type="submit"].scheme2:hover,
button[type="submit"].scheme2:hover,
.button.scheme2:hover,
.bg-scheme2,
#site-header #headerbar .top-navigator .menu li .sub-menu li a::before,
#site-header #masthead #site-navigator .menu li .sub-menu li a::before,
#site-header #masthead #site-navigator .menu.menu-extra .shopping-cart > a,
#site-wrapper .imagebox .box-header::before,
#site-wrapper .imagebox .box-content .box-button a,
#site-wrapper .iconbox .box-readmore a::before,
#site-wrapper .iconbox:hover .box-header .box-icon i,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-content-wrap::after,
#site-wrapper .blog-shortcode.blog-grid .hentry .entry-cover:hover .entry-time,
.blog .hentry .entry-content .readmore .more-link,
#site-footer #footer-widgets .widget.widget_nav_menu ul li::before,
.widget.widget_archive ul li::before,
.quick-form h1::after,
.quick-form h2::after,
.quick-form h3::after,
.quick-form h4::after,
.quick-form h5::after,
.quick-form h6::after,
h1.style2::after,
h2.style2::after,
h3.style2::after,
h4.style2::after,
h5.style2::after,
h6.style2::after,
.navigation .page-numbers.current,
.widget .widget-title::before,
.single-post .hentry .entry-footer .entry-tags a:hover,
.box .box-title::after,
#comments .comments-title::after,
#comments #reply-title::after,
a[rel="wp-video-lightbox"]:hover::before,
#site-wrapper .owl-controls .owl-buttons > div,
#site-wrapper .owl-controls .owl-pagination .owl-page.active span,
.widget.widget_categories ul li a::before,
.widget.widget_pages ul li a::before,
.widget.widget_meta ul li a::before,
.widget.widget_tag_cloud .tagcloud a:hover,
.widget.widget_calendar table tbody tr td#today,
#site-wrapper .vc_toggle.vc_toggle_active .vc_toggle_title h4::after,
#site-wrapper .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list li a::before,
.header-v2 #site-header #masthead #site-navigator .menu > .current-menu-item > a,
.header-v2 #site-header #masthead #site-navigator .menu > .current-menu-ancestor > a,
.header-v3 #site-header #masthead #site-navigator .menu > .current-menu-item > a,
.header-v3 #site-header #masthead #site-navigator .menu > .current-menu-ancestor > a,
.header-v4 #site-header #masthead #site-navigator .menu > .current-menu-item > a::after,
.header-v4 #site-header #masthead #site-navigator .menu > .current-menu-ancestor > a::after,
.woocommerce .product .woocommerce-tabs .tabs li a::before,
.projects .projects-filter ul li.active a::after,
.projects.projects-grid .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a,
.projects.projects-grid-alt .projects-items .hentry .project-wrap .project-thumbnail .project-buttons a,
.sidebar .widget.widget_nav_menu .menu li .sub-menu li a::before,
.sidebar .widget.widget_nav_menu .menu > li.current-menu-item > a::after,
.sidebar .widget.widget_nav_menu .menu > li.current_page_item > a::after,
.woocommerce .products li .wc-forward,
.woocommerce-page .products li .wc-forward,
.woocommerce .onsale,
.woocommerce-page .onsale,
.woocommerce .products li .add_to_cart_button:hover,
.woocommerce-page .products li .add_to_cart_button:hover,
.woocommerce .woocommerce-pagination ul li .page-numbers.current,
.woocommerce-page .woocommerce-pagination ul li .page-numbers.current,
.widget.widget_product_tag_cloud .tagcloud a:hover,
.widget.widget_product_categories ul li::before,
.widget_shopping_cart .buttons .button.checkout:hover,
.widget_shopping_cart_content .buttons .button.checkout:hover,
.widget.widget_price_filter .price_slider_wrapper .ui-slider .ui-slider-handle,
.woocommerce .product .related h2::after,
.woocommerce-page .product .related h2::after,
.woocommerce .product .upsells h2::after,
.woocommerce-page .product .upsells h2::after,
.projects-related .projects-related-title::after,
#site-header #headerbar .top-navigator .menu > li.current-menu-item > a::after,
#site-header #headerbar .top-navigator .menu > li.current-page-item > a::after,
.project-single .project-content ul li::after,
ul.style1 li::after,
ul.style2 li::after,
.navigation.post-navigation .nav-links li a:hover::after,
.search #main-content .content-inner .search-results article .counter,
.goto-top a:hover,
#site-wrapper .counter .counter-image i,
#site-wrapper .flexslider .flex-direction-nav li a,
#site-wrapper .member .member-info .social-links a:hover,
.history li:hover h3,
.history li::after,
#site-wrapper .testimonial:not(.has-image) .testimonial-content::after,
#site-header #masthead #site-navigator-mobile.navigator-mobile.active .navigator-toggle {
  background-color: #18ba60;
}
.wrapper,
.page-fullwidth #page-body .wrapper .content-wrap .content .vc_row_wrap,
.page-fullwidth #page-body #respond,
.page-fullwidth #page-body .nocomments {
  width: 1200px;
// width: 800px;
}
.layout-boxed #site-wrapper {
  width: 1300px;
}
.owl-carousel .owl-wrapper:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
/* display none until init */
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
//   -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}
.owl-carousel .owl-item {
  float: left;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer;
}
.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* mouse grab icon */
// .grabbing {
// //   cursor: url(../images/grabbing.png) 8 8, move;
// }
/* fix */
// .owl-carousel .owl-wrapper,
// .owl-carousel .owl-item {
//   -webkit-backface-visibility: hidden;
//   -moz-backface-visibility: hidden;
//   -ms-backface-visibility: hidden;
//   -webkit-transform: translate3d(0, 0, 0);
//   -moz-transform: translate3d(0, 0, 0);
//   -ms-transform: translate3d(0, 0, 0);
// }
#masthead {
    position: absolute;
    top: 50px;
}
body #site-header #masthead.stick {
    position: absolute;
    top: 10px;
    // width: 30%;
    padding-left: 50px;
  }
  body #site-header #masthead{
      display: flex;
      justify-content: center;
      padding-left: 50px;
  }

//   body #site-header #headerbar.stick {
//     width: 30%;
    
//   }
}