.alert-delete {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; 
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 101;
    
}
.alert-cnt {  
    margin: 0 auto;
    margin-top: calc(100vh / 4); 
    height: 150px;
    width: 300px;
    background-color: $backgray;
    padding: 50px;
    color: $mblue;
    border-radius: 10px;
    border: 2px solid $mblue;
    
    

}
.alert-cnt-btn {
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    
}