


.course-cnt img {    
    height: 222px;
    width: 222px;
    border-radius: 8px 8px 0px 0px; 
   
}

 
.course-cnt lis{ 
    color: $colhighcolor;
    font-weight: 700;
    list-style: none;   
    display: inline-block;
    margin: 22px 22px;
    margin-bottom: 0px;
    vertical-align: top;
        
  
} 




.cnt-delete {
    display: flex;
    justify-content: space-around;
    font-size: 13px;
    font-weight: 100;
    margin: 2px;
}

.img-column,   .img-column-active{
    color: $mgray;
    position: relative;
    left: -5px;
    top: 1px;
    padding-left: 10px;
    
}
.img-column-active {
    color: $mblue;
    border-left: 2px solid $mblue; 
}
#img-help {
    top: 2.2px;
    transform: scale(1.1); 

}
.column-titles, .column-titles-active {
    margin-top: 15px;
    font-weight: 700; 
    color: $mgray; 
    
  
}
.column-titles-active {
    color: $colhighcolor; 
}



