.wrap-kidcourse-preview-title {
    background-color: $backgroundcoursepreview;
    // height: 300px;
    min-width: calc(100vw - 234px);
    max-width: calc(100vw - 234px);
    border-bottom: 7px solid $borderbottom;
    display: flex;
    justify-content: center;
    content: center;
    // text-align: center;
    align-items: center;
    text-align: center;
}

.wrap-kidcourse-preview-details {
    // display: flex;
    // justify-content: center; 
    // flex-direction: column;
    text-align: center;
}
.wrap-kidcourse-preview-box {
    width: 100%; 
    border: 2px solid $trim;
    border-radius: 10px;
    margin-bottom: 50px;
}

.wrap-kidcourse-preview-box-title {
    color: $titlepreview; 
    text-align: start;
    padding: 10px;
    & dd {
        color: $mblue;
    }
    
}
.wrap-kidcourse-preview-box-data {
    color: $txttitlepreview;
    border-top: 2px solid $trim;
    text-align: start;
    padding: 10px;
    & dt {
        color: green;  
    }
    & dl {
        color: $mblue;
    }
    &:hover {
        background-color: $trim;
    }
}
.wrap-abs-btn-preview  {
    position: absolute;
    top: 65px;
}
.wrap-center-page {
    margin-top: 80px;
    margin-bottom: 100px;
    margin-left: 100px;
    margin-right: 100px;
}

.wrap-onboard-body {
    margin-top: 80px;
    margin-bottom: 100px;
    margin-left: 100px;
    margin-right: 100px;
 
}

.wrap-onboard-txt {
    display: flex;
    color: $colhighcolor;
    width: 600px;
    // padding: 7px;
    border-radius: 10px; 
    border: 2px solid $trim;
    margin-top: 25px;
    // &:hover {
    //     background-color: $trim;
    // }
}
.wrap-onboard-token {
    margin-top: 10px;
    margin-left: 10px;
    color: $colhighcolor;
    width: 600px;

    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis; 
    overflow-wrap: break-word;

}
.wrap-onboard-txt-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 7px;
    width: 100%;
    &:hover {
        background-color: $trim;
    }
}
.wrap-onboard-txt-stripe {
    width: 20%;
    padding: 7px;
    border-left: 2px solid $trim; 
    text-align: center;
    &:hover {
        background-color: $trim;
    }
}
.wrap-onboard-txt-edit {
    width: 07%;
    padding: 7px;
    border-left: 2px solid $trim; 
    text-align: center;
    &:hover {
        background-color: $trim;
    }
}