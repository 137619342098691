.freeweb_outter {
    background-color: rgb(241,242,248);
    padding: 40px 50px 50px 50px ;
    color: #15416e;

}
.freeweb_innerbod {
    width: calc(100% - 60px);
    min-height: 100vh; 
    background-color: white;
    padding: 30px;
    

}
.freeweb_innerbod_just {
    width: calc(100% - 60px);
    min-height: 100vh; 
    background-color: white;
    padding: 10px 30px 30px 30px;
}
.freeweb_header {
    text-transform: uppercase;
    text-align: center;
    
    font-weight: 700;
    // font-style: normal;
    padding: 0px 0px 0px 0px;
    line-height: 1.1;
    font-size: 18px;
    // text-rendering: optimizeLegibility;
    

}
.freeweb_header2 {
    text-transform: uppercase;
    text-align: center;
    
    font-weight: 700;
    // font-style: normal;
    padding: 0px 0px 30px 0px;
    line-height: 1.1;
    font-size: 30px;
}

.freeweb_btn_ctn {
    display: flex;
    justify-content: center;
    margin-top: 30px;

}
.freeweb_btn {
    border-radius: 2px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, .1);
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    padding-top: 18px;
    padding-bottom: 18px;
    color: #fff;
    border-color: #08c;
    background-color: #08c; 
    width: 100%; 
    background-image: linear-gradient(to bottom,rgba(255,255,255,.2) 0,rgba(255,255,255,.01) 100%);
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    box-sizing: border-box;
    word-wrap: break-word;
    user-select: none;
    text-decoration: none;
    &:hover {
        border-color: #0074ad;
        background-color: #0074ad;
        background-image: linear-gradient(to bottom,rgba(255,255,255,.2) 0,rgba(255,255,255,.01) 100%);
    }
}
.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 .07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}
.freeweb_wrapper {
    width: 50%;
    // background-color: gray;
    & p {
        margin-bottom: 20px;
        font-size: 16px;
    }   
    & dd {
        font-size: 19px;
        margin-bottom: 20px;
    } 
}
.freeweb_vidp {
    display: flex ;

}
.freeweb_vid_cnt {
    width: calc(50% - 30px);
    height: calc((50% - 30px) * .5625 );

    margin-right: 30px;
    & video {
        border-radius: 2px;
    }  
}
.freeweb_vid2_cnt {
    margin-top: 30px;
    margin-bottom: 30px;
    & video {
        border-radius: 2px;
    }  
}
.freeweb_info_wrap {
    padding: 20px 100px; 
    border-radius: 5px;
    background: rgb(241,242,248);
    color: $colorbody;  
    // box-shadow: 5px 5px 22px $glow,
    //     -5px -5px 22px $glow;  
    display: flex;
    justify-content: center; 
    position:relative;    
    width: 333px; 
    margin: auto;
    margin-top: 30px;
    // margin-bottom: 100px;
    border: 2px solid $mblue; 
}


#site-footer {

}