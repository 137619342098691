.flex {
    display: flex;
}
.flexcenter {
    display: flex;
    justify-content: center;
}
.id-50 {
    height: 50px;
}
.maxheight {
    // height: calc(100vh + 50vh)
}
.classconfirmfull {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.685);
    height: 100vh;
    width: 100vw;
}
.classconfirm {
    position: fixed; 
    top: 50vh;
    left: 50vw;
    background-color: white;
    padding: 15px;
    & h4 {
        color: black; 
        margin-bottom: 15px;
        
    }
    & span {
        
    }
}
