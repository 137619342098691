 
.vid-lesson-video video{ 
    border-radius: 15px;   
    // outline: none;

  }
  
.vid-calls {
  width: 500px;
  & video{
    border-radius: 10px;     
  }
}
.vid-call-side {
  margin-top: 15px;
  color: black;
  width: 500px;
  height: calc( 500px * 0.5625 ); 
  & video {
    border-radius: 10px;     
  }
}
.vid-take-video{ 
  margin: 50px;
}
.vid-take-video video{
  border-radius: 15px;    
}
.vid-take-video-small{ 
  margin: 50px 300px 50px 300px;
  
}
.vid-take-video-small video{
  border-radius: 15px;    
}
 
.video_player {
  // background-image: url(https://i.ytimg.com/vi_webp/S24Ns1xXguY/maxresdefault.webp);
}
