.cnt-call-delete {
    display: flex;
}

.ctn-create-input,.ctn-create-side {
    display: flex;
    flex-direction: column;
    color: $headcolor;
    width: 600px;
    // height: 100px;
    font-size: 18px;
    font-weight: 700;
    
}
.ctn-lesson-input {
    display: flex;
    flex-direction: column;
    color: $headcolor;
    // width: 600px;
    // height: 100px;
    font-size: 18px;
    font-weight: 700;

}
.course-cnt, .cnt-create {
    margin: 22px 22px 50px 22px;  
    

}
.cnt-create, .cnt-delete-file {
    display: flex;
    justify-content: start;
    margin-bottom: 15px;
}

.ctn-create-side {
    margin-left: 50px;
    width: 333px;
    height: 200px;
    // background-color: gray;
}
.ctn-create-side-quiz {
    margin-left: 50px;
    height: 200px; 
    width: 100%; 
    color: $headcolor;  
    font-size: 18px;
    font-weight: 700;
}
.ctn-status {
    margin-top: 20px;
    border: 2px solid $trim; 
    font-size: 18px;  
    border-radius: 7px; 
    display: flex;
    flex-direction: column; 
    align-items: center; 
    padding: 15px;
}
.ctn-check {
    margin-top: 3px;
}
.cnt-create-img, .cnt-student-img {
    // padding: 10px;
    border: 2px solid $trim; 
    font-size: 18px;  
    border-radius: 7px; 
    margin-top: 50px;
    display: flex;
    flex-direction: column; 
    align-items: center;  
    
}

.cnt-unit, .cnt-quiz, .cnt-worksheet{
    border: 2px solid $trim; 
    border-radius: 7px; 
    // padding: 20px;
    margin: 20px 10px;
    // width: 600px; 
   
}
.cnt-unit-header, .cnt-unit-lesson   {
    margin: 20px ;
    display: flex;
    justify-content: space-between;
 
}

.cnt-lesson {
    margin: 0px;
    border-left: 2px solid $trim;
    border-top: 2px solid $trim; 
}

.ctn-unit-footer {
    background-color: $trim;
    text-align: center;
    padding-top: 20px;
}

.ctn-students-course {
    border: 2px solid $trim;
    border-radius: 7px; 
    margin: 20px 0px 0px 0px ;
    

}

.ctn-students-courses {
    padding: 20px;
    border-bottom: 2px solid $trim;
}

.ctn-students-courses-title {
    color: $mblue;
    margin-bottom: 10px;
}

.ctn-students-courses-tier {
    display: flex;
    justify-content: center;
    font-size: 15px;
    & hide {
        color: transparent;
        z-index: -100;
    }
}
.ctn-students-quiz-title {
    margin-bottom: 15px;
    margin-top: 40px;
}

.ctn-students-quizzes { 
    border: 2px solid $trim;
    border-radius: 7px;  
    & th, td { 
        border-top: none;
        text-align: left;
        padding: 10px;
    }
    
}

.ctn-students-quizzes-header, .ctn-students-quizzes-body {
    // display: flex;
    // justify-content: space-between;
    padding: 10px;
    border-bottom: 2px solid $trim;

    color: $mgray; 
} 
.ctn-students-quizzes-body-click {
    padding: 10px;
    border-bottom: 2px solid $trim;

    color: $mblue;
    &:hover {
        background-color: $trim;
    }
}
.ctn-students-quizzes-body { 
    color: $mblue;
}

.ctn-lesson-video{
    color: black; 
    // background-color: gray; 
    display: flex;
    flex-direction: column; 
    align-items: start;    
}

.cnt-quiz, .cnt-worksheet {
    color: $headcolor;
    font-size: 18px;
    font-weight: 700;
}

.cnt-answers {
    width: calc(100% - 84px); // margin*2 + border *2 + padding *2
    border: 2px solid $trim; 
    margin: 20px;
    border-radius: 7px; 
    padding: 20px;
}
.cnt-answer { 
    // background-color: gray;
    display: flex;
    justify-content: start; 
    margin-top: 10px;
}
.cnt-btn-answer {
    margin-left: 20px;
    margin-bottom: 20px;
}
.cnt-btn-question, .cnt-worksheet-previews {
    display: flex;
    justify-content: center;
}
.cnt-worksheet {
    width: 600px;
    padding: 20px;
} 
.cnt-worksheet-question {
    display: flex;
    justify-content: space-between;
}
.cnt-video {
    height: 400px;
    width: 600px;
}
.list-con { 
    width: 222px;
    border: 2px solid $trim; 
    border-radius:   10px;
    background-color: $headerback;
    &:hover {
        box-shadow: 0px 0px 10px black;
        transform: scale(1.03);
    }
}

.list-con p {  
     
    padding: 10px;
    margin: 00px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    
} 
.cnt-worksheet-previews {
    margin-bottom: 15px;
    font-weight: 500;
}

.cnt-call {
    display: flex;
    padding-top: 12.5px; 
    padding-bottom: 12.5px; 
    margin-left: 25px;
    border-bottom: 2px solid $trim;
}
.cnt-call-details {  
    height: 222px;
    width: 500px; 
    overflow-wrap: break-word; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.cnt-call-side {
    margin-top: 15px;
    margin-left: 25px;
    color: black;
    & input {
        transform: scale(1.2);
        margin-left: 25px; 
      }
}

.ctn-homework {
    color: $mblue;
    margin: 25px;
    width: 600px;
    // background-color: gray;
}

.cnt-each-homework {
    width: 100%;
    border: 2px solid $trim;
    border-radius: 7px;
    margin-bottom: 15px;
    
    display: flex;



}

.cnt-homework-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    width: 100%;
    padding: 7px;
    &:hover {
        background-color: $trim;
    }
}
.cnt-homework-btn { 
      
    width: 7%;
    padding: 7px;
    border-left: 2px solid $trim; 
    text-align: center;
    &:hover {
        background-color: $trim;
    }
}
.cnt-homework-add {
    display: flex;
    justify-content: start;
    margin-bottom: 15px;
    margin: 22px 22px 50px 22px;  

}
.cnt-soon {
    display: flex;
    justify-content: center;
}

 
.cnt-home-vid video{
    height: 100vh;
    width: calc(100vw - 17px);  
    object-fit: cover; 
}
.cnt-home-vid  {
    height: 100vh;
    background-color: gray;
}
.cnt-kid-body-courses {
    margin: 25px;
   
}
.cnt-kid-courses {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid $trim;
}
.cnt-kids-courses-start { 
    display: flex;
    align-items: center;
    margin-top: -50px;
    margin-left: 20px;

} 

.cnt-kidcourse-preview-title {
    max-width: 500px;
    position: relative;
}
.cnt-kidcourse-preview-title h2 {
    color: $titlepreview; 
}

.cnt-kidcourse-preview-title dd {
    color: $txttitlepreview;
    margin: 15px;
}
.cnt-kidcourse-preview-title-button {
    display: flex;
    justify-content: center;
}
.cnt-kidcourse-preview-title-button dt{
    margin-left: 15px;
    margin-top: 5px;
    color: $mblue;
}
.cnt-kidcourse-preview-detail {
    min-width: 600px;
    max-width: 800px;
    border-bottom: 2px solid $trim;
}
.cnt-kidcourse-preview-detail dd{
    color: $txttitlepreview;
    margin: 50px;
}

#cnt-take-build { 
    color: $txttitlepreview;
    margin: 50px; 
}
 
#cnt-take-quiz {
    color: $quiztxt;
    margin: 50px;
    
}
#cnt-take-quiz-answers { 
    color: $txttitlepreview; 
    margin-left: 20px;
}

#cnt-take-button {
    margin-left: 50px;
}

#cnt-take-button-next {
    margin-left: 50px;
    display: flex;
    
    & dd {
        color: green;
        margin-top: 6px;
        margin-left: 22px;
    }
}
#cnt-take-worksheet {
    color: $titlepreview;
    margin-top: 20px; 
    margin-left: 50px; 
    margin-bottom: 20px; 
    word-wrap: break-word;
}

#cnt-take-worksheet-question {
    color: $txttitlepreview ;
    margin-left: 20px;
}
#cnt-worksheet-each {
    margin-bottom: 20px; 
}

#cnt-take-download {
    display: flex;
    color: $titlepreview;
    margin-top: 20px; 
    margin-left: 50px; 
    margin-bottom: 20px; 
 
}

.cnt-kidhomework {
    display: flex;
    // justify-content: space-between;
    width: 600px;
}
#cnt-take-homework-question {
    color: $txttitlepreview ;
    margin-left: 50px;
    margin-top: 50px;
}
.cnt-contactme {
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    & p {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}