.header-home {
    display: flex;
    justify-content: space-between; 
    & h1 {
        max-width: 80vw;
        word-wrap: break-word;
    }
}
.header-home-free {
    display: flex;
    justify-content: center; 
    padding: 25px;  
    background: $headerback;   
    color: $headcolor;  
    border-bottom: 2px solid $trim; 
    & h1 {
        max-width: 80vw;
        word-wrap: break-word;
    }
}

.header,.header-home {   
    // text-align: center; 
    padding: 25px;  
    background: $headerback;   
    color: $headcolor;  

}

.header-home,.header-home-detail {
    border-bottom: 2px solid $trim; 
    
}

.header-home-detail {
    display: flex;
    justify-content: start;
    padding: 15px;
    background: white;   
    color: $headcolor;  
 
}