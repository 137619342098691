.btn-blue ,.btn-clear-active,.btn-clear{
  

    color: white ; 
    background: $mblue;
    // position: relative;
    // top: 3px;
    // left: -22px;
    margin-left: 10px;
    // width: 80px;
    padding: 0px 15px;
    height: 33px;
    // width: 25%;
    border-radius: 5px;
    border: 1.5px solid $mlblue;
    font-weight: 500;
    transition: .6s;
    &:hover  { //, &:focus
    // color: $backcolor ;
    // background:  rgb(35, 104, 194);
    border: 1.5px solid black;
    box-shadow: 0px 0px 1px black;
    color: black;
    outline: none;
    
    }
&:active, active {
    background: yellow;
    color: black ;
    border: 1.5px solid black;
    transition: .0s;
}}

.btn-clear-active{  
    margin-right: 17px;
    color: $mblue ; 
    background: $headerback;   
    border: 1.5px solid $trim;
    font-weight: 700;
    font-size: 18px;
    &:hover  { //, &:focus 
    border: 1.5px solid $mblue;
    box-shadow: 0px 0px 1px $mblue; 
    color: $mblue ; 
    }
&:active, active {
    background: $mblue;
    color: black ;
    border: 1.5px solid black; 
} } 


.btn-clear{  
    margin-right: 17px;
    color: $headcolor ; 
    background: transparent;   
    border: 1.5px solid transparent;
    font-weight: 700;
    font-size: 18px;

    &:hover  { //, &:focus 
    border: 1.5px solid $mblue; 
    color: $mblue ; 
    }
&:active, active {
    background: $headerback;
    color: $headcolor ;
    border: 1.5px solid $headcolor;
    // border: 1.5px solid black; 
} }  

.btn-hidden {
    background: transparent;
    color: transparent;
    border: 1.5px solid transparent;
    z-index: -100;
}

.cnt-lesson-btns-edit button {
    margin-left: 10px;
}
.rbtn-clear {
    background: transparent;
    padding: 5px;
    padding-left: 9px;
    border: 2px solid $trim;
    border-radius: 7px;
    color: $mblue; 
    &:hover {
        border: 1.7px solid $mblue;
    }
    &:active {
        background: $mblue;
        border: 1.7px solid black;
        color: black; 
    }
}

.rbtn-blue {
    background: $mblue;
    padding: 8px;
    padding-left: 9px;
    border: 2px solid $mlblue;
    border-radius: 7px;
    color: white; 
    &:hover {
        border: 2px  solid black;
        color: black;  
    }
    &:active {
        background: yellow;
        border: 2px solid black;
        color: black; 
    }
}

.rbtn-mblue {

    color: $backgray ;
    background: rgb(105, 170, 255);
    // position: relative;
    // top: 3px;
    // left: -22px;
    // width: 80px;
    // height: 33px;
    // width: 25%;
    padding: 8px;
    padding-left: 9px;

    border-radius: 5px;
    border: 1.5px solid rgb(101,167,255);
    font-weight: 900;
    transition: .3s;
 &:hover  {//, &:focus
    // color: $backcolor ;
    // background:  rgb(35, 104, 194);
    // border: 1.5px solid yellow;
    // box-shadow: 0px 0px 1px yellow;
    // color: yellow;
    outline: none;
    transform: scale(1.1);
    box-shadow: 0px 0px 5px black;
 
 }
&:active, active {
    background: yellow;
    color: $backgray ;
    border: 1.5px solid rgb(101,167,255);
    transition: .0s;
}
}
.rbtn-blue-f {
    background: $mblue;
    padding: 8px;
    padding-left: 9px;
    border: 2px solid $mlblue;
    border-radius: 7px;
    color: white; 
    font-size: 18px;
    height: 40px;
    &:hover {
        border: 2px  solid black;
        color: black;  
    }
    &:active {
        background: yellow;
        border: 2px solid black;
        color: black; 
    }
}

.rbtn-green {
    background: $mlgreen;
    padding: 8px;
    padding-left: 9px;
    border: 2px solid $mlgreen;
    border-radius: 7px;
    color: white; 
    &:hover {
        border: 2px  solid black;
        color: black;  
    }
    &:active {
        background: yellow;
        border: 2px solid black;
        color: black; 
    }
}