
* { 
    // -moz-box-sizing: border-box; //for paddding not resizeing
    // -webkit-box-sizing: border-box; 
    // box-sizing: border-box;     
     
    // top: 0;
    // left: 0;
    padding: 0;
    margin: 0;
    // font-family: Comic Sans MS, Comic Sans, cursive ; 
    font-family: Montserrat, arial, sans-serif;
    
    
    // font-family: MS Gothic;



} 

h5 {
    color: black;
}
body {
    background-color:$background;
    // position: relative;
    // background-color: background; 
    color: $font;
    // 
 
    
}
#all-Link {
    text-decoration: none;
    color: inherit;
}

.margin_top25 {
    margin-top: 25px;    
}