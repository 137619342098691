.head_cnt {
    position: absolute;
    top: 50px;
    height: 80px;
    width: calc(100% - 50px - 50px);
    background: white;
    margin-left: 50px;
    margin-right: 50px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, .1);
    display: flex;
    justify-content: space-between;
    // padding: 0 0px 0 30px;
    & h1 {
        margin-left: 30px;
    }
}
.other_nav {
    margin-right: 30px;
}
.freeweb_header_x {
    // background-color: transparent;
    text-transform: uppercase;
    text-align: center;
    
    font-weight: 700;
    // font-style: normal;
    padding: 0px 0px 0px 0px;
    line-height: 1.1;
    font-size: 25px;
    // text-rendering: optimizeLegibility;
    

}
.logo-retina_x {
    height: 60px;
    margin-top: 10px;
    margin-left: 10px;
}
.nav_wrapper ul{
    display: flex;
    font-size: 20px;
    li {
        margin: 20px 15px;  
        font-weight: 700; 

    }
}
.other_dropdown {
    z-index: 101;
    display: flex;
    flex-direction: column;
    background: white;
    padding-left: 20px;
    padding-right: 40px;
    margin-left: -20px;
    // margin-right: 20px;
     
//    position: relative;
   position: absolute;
   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.294);
   font-family: monospace;
   color: red;
   list-style: none;
   & li::before {
    content: "\2605"; /* Unicode bullet symbol */
    color: $mblue;  
    padding-right: 12px;
   }
   & li {
        // padding-left: 10px
        font-size: 18px;
        font-weight: 500; 
        // padding-left: 10px;
    }
}
.freeweb_wrapper i{
    color: rgb(65, 173, 91);

}
.remove_listdot {
    list-style: none;
}
@media (max-width: 777px) {/////////////////////////////////////////////////////
    .logo-retina_x {
        height: 60px;
        margin-top: 25px; 
    }
    .head_cnt { 
        top: 0; 
        width: 100%; 
        margin-left: 0;
        margin-right: 0; 
        height: 110px;
        justify-content: center;
    }
    .other_nav, #site-header {
        display: none;
    }
    .freeweb_header_x { 
        padding: 10px 10px 0px 10px;
       
    }
    .freeweb_outter {
        padding: 100px 0 0 0 ;
    }
   
}